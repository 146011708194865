import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Textarea,
  Text,
  useToast,
  Select,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadAttachmentImage from '../../../../assets/images/onboarding_upload_site_image.svg';
import { CloseIcon } from '@chakra-ui/icons';
import { get, post } from '../../../../api/api-helpers';
import { Platform, Ticket, TicketToSave, ZendeskUser } from '../../../../api/api-zendesk';
import { selectUser } from '../../userSlice';
import { PaginatedResponse } from 'clipsal-cortex-types/src/common/types';
import { SiteData } from '../../../../api/api-site';
import SearchableDropdownFormControl from '../../../../common/components/SearchableDropdownFormControl';
import { debounceEvent } from '../../../../utils/component-helpers';

const PLATFORMS: Platform[] = ['Installer', 'Pulse'];

type TicketType = 'General' | 'Site Specific';

const TYPES: TicketType[] = ['General', 'Site Specific'];

const submitTicketSchema = yup.object().shape({
  type: yup.string().required(),
  subject: yup.string().required(),
  description: yup.string().required(),
  platform: yup.string().when('type', {
    is: 'Site Specific',
    then: yup.string().required(),
  }),
  siteID: yup.string().when('type', {
    is: 'Site Specific',
    then: yup.string().required(),
  }),
});

type SubmitTicketFormData = {
  type: string;
  subject: TicketType;
  platform: Platform;
  siteID: number;
  description: string;
};

type SubmitTicketProps = {
  zendeskUser: ZendeskUser | null;
  onChangeUser: (user: ZendeskUser) => void;
  onChangeCurrentlyViewingTicket: (ticket: Ticket) => void;
};

export type UploadedFile = {
  name: string;
  url: string;
  fileData: string;
  type: string;
};

export function SubmitTicket({ onChangeCurrentlyViewingTicket }: SubmitTicketProps) {
  const {
    register,
    control,
    watch,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SubmitTicketFormData>({
    resolver: yupResolver(submitTicketSchema),
  });
  const toast = useToast();
  const user = useSelector(selectUser);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [userSites, setUserSites] = useState<SiteData[]>([]);
  const [isSelectLoading, setSelectLoading] = useState<boolean>(false);
  const handleFileUpload = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading was aborted');
        };
        reader.onerror = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading has failed');
        };
        reader.onload = async () => {
          const fileData = reader.result as string;

          setUploadedFiles([
            ...uploadedFiles,
            {
              name: file.name,
              url: fileData,
              fileData,
              type: file.type,
            },
          ]);
        };

        reader.readAsDataURL(file);
      });
    },
    [toast, uploadedFiles]
  );
  const ticketType = watch('type');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSiteSelectKeyUp = useCallback(
    debounceEvent(async (event: React.KeyboardEvent<HTMLDivElement>) => {
      setSelectLoading(true);
      const target = event.target as HTMLSelectElement;
      const searchTerm = target.value;
      let uri = `/fleet/sites?limit=10&offset=0`;

      if (searchTerm) {
        uri += `&search_term=${searchTerm}`;
      }

      const { data: userSites } = await get<PaginatedResponse<SiteData>>(uri);
      setUserSites(userSites);
      setSelectLoading(false);
    }),
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: ['image/*', 'application/pdf'],
    multiple: true,
  });

  useEffect(() => {
    async function fetchUserSites() {
      const { data: userSites } = await get<PaginatedResponse<SiteData>>(`/fleet/sites?limit=10&offset=0`);
      setUserSites(userSites);
    }

    fetchUserSites();
  }, []);

  async function handleSubmit({ subject, description, siteID, platform }: SubmitTicketFormData) {
    try {
      const body: TicketToSave = {
        body: description,
        description,
        subject,
        platform: ticketType === 'General' ? 'Installer' : platform,
        tenant: user.tenant_id.toString(),
      };
      const uri =
        ticketType === 'General'
          ? `/fleet/users/${user.user_id}/create_general_ticket`
          : `/fleet/sites/${siteID}/users/${user.user_id}/create_ticket`;

      try {
        // Add uploaded files to the ticket
        const savedUploadedFiles = await Promise.all(
          uploadedFiles.map((f) =>
            post(`/fleet/upload_file_to_zendesk`, {
              file_base64: f.fileData,
              file_name: f.name,
              content_type: f.type,
            })
          )
        );

        if (savedUploadedFiles.length) {
          body.attachment_tokens = savedUploadedFiles.map((savedFile) => savedFile.token);
        }
      } catch (e) {
        toast({
          title: `Error uploading attachments`,
          description: 'Please contact support directly about this issue.',
          status: 'error',
          isClosable: true,
        });
      }

      // A user will be auto-created when a ticket is created. This takes some time, so we ping the endpoint based
      // on an interval at the top level.
      const ticket = await post<Ticket>(uri, body);

      toast({
        title: `Successfully created ticket #${ticket.id}`,
        description: "We'll get back to you as soon as we can.",
        status: 'success',
        isClosable: true,
      });

      onChangeCurrentlyViewingTicket(ticket);
    } catch (e) {
      toast({
        title: 'Error creating ticket',
        description: 'Please contact support directly about this issue.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  async function handleDeleteFile(file: UploadedFile) {
    setUploadedFiles(uploadedFiles.filter((existingFile) => existingFile.url !== file.url));
  }

  const existingFilesContents = (
    <Box>
      <Heading mt={2} size="md">
        Files
      </Heading>
      <Flex flexWrap="wrap">
        {uploadedFiles.map((file, i) => (
          <Center w="100px" h="100px" position="relative" key={i}>
            <Center
              as="button"
              type="button"
              onClick={() => handleDeleteFile(file)}
              h="25px"
              w="25px"
              bg="black"
              rounded={100}
              position="absolute"
              top={0}
              right={0}
            >
              <CloseIcon fontSize="xs" color="white" />
            </Center>
            <Image
              rounded={15}
              w="80px"
              h="80px"
              objectFit={'cover'}
              src={file.type.includes('image') ? file.url : uploadAttachmentImage}
              alt={'file'}
            />
          </Center>
        ))}

        <Center w="100px" h="100px">
          <Center
            rounded={15}
            justifySelf={'center'}
            flexDirection="column"
            border="2px"
            borderStyle="dashed"
            borderColor="day.500"
            bg="rgba(134, 181, 209, 0.2)"
            w="80px"
            h="80px"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Image mb={2} src={uploadAttachmentImage} alt="Upload icon" w="50%" />{' '}
          </Center>
        </Center>
      </Flex>
    </Box>
  );

  const noFilesContents = (
    <Center flexDirection={'column'} mt={3}>
      <Heading mb={2} size="md">
        Upload a file (optional)
      </Heading>
      <Center
        flexDirection="column"
        border="2px"
        borderStyle="dashed"
        rounded={5}
        borderColor="day.500"
        bg="rgba(134, 181, 209, 0.2)"
        h="200px"
        w="80%"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Image mb={1} src={uploadAttachmentImage} alt="Upload a file" w="20%" />
        <Text mb={3}>Upload a file here.</Text>
        <Button colorScheme="white" rounded={20} w="40%" variant="outline" mr={3} type="button">
          Upload
        </Button>
      </Center>
    </Center>
  );

  return (
    <Box as={'form'} onSubmit={handleFormSubmit(handleSubmit)}>
      <FormControl mb={2} isInvalid={!!errors.type}>
        <FormLabel>Ticket type</FormLabel>
        <Select data-testid="ticketType" {...register('type')}>
          {TYPES.map((type, i) => (
            <option key={`type-option-${i}`} value={type}>
              {type}
            </option>
          ))}
        </Select>
        <FormErrorMessage>Ticket type is required</FormErrorMessage>
      </FormControl>

      <FormControl mb={2} isInvalid={!!errors.subject} width={'100%'}>
        <FormLabel>Subject</FormLabel>
        <Input data-testid="ticketSubject" {...register('subject')} placeholder={'Unable to upload a bill'} />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <FormControl mb={2} isInvalid={!!errors.description} width={'100%'}>
        <FormLabel>Description</FormLabel>
        <Textarea
          data-testid="ticketDescription"
          {...register('description')}
          placeholder={'The bill upload keeps failing'}
        />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      {ticketType === 'Site Specific' && (
        <>
          <FormControl mb={2} isInvalid={!!errors.platform}>
            <FormLabel>Platform</FormLabel>
            <Select data-testid="ticketPlatform" {...register('platform')}>
              {PLATFORMS.map((platform, i) => (
                <option key={`platform-option-${i}`} value={platform}>
                  {platform}
                </option>
              ))}
            </Select>
            <FormErrorMessage>Platform is required</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.siteID}>
            <FormLabel>Related Site</FormLabel>
            <Box onKeyUp={handleSiteSelectKeyUp}>
              <SearchableDropdownFormControl
                isLoading={isSelectLoading}
                control={control}
                options={userSites}
                defaultValue={''}
                valueLabelOptions={['clipsal_solar_id', 'site_name']}
                fieldName={`siteID`}
                placeholder={'Select a site'}
                isInvalid={!!errors.siteID}
              />
            </Box>
            <FormErrorMessage>Site is required</FormErrorMessage>
          </FormControl>
        </>
      )}

      {uploadedFiles.length ? existingFilesContents : noFilesContents}

      <Button
        type="submit"
        mt={6}
        width={'100%'}
        alignSelf={'center'}
        rounded={20}
        colorScheme="primaryButton"
        data-testid="submitTicketDataBtn"
        isLoading={isSubmitting}
      >
        Submit
      </Button>
    </Box>
  );
}
