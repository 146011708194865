import {
  VStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useBreakpointValue,
  useDisclosure,
  InputGroup,
  InputRightElement,
  FormHelperText,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { WifiDetailsFormData, wifiDetailsSchema } from './wifi-config-helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { COMPONENT_MIN_HEIGHT } from '../../../../common/constants';
import { selectRawMeters } from '../meterSetupSlice';
import { useSelector } from 'react-redux';
import { WIFI_CONFIGURABLE_METER_MODELS } from 'clipsal-cortex-utils/src/constants/common-constants';
import CustomButton from '../../../../common/components/CustomButton';
import { useUpdateWifiDetailsMutation } from './wifiApi';
import { selectSite } from '../../siteSlice';
import networkInfoImg from '../../../../assets/images/network_info.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const WifiDetailsForm = ({ onToggleEditMode }: { onToggleEditMode: () => void }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WifiDetailsFormData>({
    defaultValues: { ssid: '', password: '' },
    resolver: yupResolver(wifiDetailsSchema),
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const site = useSelector(selectSite);
  const [saveWifiDetails, { isLoading: isSavingWifiDetails }] = useUpdateWifiDetailsMutation();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );
  const meters = useSelector(selectRawMeters);
  const eligibleMeters = Object.values(meters).filter((meter) => WIFI_CONFIGURABLE_METER_MODELS.includes(meter.model));
  const hasPendingUpdate = useMemo(
    () =>
      !!eligibleMeters?.some((meter) => {
        const pendingValues = meter.comms.wifi?.pending;
        if (!pendingValues) return false;
        // Only show the pending state if meter has a pending ssid or pskUpdatedAt
        return 'ssid' in pendingValues || 'pskUpdatedAt' in pendingValues;
      }),
    [meters]
  );
  const isWifiUpdatePending = hasPendingUpdate || isSavingWifiDetails;

  const handleFormSubmit = async (data: WifiDetailsFormData) => {
    try {
      await saveWifiDetails({
        body: {
          wifi_ssid: data.ssid,
          wifi_password: data.password,
        },
        siteId: site.clipsal_solar_id,
      }).unwrap();
      onToggleEditMode();
      toast({ title: 'Wifi details configured successfully!', status: 'success' });
    } catch (error) {
      toast({ title: 'Failed to configure wifi details!', status: 'error' });
    }
  };

  useEffect(() => {
    if (eligibleMeters.length) {
      const meterWithValidSSID = eligibleMeters.find((meter) => meter.comms.wifi?.ssid);
      const pendingSSID = eligibleMeters.find((meter) => meter.comms.wifi?.pending)?.comms.wifi?.ssid;
      const ssid = pendingSSID ?? meterWithValidSSID?.comms.wifi?.ssid ?? '';
      reset({ ssid, password: '' });
    }
  }, []);

  return (
    <VStack
      minH={`calc(${COMPONENT_MIN_HEIGHT} - ${isMobileViewport ? 60 : 200}px)`}
      px={2}
      data-testid="wifi-setup-screen"
    >
      <FormControl isInvalid={!!errors?.ssid} data-testid="ssid">
        <FormLabel>Wi-Fi Network (SSID)</FormLabel>
        <Input {...register('ssid')} type="text" placeholder={'Enter SSID'} isDisabled={isWifiUpdatePending} />
        <FormErrorMessage>{errors?.ssid?.message}</FormErrorMessage>
        <FormHelperText>Note: Wi-Fi Network (SSID) is case-sensitive.</FormHelperText>
      </FormControl>

      <FormControl isInvalid={!!errors?.password} data-testid="password">
        <FormLabel>Password</FormLabel>
        <InputGroup>
          <Input
            {...register('password')}
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder={isWifiUpdatePending ? 'XXXXXXXXX' : 'Enter Password'}
            autoCorrect="off"
            autoCapitalize="none"
            isDisabled={isWifiUpdatePending}
          />
          <InputRightElement
            onClick={() => !isWifiUpdatePending && setIsPasswordVisible((prevState) => !prevState)}
            opacity={isWifiUpdatePending ? 0.5 : 1}
            cursor={isWifiUpdatePending ? 'not-allowed' : 'pointer'}
          >
            {isPasswordVisible ? <ViewOffIcon w={5} h={5} /> : <ViewIcon w={5} h={5} />}
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
      </FormControl>

      <Link w="100%" my={6} color="customLinkBlue.500" onClick={onOpen} data-testid="wifi-connection-info-button">
        How do I know if Wi-Fi network is connected?
      </Link>

      {isMobileViewport ? (
        <Drawer isOpen={isOpen} onClose={onClose} size={'xl'} placement="bottom">
          <DrawerOverlay />
          <DrawerContent minH={'92vh'} borderTopRadius={10} data-testid="wifi-connection-drawer">
            <DrawerCloseButton />
            <DrawerHeader>More Information</DrawerHeader>
            <DrawerBody p={0}>
              <Image src={networkInfoImg} alt="network information" w="100%" borderBottomRadius={10} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent data-testid="wifi-connection-modal">
            <ModalHeader>More Information</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
              <Image src={networkInfoImg} alt="network information" w="100%" borderBottomRadius={10} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Text w="100%">
        By entering your Wi-Fi credentials here, it will applied to the following Clipsal Cortex meters:
      </Text>

      {eligibleMeters.map((meter, index) => (
        <Box key={meter.ww_device_id} w="100%" mt={4}>
          <Text>{meter.label ?? `Meter ${index}`}</Text>
          <Text border={'1px solid'} w="fit-content" borderRadius={'100px'} px={2} mt={4}>
            {meter.ww_device_id}
          </Text>
        </Box>
      ))}

      <CustomButton
        data-testid="save-wifi-config-btn"
        minW={260}
        containerProps={{ mt: 'auto' }}
        mt={4}
        onClick={handleSubmit(handleFormSubmit)}
        isLoading={isWifiUpdatePending}
        loadingText={hasPendingUpdate ? 'Update in progress...' : 'Saving...'}
      >
        Save
      </CustomButton>
    </VStack>
  );
};

export default WifiDetailsForm;
