import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Box, Divider, Heading, useBreakpointValue, useColorModeValue, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import NestedInverterFieldArray from './NestedInverterFieldArray';
import BatteryForm from './BatteryForm';
import { selectSite, setCurrentSite, SiteState } from '../siteSlice';
import { get, patch, post } from '../../../api/api-helpers';
import { selectUser } from '../../user/userSlice';
import { selectModelsByDeviceType, setBatteries, setEVChargers, setInverters } from './systemDetailsSlice';
import { useAppDispatch } from '../../../app/hooks';
import { InstalledDevice } from '../../../api/api-device';
import { InverterOrientation } from '../../../api/api-orientation';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import {
  mapBatteriesToAPI,
  mapBatteriesToForm,
  mapEVChargersToAPI,
  mapEVChargersToForm,
  mapInvertersToAPI,
  mapInvertersToForm,
  mapOrientationsToAPI,
} from './form-mapping-helpers';
import { schema } from './system-details-validation-schema';
import { InverterFormData, SystemDetailsFormData } from './system-details-form-types';
import { SiteData } from '../../../api/api-site';
import CustomButton from '../../../common/components/CustomButton';
import { WizardSubRouteCardWrapper } from '../../wizard/WizardSubRouteCardWrapper';
import { setHasUnsavedChanges } from '../../wizard/wizardSlice';
import { selectRawMeters } from '../meter-setup/meterSetupSlice';
import { selectWebSocketState, updateSubscribedSite } from '../meter-setup/webSocketSlice';
import EVChargerForm from './EVChargerForm';
import { SiteRouteChangeContext, SiteRouteChangeContextProps } from '../Site';

export default function SystemDetails() {
  const { onMoveForward } = useContext<SiteRouteChangeContextProps>(SiteRouteChangeContext);
  const {
    register,
    control,
    setValue,
    reset,
    getValues,
    clearErrors,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SystemDetailsFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      inverters: [],
      batteries: [],
      hasSolarSystem: false,
      hasBattery: false,
      hasEVCharger: false,
    },
  });
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const meters = useSelector(selectRawMeters);
  const { webSocket, subscribedSiteId } = useSelector(selectWebSocketState);
  const dispatch = useAppDispatch();
  const [isLoaded, setLoaded] = useState(false);
  const toast = useToast();
  const inverterModelsByManufacturerId = useSelector(selectModelsByDeviceType('INVERTER'));
  const batteryModelsByManufacturerId = useSelector(selectModelsByDeviceType('BATTERY_PACK'));
  const evChargerModelsByManufacturerId = useSelector(selectModelsByDeviceType('EV_CHARGER'));
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });
  const dividerColor = useColorModeValue('#0000001A', 'dusk100.400');

  const {
    fields: batteryFields,
    append: appendBattery,
    remove: removeBattery,
  } = useFieldArray({
    name: `batteries`,
    control,
  });

  const {
    fields: evChargerFields,
    append: appendEVCharger,
    remove: removeEVCharger,
  } = useFieldArray({
    name: `evChargers`,
    control,
  });

  useEffect(() => {
    async function fetchAPI() {
      dispatch(setHasUnsavedChanges(false));

      const [inverters, batteries, evChargers] = await Promise.all([
        get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/inverters`),
        get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/batteries`),
        get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/ev_chargers`),
      ]);

      const newFormValues = { ...getValues() };

      const siteHasInverter = !!inverters.length && !site.is_consumption_only;

      if (siteHasInverter) {
        // Get orientations for each inverter
        const invertersWithOrientations: (InstalledDevice & {
          orientations: InverterOrientation[];
        })[] = [];
        for (const inverter of inverters) {
          if (inverter.row_id) {
            const orientations = await get<InverterOrientation[]>(`/fleet/inverters/${inverter.row_id}/orientations`);
            invertersWithOrientations.push({
              ...inverter,
              orientations,
            });
          }
        }

        newFormValues.hasSolarSystem = true;
        const mappedInverters = mapInvertersToForm(invertersWithOrientations);
        newFormValues.inverters = mappedInverters;
      }

      if (batteries.length) {
        newFormValues.hasBattery = true;
        newFormValues.batteries = mapBatteriesToForm(batteries);
      }

      if (evChargers.length) {
        newFormValues.hasEVCharger = true;
        newFormValues.evChargers = mapEVChargersToForm(evChargers);
      }

      reset(newFormValues);
      setLoaded(true);
    }

    if (site.isLoaded && !isLoaded) fetchAPI();
  }, [user.token, site, dispatch, isLoaded, getValues, reset]);

  async function handleSubmit(values: SystemDetailsFormData) {
    dispatch(setHasUnsavedChanges(false));
    try {
      let isConsumptionOnly = true;
      if (values.hasSolarSystem) {
        isConsumptionOnly = false;
        const inverters = mapInvertersToAPI(values.inverters, site.clipsal_solar_id, inverterModelsByManufacturerId);

        const savedInverterData = await post<InstalledDevice[]>(
          `/fleet/sites/${site.clipsal_solar_id}/devices`,
          inverters
        );
        dispatch(setInverters(savedInverterData));

        // Map back to form structure
        const inverterFormDataSaved = savedInverterData.map<InverterFormData>((inverter, inverterIndex) => ({
          inverterId: inverter.row_id as number,
          hasSolarPanels: values.inverters[inverterIndex].hasSolarPanels,
          hasDCCoupledBattery: values.inverters[inverterIndex].hasDCCoupledBattery,
          manufacturer: inverter.meta_data.manufacturer_id,
          model: inverter.meta_data.device_metadata_id,
          serialNumber: inverter.serial_number,
          apiKey: inverter.api_key,
          siteIdentifier: inverter.site_identifier,
          totalPanels: values.inverters[inverterIndex].totalPanels,
          orientations: values.inverters[inverterIndex].orientations,
        }));

        await Promise.all<InverterOrientation[]>(
          inverterFormDataSaved.map((inverter) =>
            post(
              `/fleet/inverters/${inverter.inverterId}/orientations`,
              mapOrientationsToAPI(inverter.orientations, inverter.inverterId as number)
            )
          )
        );
      }

      if (values.hasBattery) {
        isConsumptionOnly = false;
        const batteries = await post<InstalledDevice[]>(
          `/fleet/sites/${site.clipsal_solar_id}/devices`,
          mapBatteriesToAPI(values.batteries, site.clipsal_solar_id, batteryModelsByManufacturerId)
        );

        dispatch(setBatteries(batteries));
      }

      if (values.hasEVCharger) {
        isConsumptionOnly = false;
        const evChargers = await post<InstalledDevice[]>(
          `/fleet/sites/${site.clipsal_solar_id}/devices`,
          mapEVChargersToAPI(values.evChargers, site.clipsal_solar_id, evChargerModelsByManufacturerId)
        );

        dispatch(setEVChargers(evChargers));
      }

      if (isConsumptionOnly || (!isConsumptionOnly && site.is_consumption_only)) {
        // Set this site as consumption only.
        const updatedSite: SiteState = { ...site, is_consumption_only: isConsumptionOnly };
        dispatch(setCurrentSite(updatedSite));

        // Use a partial type on a new variable reference so we avoid compiler issues when removing unnecessary fields.
        const siteToSave = {
          clipsal_solar_id: updatedSite.clipsal_solar_id,
          is_consumption_only: isConsumptionOnly,
          site_address_line1: updatedSite.site_address_line1,
        };
        await patch<SiteData>(`/fleet/sites/${site.clipsal_solar_id}`, siteToSave);
      }

      await post(`/fleet/sites/${site.clipsal_solar_id}/system_details_complete`, {});

      // if meters have been loaded, need to subscribe to site again
      if (Object.keys(meters).length) {
        // The websocket exists but is not subscribed to the site currently being commissioned -- update that.
        if (subscribedSiteId !== site.clipsal_solar_id) dispatch(updateSubscribedSite(site.clipsal_solar_id));
        webSocket?.sendJsonMessage({ action: 'subscribeSite', clipsal_solar_id: site.clipsal_solar_id });
      }
      onMoveForward();
    } catch (e) {
      console.error(e);
      dispatch(setHasUnsavedChanges(false));

      toast({
        title: 'Something went wrong saving system details.',
        description: 'Please try again. If this continues, please contact support.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  const commonSubFormProps = {
    register,
    control,
    errors,
    setValue,
    getValues,
    clearErrors,
    batteryFields,
    appendBattery,
    removeBattery,
    evChargerFields,
    appendEVCharger,
    removeEVCharger,
  };

  return (
    <WizardSubRouteCardWrapper p={[0]}>
      {!isLoaded ? (
        <CenteredLoader />
      ) : (
        <Box
          data-testid="system-details-form"
          as={'form'}
          onChange={() => dispatch(setHasUnsavedChanges(true))}
          onSubmit={handleFormSubmit(handleSubmit)}
        >
          {!isMobileViewport && (
            <Heading my={4} ml={[4, 6]} size={'lg'}>
              System Details
            </Heading>
          )}
          <Divider borderColor={dividerColor} opacity={1} mb={2} />

          <NestedInverterFieldArray {...commonSubFormProps} />

          <BatteryForm {...commonSubFormProps} />

          <EVChargerForm {...commonSubFormProps} />

          <CustomButton isLoading={isSubmitting} data-testid="system-details-form-submit">
            Next: Meter Setup
          </CustomButton>
        </Box>
      )}
    </WizardSubRouteCardWrapper>
  );
}
