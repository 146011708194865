import { ChannelData, WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { ShortEnergy } from '../../../../api/api-short-energy';

const ONE_SECOND_IN_MS = 1000;

const FIVE_SECONDS_IN_MS = ONE_SECOND_IN_MS * 5;

/**
 * Aggregates multiple arrays of meter data into one, by first creating a map of 5-second interval values between the
 * start and end time of the first meter's data, then populating the map with data from each array.
 * Reasons for this implementation:
 * - Data from multiple meters can be provided at several timestamps per meter (e.g. 30-second interval data
 *   could be provided at timestamps XX:15, XX:45 on one meter, but at XX:10, XX:40 on another, leading to slight
 *   differences in timestamp values, and the recharts library does not support time scaled x axes (categorical or
 *   numerical only). Data could be in different intervals across a single array, e.g. some data in an array may be
 *   at 30-second intervals, but some other data might be at 5-second intervals, leading to issues with time scaling
 *   on the categorical x axis. We solve this by normalizing the axis in 5-second intervals.
 *
 * @param meterData - 2d array of meter data, where each top-level index matches the index of the provided meter.
 * @param dataProperty - The property to pull from the WW data and provide to the transformed chart data.
 * @param applicableCircuits - Array of circuits to be plotted in the chart data.
 * @param applicableMeters - Array of WW meter objects corresponding to the applicable circuits.
 */
export default function transformMeterData(
  meterData: ShortEnergy[][],
  dataProperty: 'powerFactor' | 'pRealKw',
  applicableCircuits: ChannelData[],
  applicableMeters: WattwatchersMeter[]
): Record<string, number | string>[] {
  // Pad data per 5 second timestamp
  let currentTimestampInMs = (meterData[0][0].timestamp || 0) * 1000;
  const lastTimestampInMs = (meterData[0][meterData[0].length - 1].timestamp || 0) * 1000;

  const timestampInMsToData: Record<string, Record<string, number | string>> = {};

  // Fill an empty map
  while (currentTimestampInMs <= lastTimestampInMs) {
    const emptyCircuitValues = applicableCircuits.reduce<Record<string, null>>((emptyChartValues, circuit) => {
      const [meterId, circuitNumber] = circuit.ww_circuit_id.split('_');
      const meter = applicableMeters.find((m) => m.ww_device_id === meterId);
      const nameInChart = `${circuit.circuit_name} (${meter?.label}, ${circuitNumber})`;
      emptyChartValues[nameInChart] = null;
      return emptyChartValues;
    }, {});

    timestampInMsToData[currentTimestampInMs] = {
      timestamp: currentTimestampInMs,
      ...emptyCircuitValues,
    };

    currentTimestampInMs += FIVE_SECONDS_IN_MS;
  }

  let meterIndex = 0;
  // Iterate each array of meter data and assign values
  for (const shortEnergyData of meterData) {
    // Iterate each interval in this meter's data
    for (const intervalData of shortEnergyData) {
      const timestampInMs = intervalData.timestamp * ONE_SECOND_IN_MS;

      for (const applicableCircuit of applicableCircuits) {
        // Get this circuit's index
        const circuitIndex =
          Number(applicableCircuit.ww_circuit_id.charAt(applicableCircuit.ww_circuit_id.length - 1)) - 1;
        const meterForCircuit = applicableMeters[meterIndex];
        const [meterId, circuitNumber] = applicableCircuit.ww_circuit_id.split('_');

        if (meterForCircuit.ww_device_id === meterId) {
          // Add the data at this circuit index for the specified key
          const meter = applicableMeters.find((m) => m.ww_device_id === meterId);
          const nameInChart = `${applicableCircuit.circuit_name} (${meter?.label}, ${circuitNumber})`;
          const entry = intervalData[dataProperty];

          if (timestampInMs in timestampInMsToData) {
            timestampInMsToData[timestampInMs] = {
              ...timestampInMsToData[timestampInMs],
              [nameInChart]: entry[circuitIndex],
            };
          }
        }
      }
    }

    meterIndex++;
  }

  return Object.values(timestampInMsToData);
}
