import React, { PropsWithChildren } from 'react';
import {
  Avatar,
  BoxProps,
  Button,
  Center,
  Flex,
  Grid,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import { INVITATION_STATUS_CONFIG } from './customer-details-helpers';
import { BsThreeDots } from 'react-icons/bs';
import { InvitationStatus, UserData } from '../../../api/api-user';
import { DeleteIcon, EditIcon, EmailIcon } from '@chakra-ui/icons';
import {
  useRemoveConfirmedUserMutation,
  useDeleteInvitedUserMutation,
  useResendInvitationMutation,
} from './siteUsersApi';
import { selectSite } from '../siteSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type UserDetailsProps = {
  userId?: UserData['user_id'];
  username: UserData['user_name'];
  email: UserData['user_email'];
  firstName: UserData['user_first_name'];
  lastName: UserData['user_last_name'];
  fullName?: UserData['user_full_name'];
  invitiationStatus: InvitationStatus;
  containerProps?: BoxProps;
  isDisabled?: boolean;
  index: number;
};

export default function UserDetails({
  userId,
  username,
  email,
  firstName,
  lastName,
  fullName,
  invitiationStatus,
  children,
  index,
  isDisabled,
  containerProps,
}: PropsWithChildren<UserDetailsProps>) {
  const theme = useTheme();
  const site = useSelector(selectSite);
  const toast = useToast({ isClosable: true, status: 'success' });
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const { label, color } = INVITATION_STATUS_CONFIG[invitiationStatus];
  const lightenedColor = `${theme.colors[color][500]}1A`;
  const userFullName = fullName || `${firstName} ${lastName}`;
  const isConfirmedUser = invitiationStatus === 'CONFIRMED';
  const navigate = useNavigate();
  const { clipsal_solar_id } = useSelector(selectSite);
  const [removeConfirmedUser] = useRemoveConfirmedUserMutation();
  const [deleteInvitedUser] = useDeleteInvitedUserMutation();
  const [resendInvitation] = useResendInvitationMutation();

  return (
    <Flex
      justify={'space-between'}
      align="center"
      bg={backgroundColor}
      my={2}
      px={2}
      data-testid={`${invitiationStatus}-user-${index}-details`}
      {...containerProps}
    >
      <Flex align="flex-start" py={4} data-testid="user" overflow={'hidden'}>
        <Grid gridTemplateColumns={'40px minmax(0, 1fr)'} w="100%">
          <Avatar name={userFullName} size={'24px'} w={10} h={10} fontSize={'sm'} color="white" mt={1} />
          <Flex direction="column" mx={2}>
            <Text fontWeight={500} noOfLines={1}>
              {userFullName}
            </Text>
            <Text fontSize={'sm'} color="gray" noOfLines={1} maxW={'100%'}>
              {email}
            </Text>
          </Flex>
        </Grid>
      </Flex>

      {invitiationStatus !== 'UNINVITED' && (
        <Center>
          <Text
            fontWeight={500}
            fontSize={14}
            color={theme.colors[color][500]}
            bg={lightenedColor}
            py={1}
            px={2}
            borderRadius={50}
            minW={90}
            textAlign={'center'}
          >
            {label}
          </Text>
          <Menu>
            <MenuButton
              data-testid={`${invitiationStatus}-user-${index}-menu-button`}
              as={Button}
              variant={'ghost'}
              p={2}
              minW={8}
              h={'fit-content'}
              ml={2}
              isDisabled={isDisabled}
            >
              <Icon as={BsThreeDots} />
            </MenuButton>
            <MenuList>
              <MenuItem
                data-testid={`${invitiationStatus}-user-${index}-delete`}
                icon={<DeleteIcon />}
                onClick={async () => {
                  if (!isConfirmedUser) {
                    const response = await deleteInvitedUser({ clipsal_solar_id, username: username });
                    const isError = 'error' in response;
                    toast({
                      title: isError ? 'Failed deleting invitation!' : 'Successfully deleted invitation!',
                      status: isError ? 'error' : 'success',
                    });
                  } else if (userId) {
                    const response = await removeConfirmedUser({ clipsal_solar_id, userId: userId });
                    const isError = 'error' in response;
                    toast({
                      title: isError ? 'Failed removing user from site!' : 'Successfully removed user from site!',
                      status: isError ? 'error' : 'success',
                    });
                  }
                }}
              >
                {isConfirmedUser ? 'Remove from site' : 'Delete invitation'}
              </MenuItem>
              <MenuItem
                data-testid={`${invitiationStatus}-user-${index}-edit`}
                icon={<EditIcon />}
                onClick={() => {
                  const backUrlParam = `backUrl=/site/${site.clipsal_solar_id}/customer_details`;
                  const userDataParams = `status=${invitiationStatus}&userId=${userId}&username=${username}`;
                  navigate(`/account/manage_users?${userDataParams}&${backUrlParam}`);
                }}
              >
                Edit Customer Details
              </MenuItem>
              {!isConfirmedUser && (
                <MenuItem
                  data-testid={`${invitiationStatus}-user-${index}-resend-invitation`}
                  icon={<EmailIcon />}
                  onClick={async () => {
                    const response = await resendInvitation(username);
                    const isError = 'error' in response;
                    toast({
                      title: isError ? 'Failed resending invitation!' : 'Successfully resent invitation!',
                      status: isError ? 'error' : 'success',
                    });
                  }}
                >
                  Resend Invitation
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Center>
      )}

      {children}
    </Flex>
  );
}
