import { Box, BoxProps, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

type Props = {
  bg: string;
  title: string;
  value: number | null;
  suffix?: string;
  isRenderingPdf?: boolean;
};

export default function BatteryInfoStats({
  bg,
  title,
  value,
  suffix = 'kWh',
  isRenderingPdf,
  ...boxProps
}: Props & BoxProps) {
  return (
    <Flex {...boxProps}>
      <Box rounded={10} mr={2} w={'8px'} bg={bg} />
      <Box h="fit-content" color={'black'} _dark={{ color: 'white' }}>
        <Box>{title}</Box>

        <Flex align={'center'}>
          <Heading data-testid={`fleet-info-stats-${title.replaceAll(' ', '-').toLowerCase()}`} size="md">
            {value?.toFixed(1) ?? 'N/A'}
          </Heading>
          <Text ml={1} mb={isRenderingPdf ? '-2px' : 0} fontSize={'sm'} mt="auto">
            {suffix}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}
