import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useSearchMeters, useSearchSites } from './meterConfigApi';
import { CloseIcon } from '@chakra-ui/icons';
import { debounceEvent } from '../../../../utils/component-helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SiteMeterData } from './meter-config-types';

type SearchType = 'site' | 'meter';

const SEARCH_FILTER_OPTIONS = [
  { label: 'Site Id/Name', value: 'site' },
  { label: 'Serial Number', value: 'meter' },
];

const SEARCH_FILTER_VALUE_TO_LABEL = {
  site: 'Site ID or Name',
  meter: 'Meter Serial Number',
};

const INITIAL_METER_SEARCH_STATE = {
  isResultMenuOpen: false,
  searchType: 'site' as SearchType,
  searchTerm: '',
};

type MeterSearchFormProps = {
  meterId: string;
  onUpdateMeterId: (meterId: string, siteMeterId: number | null) => void;
};

const MeterSearchForm = ({ meterId, onUpdateMeterId }: MeterSearchFormProps) => {
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const [{ searchType, searchTerm, isResultMenuOpen }, setState] = useState({
    ...INITIAL_METER_SEARCH_STATE,
    searchType: (urlParams.get('meterId') ? 'meter' : 'site') as SearchType,
  });
  const {
    data: sites,
    searchResults: siteResults,
    isLoading: isSearchingSites,
    isFetching: isFetchingSites,
  } = useSearchSites(searchTerm, searchType === 'meter');
  const {
    data: meterResults,
    isLoading: isSearchingMeters,
    isFetching: isFetchingMeters,
  } = useSearchMeters(searchTerm, searchType === 'site');
  const isSearching =
    searchType === 'site' ? isSearchingSites || isFetchingSites : isSearchingMeters || isFetchingMeters;
  const searchResults = searchType === 'site' ? siteResults : meterResults;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);
  useOutsideClick({
    ref: tableRef,
    handler: () => setState((prevState) => ({ ...prevState, isResultMenuOpen: false })),
  });
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });

  const handleSearch = React.useMemo(
    () =>
      debounceEvent(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value.trim();
        setState((prevState) => ({ ...prevState, searchTerm, isResultMenuOpen: !!searchTerm }));
      }, 600),
    [searchType]
  );

  const handleSelectMeter = (data: SiteMeterData) => {
    if (!data.site_identifier) return;
    const newMeterId = (data.site_identifier ?? '').trim();

    if (newMeterId === meterId) {
      setState((prevState) => ({ ...prevState, isResultMenuOpen: false }));
      return;
    }

    if (searchType === 'meter' && inputRef.current) inputRef.current.value = newMeterId;

    // Check if this meter is a site meter
    let siteMeterId = null;
    if (data.clipsal_solar_id) {
      siteMeterId =
        sites?.data
          ?.find((site) => site.clipsal_solar_id === data.clipsal_solar_id)
          ?.meters?.find((meter) => meter.site_identifier === newMeterId)?.row_id ?? null;
    }

    // update the URL
    let searchString = `meterId=${newMeterId}`;
    if (siteMeterId) searchString += `&siteMeterId=${siteMeterId}`;
    navigate(`/account/meter_configuration?${searchString}`, {
      replace: true,
    });

    onUpdateMeterId(newMeterId, siteMeterId);

    setState((prevState) => ({
      ...prevState,
      isResultMenuOpen: false,
    }));
  };

  return (
    <Flex
      align={'center'}
      mx={4}
      mt={2}
      direction={isMobileViewport ? 'column' : 'row'}
      data-testid="meter-search-form"
    >
      <FormControl maxW={isMobileViewport ? undefined : '400px'} zIndex={2}>
        <FormLabel>Search by {SEARCH_FILTER_VALUE_TO_LABEL[searchType]}</FormLabel>
        <InputGroup size="md" position="relative" ref={tableRef}>
          <Input
            data-testid={'meter-search-input'}
            ref={inputRef}
            defaultValue={urlParams.get('meterId') ?? ''}
            onFocus={() => setState((prevState) => ({ ...prevState, isResultMenuOpen: !!searchResults.length }))}
            onChange={handleSearch}
            py={'20px'}
            placeholder={`Enter ${SEARCH_FILTER_VALUE_TO_LABEL[searchType]}`}
          />
          <InputRightElement height="100%">
            {isSearching ? (
              <Spinner my="auto" color="gray.500" size="sm" />
            ) : (
              isResultMenuOpen && (
                <IconButton
                  ref={closeButtonRef}
                  aria-label="Reset Search"
                  variant={'ghost'}
                  icon={<CloseIcon w={2} h={2} />}
                  onClick={() => {
                    if (inputRef?.current) inputRef.current.value = '';
                    setState((prevState) => ({
                      ...INITIAL_METER_SEARCH_STATE,
                      searchType: prevState.searchType,
                    }));
                  }}
                />
              )
            )}
          </InputRightElement>

          {isResultMenuOpen && searchTerm && (
            <TableContainer
              shadow=" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
              bg={'white'}
              _dark={{ bg: 'gray.900' }}
              borderRadius="0px 0px 10px 10px"
              position="absolute"
              top={43}
              left={0}
              zIndex={1}
              w={'100%'}
              data-testid="search-results-table"
            >
              {!searchResults.length && (
                <Text py={12} align="center">
                  {isSearching ? 'Searching...' : 'No search results found. Please try again.'}
                </Text>
              )}

              {!!searchResults.length && (
                <Table variant="simple" size="sm" colorScheme="blue">
                  <Thead>
                    <Tr>
                      <Th border={'0px !important'} fontSize="13px" pt={2}>
                        Site ID
                      </Th>
                      <Th border={'0px !important'} fontSize="13px">
                        Site Name
                      </Th>
                      <Th border={'0px !important'} fontSize="13px">
                        Serial Number
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {searchResults.map((data, idx) => {
                      const hasWattwatchersMeter = !!data.site_identifier;
                      const isSelected = data.site_identifier === meterId;

                      return (
                        <Tr
                          data-testid={`search-result-tr-${idx}`}
                          key={(data.site_name ?? 'site-') + idx}
                          position="relative"
                          bg={isSelected ? 'green.50' : undefined}
                          _hover={{
                            bg: hasWattwatchersMeter ? 'blue.50' : 'gray.50',
                            cursor: hasWattwatchersMeter ? 'pointer' : 'not-allowed',
                          }}
                          _dark={{
                            bg: isSelected ? 'green.900' : undefined,
                            _hover: {
                              bg: hasWattwatchersMeter ? 'blue.900' : 'gray.700',
                            },
                          }}
                          onClick={() => handleSelectMeter(data)}
                        >
                          <Td border={'0px !important'}>{data.clipsal_solar_id || 'N/A'}</Td>
                          <Td border={'0px !important'} maxW="120px" isTruncated>
                            {data.site_name || 'N/A'}
                          </Td>
                          <Td border={'0px !important'}>{data.site_identifier ?? 'No Valid Meter'}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </TableContainer>
          )}
        </InputGroup>
      </FormControl>
      <FormControl
        maxW={isMobileViewport ? undefined : '180px'}
        ml={isMobileViewport ? 0 : 4}
        mt={isMobileViewport ? 2 : 0}
      >
        <FormLabel>Search Filter</FormLabel>
        <Select
          data-testid="search-filter-select"
          value={searchType}
          onChange={(event) => {
            const searchType = event.target.value as SearchType;
            if (inputRef?.current) inputRef.current.value = '';
            setState((prevState) => ({
              ...prevState,
              searchType,
              searchTerm: '',
            }));
          }}
        >
          {SEARCH_FILTER_OPTIONS.map(({ label, value }) => (
            <option value={value} key={value} data-testid={value}>
              {label}
            </option>
          ))}
        </Select>
      </FormControl>
    </Flex>
  );
};

export default MeterSearchForm;
