import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, HStack, BoxProps } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import useInterval from 'clipsal-cortex-utils/src/hooks/use-interval';
import useActiveBrowserTab from 'clipsal-cortex-utils/src/hooks/use-active-browser-tab';

export type CarouselProps = {
  slides: React.ReactNode[];
  backgroundImgSrc?: string;
  containerProps?: BoxProps;
  navContainerProps?: BoxProps;
  navDotProps?: BoxProps;
  navArrowProps?: BoxProps;
  autoSlide?: boolean;
  autoSlideInMs?: number;
};

const DEFAULT_ARROW_STYLES = {
  color: '#102E3D',
  cursor: 'pointer',
  p: '16px',
  bg: 'white',
  transition: '0.6s ease',
  borderRadius: '50%',
  h: '38px',
  w: '38px',
  _hover: {
    opacity: 0.8,
    bg: 'white',
  },
} as const;

const getCurrentTimestamp = () => new Date().getTime();

export const Carousel = ({
  slides,
  containerProps,
  navDotProps,
  navArrowProps,
  navContainerProps,
  backgroundImgSrc,
  autoSlide = true,
  autoSlideInMs = 5000,
}: CarouselProps) => {
  const [currentSlideIndex, setState] = useState(0);

  // autopause slide if user has cursor over it
  const [isAutoSlidePaused, setIsAutoSlidePaused] = useState(false);
  const slidesCount = slides.length;

  // avoids janky animations when switching from other tabs, especially when in inactive state
  const isActiveBrowserTab = useActiveBrowserTab();

  // helps prevent moving to next/previous slide at a time interval close to the next tick of autoslide
  const manualSlideTimestamp = useRef(getCurrentTimestamp());

  const handlePrevSlide = () => {
    setState((prevSlideIndex) => (prevSlideIndex === 0 ? slidesCount - 1 : prevSlideIndex - 1));
    manualSlideTimestamp.current = getCurrentTimestamp();
  };

  const handleNextSlide = () => {
    setState((prevSlideIndex) => (prevSlideIndex === slidesCount - 1 ? 0 : prevSlideIndex + 1));
    manualSlideTimestamp.current = getCurrentTimestamp();
  };

  useInterval(
    () => {
      const currentTime = getCurrentTimestamp();
      const differenceInMs = currentTime - manualSlideTimestamp.current;

      // only autoSlide if last user's manual slide timestamp was more than autoSlideInMs ago
      if (differenceInMs >= autoSlideInMs) {
        setState((prevSlideIndex) => (prevSlideIndex === slidesCount - 1 ? 0 : prevSlideIndex + 1));
      }
    },
    autoSlide && isActiveBrowserTab && !isAutoSlidePaused ? autoSlideInMs : null
  );

  return (
    <Flex
      {...{
        boxSize: 'full',
        bg: '#edf3f8',
        _dark: {
          bg: '#3e3e3e',
        },
        alignItems: 'center',
        justifyContent: 'center',
        ...containerProps,
      }}
    >
      <Box
        boxSize="full"
        backgroundImage={`url(${backgroundImgSrc})`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        pb={100}
        overflow="hidden"
      >
        <Flex boxSize="full" pos="relative">
          <Flex boxSize="full" transition="all .5s" ml={`-${currentSlideIndex * 100}%`}>
            {slides.map((slide, sid) => (
              <Box
                key={`slide-${sid}`}
                className={`slide-${sid}`}
                boxSize="full"
                flex="none"
                onMouseOver={() => autoSlide && setIsAutoSlidePaused(true)}
                onMouseOut={() => autoSlide && setIsAutoSlidePaused(false)}
                onTouchStart={() => autoSlide && setIsAutoSlidePaused(true)}
                onTouchEnd={() => autoSlide && setIsAutoSlidePaused(false)}
              >
                {slide}
              </Box>
            ))}
          </Flex>

          {/* Nav Elements */}
          <Center
            w="full"
            justifyContent={'space-between'}
            position={'absolute'}
            bottom={-100}
            className="nav"
            {...navContainerProps}
          >
            <Center {...{ ...DEFAULT_ARROW_STYLES, ...navArrowProps }} ml={8} onClick={handlePrevSlide}>
              <ChevronLeftIcon w={8} h={8} fill="none" />
            </Center>

            <HStack justify="center" w="fit-content">
              {Array.from({
                length: slidesCount,
              }).map((_, slideIndex) => {
                const isCurrentSlide = currentSlideIndex === slideIndex;
                return (
                  <Box
                    key={`dots-${slideIndex}`}
                    cursor="pointer"
                    boxSize={isCurrentSlide ? '10px' : '6px'}
                    bg={isCurrentSlide ? 'white' : 'whiteAlpha.500'}
                    rounded="50%"
                    display="inline-block"
                    transition="background-color 0.6s ease"
                    _hover={{
                      bg: 'whiteAlpha.800',
                    }}
                    {...navDotProps}
                    onClick={() => setState(slideIndex)}
                  />
                );
              })}
            </HStack>

            <Center {...{ ...DEFAULT_ARROW_STYLES, ...navArrowProps }} mr={8} onClick={handleNextSlide}>
              <ChevronRightIcon w={8} h={8} fill="none" />
            </Center>
          </Center>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Carousel;
