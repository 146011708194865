import { useSelector } from 'react-redux';
import { selectSite } from '../../siteSlice';
import { SiteSwitch } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { PatchSiteSwitch } from './switch-config-form-types';
import { baseApi } from '../../../../app/baseApi';
import {
  SwitchIntelligentControl,
  SwitchIntelligentControlToSave,
  SwitchStateProfile,
} from 'clipsal-cortex-types/src/api/api-ww-switch';
import { SwitchSchedule, SwitchScheduleToCreate } from 'clipsal-cortex-types/src/api/api-switch-schedule';
import { del, get, patch, post } from '../../../../api/api-helpers';

export const switchApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSwitches: builder.query<SiteSwitch[], number>({
      query: (siteId) => {
        return {
          url: `/fleet/sites/${siteId}/switches`,
          method: 'GET',
        };
      },
      providesTags: ['Switches'],
    }),
    patchSwitches: builder.mutation<SiteSwitch[], { body: Omit<PatchSiteSwitch, 'oem_switch_id'>; switchId: number }[]>(
      {
        queryFn: async (switchData) => {
          const data = await Promise.all(
            switchData.map(({ switchId, body }) => patch<SiteSwitch>(`/fleet/switches/${switchId}`, body))
          );

          return {
            data,
          };
        },
        invalidatesTags: ['Switches'],
      }
    ),
    updateSwitchIntelligentControls: builder.mutation<
      SwitchIntelligentControl[],
      { body: SwitchIntelligentControlToSave; switchId: number }[]
    >({
      queryFn: async (intelligentControlData) => {
        const data = await Promise.all(
          intelligentControlData.map(({ switchId, body }) =>
            patch<SwitchIntelligentControl>(`/fleet/switches/${switchId}/hot_water_control`, body)
          )
        );

        return {
          data,
        };
      },
    }),
    createSwitchIntelligentControls: builder.mutation<
      SwitchIntelligentControl[],
      { body: SwitchIntelligentControlToSave; switchId: number }[]
    >({
      queryFn: async (intelligentControlData) => {
        const data = await Promise.all(
          intelligentControlData.map(({ switchId, body }) =>
            post<SwitchIntelligentControl>(`/fleet/switches/${switchId}/hot_water_control`, body)
          )
        );

        return {
          data,
        };
      },
    }),
    getSwitchStateProfiles: builder.query<SwitchStateProfile[], number[]>({
      queryFn: async (switchIds) => {
        const data = await Promise.all(
          switchIds.map((switchId) => get<SwitchStateProfile>(`/fleet/switches/${switchId}/state_profile`))
        );

        return {
          data,
        };
      },
      providesTags: (result, error, arg) => [
        ...arg.map((switchId) => ({
          id: switchId,
          type: 'SwitchStateProfile' as const,
        })),
      ],
    }),
    updateSwitchStateProfiles: builder.mutation<SwitchStateProfile[], { body: SwitchStateProfile; switchId: number }[]>(
      {
        queryFn: async (stateProfiles) => {
          const data = await Promise.all(
            stateProfiles.map(({ switchId, body }) =>
              patch<SwitchStateProfile>(`/fleet/switches/${switchId}/state_profile`, body)
            )
          );

          return {
            data,
          };
        },
        invalidatesTags: (result, error, arg) => [
          ...arg.map(({ switchId }) => ({
            type: 'SwitchStateProfile' as const,
            id: switchId,
          })),
        ],
      }
    ),
    getSwitchSchedules: builder.query<SwitchSchedule[], number>({
      query: (switchId) => `/v1/switches/${switchId}/schedules`,
      providesTags: ['SwitchSchedules'],
    }),
    createSwitchSchedules: builder.mutation<
      SwitchSchedule[],
      { switchId: number; schedules: SwitchScheduleToCreate[] }
    >({
      queryFn: async ({ switchId, schedules }) => {
        const data = await Promise.all(
          schedules.map((schedule) => post<SwitchSchedule>(`/v1/switches/${switchId}/schedules`, schedule))
        );

        return {
          data,
        };
      },
      invalidatesTags: ['SwitchSchedules'],
    }),
    updateSwitchSchedules: builder.mutation<
      SwitchSchedule[],
      { scheduleId: number; schedule: Partial<SwitchSchedule> }[]
    >({
      queryFn: async (schedules) => {
        const data = await Promise.all(
          schedules.map(({ scheduleId, schedule }) => patch<SwitchSchedule>(`/v1/schedules/${scheduleId}`, schedule))
        );

        return {
          data,
        };
      },
    }),
    deleteSwitchSchedules: builder.mutation<null, number[]>({
      queryFn: async (scheduleIds) => {
        await Promise.all(scheduleIds.map((scheduleId) => del(`/v1/schedules/${scheduleId}`, {})));

        return {
          data: null,
        };
      },
      invalidatesTags: ['SwitchSchedules'],
    }),
  }),
});

export const {
  useGetSwitchesQuery,
  usePatchSwitchesMutation,
  useGetSwitchStateProfilesQuery,
  useGetSwitchSchedulesQuery,
  useCreateSwitchSchedulesMutation,
  useUpdateSwitchSchedulesMutation,
  useDeleteSwitchSchedulesMutation,
  useUpdateSwitchIntelligentControlsMutation,
  useCreateSwitchIntelligentControlsMutation,
  useUpdateSwitchStateProfilesMutation,
} = switchApi;

/**
 * A bit hacky and annoying, but the back-end has a separate endpoint for fetching metadata for each specific
 * switch in the system, to fetch its fallback state.
 */
export const useSwitches = (skip?: boolean) => {
  const { clipsal_solar_id } = useSelector(selectSite);
  const {
    data: switches,
    isLoading: isSwitchesQueryLoading,
    isError: isSwitchesError,
  } = useGetSwitchesQuery(clipsal_solar_id, { skip });
  const {
    data: switchStateProfiles,
    isLoading: isSwitchStateProfilesLoading,
    isUninitialized: isSwitchStateProfilesUninitialized,
    isError: isSwitchStateProfilesError,
  } = useGetSwitchStateProfilesQuery(switches?.map((s) => s.id) ?? [], { skip: skip || isSwitchesQueryLoading });

  return {
    switches: switches ?? [],
    switchStateProfiles: switchStateProfiles ?? [],
    isLoading: isSwitchesQueryLoading || isSwitchStateProfilesLoading || isSwitchStateProfilesUninitialized,
    isSwitchesError,
    isSwitchStateProfilesError,
  };
};

export function useGetSwitchSchedules(switchId: number) {
  const { data: schedules, ...rest } = useGetSwitchSchedulesQuery(switchId);
  return { schedules: schedules ?? [], ...rest };
}
