import React, { useEffect, useMemo, useRef } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CommonFieldListProps, ScheduleFormData, SwitchFormData } from '../switch-config-form-types';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { SchedulingType } from 'clipsal-cortex-types/src/api/api-ww-switch';
import { useGetSwitchSchedules } from '../switchApi';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { WEEKDAYS } from '../../../../../common/constants';
import EditDayIntervalModal from './EditDayIntervalModal';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import EditTimeModal from './EditTimeModal';
import useSwitchAppliance from '../useSwitchAppliance';
import { mapAPISchedulesToUI } from '../form-mapping-helpers';

type CommonScheduleConfigProps = CommonFieldListProps & { meterIndex: number; switchIndex: number };

const HOT_WATER_HEAT_PUMP_APPLIANCE_TYPE = 'load_hot_water_heat_pump';

export default function SwitchScheduleConfiguration(props: CommonScheduleConfigProps) {
  const { meterIndex, switchIndex, control } = props;
  const switchesInForm = useWatch({ name: `meters.${meterIndex}.switches`, control });
  const { schedulingType } = switchesInForm[switchIndex];
  const appliances = useSwitchAppliance();
  const formSwitch = switchesInForm[switchIndex];

  const isSmartSchedulingAvailable = useMemo(() => {
    if (formSwitch?.circuitId) {
      const circuitId = formSwitch.circuitId;
      return appliances.find((appliance) => {
        // prevent the installer from being able to select solar sponge
        // as a control option for hot water heap pumps
        if (appliance.appliance_type === HOT_WATER_HEAT_PUMP_APPLIANCE_TYPE) return false;

        return appliance.circuits.includes(circuitId);
      });
    }

    return false;
  }, [appliances, formSwitch]);

  const schedulingConfigToComponent: Record<SchedulingType, React.JSX.Element> = {
    SUPPRESSED: <></>,
    STANDARD: <></>,
    AUTO: <SmartScheduleConfigForm {...props} />,
    TIMED: <ManualScheduleConfigForm {...props} formSwitch={formSwitch} />,
  };

  return (
    <Box mt={5}>
      <FormControl my={3}>
        <FormLabel>Scheduling Type</FormLabel>
        <Controller
          control={control}
          name={`meters.${meterIndex}.switches.${switchIndex}.schedulingType`}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <RadioGroup
                data-testid={`meters-${meterIndex}-switches-${switchIndex}-scheduling-type`}
                onChange={onChange}
                ref={ref}
                value={value}
              >
                <Flex wrap={'wrap'}>
                  <Radio colorScheme="primaryBranding" mr={4} value="STANDARD">
                    Manual Control
                  </Radio>
                  <Radio colorScheme="primaryBranding" mr={4} value="TIMED">
                    Time Schedule
                  </Radio>
                  {isSmartSchedulingAvailable && (
                    <Radio colorScheme="primaryBranding" value="AUTO">
                      Solar Sponge
                    </Radio>
                  )}
                </Flex>
              </RadioGroup>
            );
          }}
        />
      </FormControl>
      <Box mt={3}>{schedulingConfigToComponent[schedulingType]}</Box>
    </Box>
  );
}

function SmartScheduleConfigForm({ control, errors, register, meterIndex, switchIndex }: CommonScheduleConfigProps) {
  const switchesInForm = useWatch({ name: `meters.${meterIndex}.switches`, control });

  return (
    <Box data-testid={`meters-${meterIndex}-switches-${switchIndex}-smart-schedule-container`}>
      <Heading size="md">Smart Schedule Configuration</Heading>
      <Text mt={2}>This allows homeowners to maximize their self-consumption.</Text>
      <Text>
        If the meter sees {switchesInForm[switchIndex].schedulingConfig?.AUTO?.exportThreshold ?? 'X'} kW of solar
        export for 15 minutes, the contactor will be turned on.
      </Text>

      <Flex justify="space-between" align="center">
        <Text fontWeight="bolder">Solar Export Threshold</Text>
        <FormControl
          maxW="100px"
          isInvalid={!!errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.schedulingConfig?.AUTO?.exportThreshold}
        >
          <InputGroup>
            <Input
              data-testid={`meters-${meterIndex}-switches-${switchIndex}-export-threshold`}
              {...register(
                `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.AUTO.exportThreshold` as const
              )}
              type="text"
              placeholder={'X'}
            />
            <InputRightElement>kW</InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.schedulingConfig?.AUTO?.exportThreshold?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex mt={3} justify="space-between" align="center">
        <Text fontWeight="bolder">Run Time</Text>
        <Flex align="center">
          <FormControl
            maxW="75px"
            isInvalid={!!errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.schedulingConfig?.AUTO?.runTimeHours}
          >
            <Input
              data-testid={`meters-${meterIndex}-switches-${switchIndex}-run-time-hours`}
              {...register(`meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.AUTO.runTimeHours` as const)}
              type="number"
            />

            <FormErrorMessage>
              {errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.schedulingConfig?.AUTO?.runTimeHours?.message}
            </FormErrorMessage>
          </FormControl>
          <Text ml={1}>hours</Text>
          <FormControl
            ml={2}
            maxW="75px"
            isInvalid={
              !!errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.schedulingConfig?.AUTO?.runTimeMinutes?.message
            }
          >
            <Input
              data-testid={`meters-${meterIndex}-switches-${switchIndex}-run-time-minutes`}
              {...register(
                `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.AUTO.runTimeMinutes` as const
              )}
              type="number"
            />
            <FormErrorMessage>
              {errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.schedulingConfig?.AUTO?.runTimeMinutes?.message}
            </FormErrorMessage>
          </FormControl>
          <Text ml={1}>mins</Text>
        </Flex>
      </Flex>
    </Box>
  );
}

const DEFAULT_MANUAL_SCHEDULE: ScheduleFormData = {
  startScheduleId: null,
  endScheduleId: null,
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
  startTime: '10:00',
  endTime: '15:00',
};

function ManualScheduleConfigForm({
  control,
  meterIndex,
  switchIndex,
  formSwitch,
  errors,
  getValues,
  clearErrors,
  setValue,
}: CommonScheduleConfigProps & { formSwitch: SwitchFormData }) {
  const { schedules, isLoading, isError } = useGetSwitchSchedules(formSwitch.id);
  const { fields, append, remove, update } = useFieldArray({
    name: `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.MANUAL.schedules` as const,
    control,
  });
  const {
    isOpen: isEditDayIntervalModalOpen,
    onClose: onCloseEditDayIntervalModal,
    onOpen: onOpenEditDayIntervalModal,
  } = useDisclosure();
  const { isOpen: isEditTimeModalOpen, onOpen: onOpenEditTimeModal, onClose: onCloseEditTimeModal } = useDisclosure();
  // Used for editing day interval and times on specific schedules
  const currentlyEditingScheduleIndex = useRef<number | null>(null);
  // Used for editing day interval and times on specific schedules
  const timeEditType = useRef<'start' | 'end' | null>(null);
  const scheduleErrorsForSwitch =
    errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.schedulingConfig?.MANUAL?.schedules;

  useEffect(() => {
    if (!isLoading) {
      if (schedules.length) {
        setValue(
          `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.MANUAL.schedules`,
          mapAPISchedulesToUI(schedules)
        );
      } else if (!fields.length) {
        // No schedules from the API, or in the local form, so populate a default one
        append(DEFAULT_MANUAL_SCHEDULE);
      }
    }
  }, [schedules]);

  async function handleEditScheduleDayInterval(fieldIndex: number) {
    currentlyEditingScheduleIndex.current = fieldIndex;
    onOpenEditDayIntervalModal();
  }

  function getWeeklyIntervalFormatted(schedule: ScheduleFormData) {
    if (schedule.daysOfWeek.length === 0) return 'Never';
    if (schedule.daysOfWeek.length === 7) return 'Everyday';
    if (schedule.daysOfWeek.length === 1) return WEEKDAYS[schedule.daysOfWeek[0]];

    if (
      schedule.daysOfWeek.length === 5 &&
      [1, 2, 3, 4, 5].every((dayIndex) => schedule.daysOfWeek.includes(dayIndex))
    ) {
      return 'Weekdays';
    }

    if (schedule.daysOfWeek.length === 2 && [0, 6].every((dayIndex) => schedule.daysOfWeek.includes(dayIndex))) {
      return 'Weekends';
    }

    return schedule.daysOfWeek.map((dayIndex) => WEEKDAYS[dayIndex].slice(0, 3)).join(', ');
  }

  async function handleEditScheduleTime(newSelectedScheduleIndex: number, type: 'start' | 'end') {
    currentlyEditingScheduleIndex.current = newSelectedScheduleIndex;
    timeEditType.current = type;
    onOpenEditTimeModal();
  }

  function getTimeFormatted(time: string) {
    const [hours, minutes] = time.split(':').map((time) => Number(time));
    const isPM = hours >= 12;
    let displayHours = isPM && hours !== 12 ? hours - 12 : hours;
    if (hours === 0 && !isPM) displayHours = 12;

    return `${displayHours}:${minutes < 10 ? '0' + minutes : minutes} ${isPM ? 'PM' : 'AM'}`;
  }

  return isError ? (
    <Alert status="error" variant="left-accent">
      <AlertIcon />
      There was an error loading switch schedules. Please contact support.
    </Alert>
  ) : (
    <>
      <Heading size="md">Manual Schedule Configuration</Heading>
      <Text mt={2}>Set your own schedule for when this device should be switched on and off.</Text>
      <Text mb={5}>The customer can update this in the Cortex app.</Text>

      {isLoading ? (
        <CenteredLoader />
      ) : (
        fields.map((schedule, index) => {
          return (
            <Box
              px={5}
              borderLeft={scheduleErrorsForSwitch?.[index] ? '5px solid red' : undefined}
              pl={scheduleErrorsForSwitch?.[index] ? 2 : undefined}
              mb={index !== fields.length - 1 ? 8 : 0}
              key={schedule.id}
              data-testid={`meters-${meterIndex}-switches-${switchIndex}-schedule-${index}`}
              data-is-saved={schedule.endScheduleId && schedule.startScheduleId ? true : undefined}
            >
              <Flex py={fields.length > 1 ? 0 : 1.5} mb={1} align={'center'} justify={'space-between'}>
                <Heading size={'sm'} fontSize={'md'}>
                  Timer {index + 1}
                </Heading>

                {fields.length > 1 && (
                  <Button
                    onClick={() => {
                      if (schedule.startScheduleId && schedule.endScheduleId) {
                        const existingSchedules = getValues(
                          `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.MANUAL.deletedScheduleIds`
                        );
                        setValue(
                          `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.MANUAL.deletedScheduleIds`,
                          [...existingSchedules, schedule.startScheduleId, schedule.endScheduleId]
                        );
                      }
                      // Errors are cleared on change, and re-validated on re-submit.
                      clearErrors(
                        // eslint-disable-next-line max-len
                        `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.MANUAL.schedules.${index}`
                      );

                      remove(index);
                    }}
                    variant={'ghost'}
                    size={'sm'}
                    color={'customRed.500'}
                    data-testid={`meters-${meterIndex}-switches-${switchIndex}-schedule-${index}-delete-schedule-btn`}
                  >
                    Delete
                  </Button>
                )}
              </Flex>

              <Flex
                as={'button'}
                data-testid={`meters-${meterIndex}-switches-${switchIndex}-schedule-${index}-edit-day-interval-btn`}
                w={'100%'}
                justify="space-between"
                py={3}
                borderTop={'1px solid'}
                borderBottom={'1px solid'}
                borderColor={'#C5C5C5'}
                onClick={() => handleEditScheduleDayInterval(index)}
              >
                <Text>Repeat</Text>
                <Flex align="center">
                  <Text data-testid={`meters-${meterIndex}-switches-${switchIndex}-schedule-${index}-days-of-week`}>
                    {getWeeklyIntervalFormatted(schedule)}
                  </Text>
                  <ChevronRightIcon w={6} h={6} />
                </Flex>
              </Flex>

              <Flex
                as={'button'}
                w={'100%'}
                data-testid={`meters-${meterIndex}-switches-${switchIndex}-schedule-${index}-start-time`}
                justify="space-between"
                py={3}
                borderBottom={'1px solid'}
                borderColor={'#C5C5C5'}
                onClick={() => handleEditScheduleTime(index, 'start')}
              >
                <Text>Start</Text>
                <Text>{getTimeFormatted(schedule.startTime)}</Text>
              </Flex>

              <Flex
                as={'button'}
                w={'100%'}
                data-testid={`meters-${meterIndex}-switches-${switchIndex}-schedule-${index}-end-time`}
                justify="space-between"
                py={3}
                borderBottom={'1px solid'}
                borderColor={'#C5C5C5'}
                onClick={() => handleEditScheduleTime(index, 'end')}
              >
                <Text>End</Text>
                <Text>{getTimeFormatted(schedule.endTime)}</Text>
              </Flex>

              {scheduleErrorsForSwitch?.[index] && (
                <Text
                  data-testid={`meters-${meterIndex}-switches-${switchIndex}-schedule-${index}-validation-error`}
                  color={'red'}
                >
                  {scheduleErrorsForSwitch?.[index]?.message}
                </Text>
              )}
            </Box>
          );
        })
      )}

      <Button
        data-testid={`meters-${meterIndex}-switches-${switchIndex}-add-schedule-btn`}
        mt={4}
        onClick={() => append(DEFAULT_MANUAL_SCHEDULE)}
        variant={'ghost'}
        color={'customBlue.500'}
      >
        + Add Timer
      </Button>

      {isEditDayIntervalModalOpen && (
        <EditDayIntervalModal
          initialValue={fields[currentlyEditingScheduleIndex.current!].daysOfWeek}
          isOpen={isEditDayIntervalModalOpen}
          onClose={() => {
            currentlyEditingScheduleIndex.current = null;
            onCloseEditDayIntervalModal();
          }}
          onAccept={(daysOfWeek) => {
            update(currentlyEditingScheduleIndex.current!, {
              ...fields[currentlyEditingScheduleIndex.current!],
              daysOfWeek,
            });

            // Errors are cleared on change, and re-validated on re-submit.
            clearErrors(
              // eslint-disable-next-line max-len
              `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.MANUAL.schedules.${currentlyEditingScheduleIndex.current!}`
            );

            currentlyEditingScheduleIndex.current = null;
            onCloseEditDayIntervalModal();
          }}
        />
      )}

      {isEditTimeModalOpen && (
        <EditTimeModal
          initialValue={fields[currentlyEditingScheduleIndex.current!][`${timeEditType.current!}Time`]}
          isOpen={isEditTimeModalOpen}
          onClose={() => {
            currentlyEditingScheduleIndex.current = null;
            onCloseEditTimeModal();
          }}
          onAccept={(newTime) => {
            update(currentlyEditingScheduleIndex.current!, {
              ...fields[currentlyEditingScheduleIndex.current!],
              [`${timeEditType.current!}Time`]: newTime,
            });

            // Errors are cleared on change, and re-validated on re-submit.
            clearErrors(
              // eslint-disable-next-line max-len
              `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.MANUAL.schedules.${currentlyEditingScheduleIndex.current!}`
            );

            currentlyEditingScheduleIndex.current = null;
            timeEditType.current = null;
            onCloseEditTimeModal();
          }}
        />
      )}
    </>
  );
}
