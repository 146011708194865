import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { MeterData } from './meter-setup-form-types';

export function mapMeterToForm(meterData: WattwatchersMeter, meterId: number | null = null): MeterData {
  return {
    meterId,
    isLoaded: true,
    serialNumber: meterData.ww_device_id,
    label: meterData.label,
    model: meterData.model,
  };
}

export function mapMetersToForm(meters: Record<string, WattwatchersMeter>) {
  const metersArray = Object.values(meters);
  return metersArray.map((meterData: WattwatchersMeter, idx: number) => {
    const meterId = Number(metersArray[idx].installed_device_id);
    return mapMeterToForm(meterData, meterId);
  });
}
