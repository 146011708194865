import { FleetDataV1 } from '../../api/api-fleet-data';
import { get } from '../../api/api-helpers';
import { useState } from 'react';

type Aggregation = 'DAY' | 'MONTH' | 'YEAR';

// @TODO: refactor with RTK query for caching and simplicity
export default function useFleetData() {
  const [fleetData, setFleetData] = useState<FleetDataV1>({
    consumed: {
      name: 'consumed',
      data: [],
      total: 0,
    },
    produced: {
      name: 'produced',
      data: [],
      total: 0,
    },
  });

  const fetchAndSetFleetEnergy = async (tenantId: number, start: string, end: string, aggregation: Aggregation) => {
    const fleetData = await get<FleetDataV1>(
      `/fleet/tenants/${tenantId}/fleet_energy?start_date=${start}` + `&end_date=${end}&aggregation=${aggregation}`
    );
    setFleetData({
      consumed: {
        ...fleetData.consumed,
        data: fleetData.consumed.data.map((d) => {
          return {
            ...d,
            x: d.x * 1000,
          };
        }),
      },
      produced: {
        ...fleetData.produced,
        data: fleetData.produced.data.map((d) => {
          return {
            ...d,
            x: d.x * 1000,
          };
        }),
      },
    });
  };

  const fetchAndSetFleetPower = async (tenantId: number, start: string, end: string) => {
    const fleetData = await get<FleetDataV1>(
      `/fleet/tenants/${tenantId}/fleet_power?from_datetime=${start}&to_datetime=${end}`
    );
    setFleetData({
      consumed: {
        ...fleetData.consumed,
        data: fleetData.consumed.data.map((d) => {
          return {
            ...d,
            x: d.x * 1000,
          };
        }),
      },
      produced: {
        ...fleetData.produced,
        data: fleetData.produced.data.map((d) => {
          return {
            ...d,
            x: d.x * 1000,
          };
        }),
      },
    });
  };

  return { fleetData, fetchAndSetFleetEnergy, fetchAndSetFleetPower };
}
