import {
  Box,
  ComponentWithAs,
  Flex,
  IconProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectAppliances, selectRawMeters } from '../meterSetupSlice';
import { useSelector } from 'react-redux';
import { mapMetersToCTConfigurationForm } from './form-mapping-helpers';
import { selectSite } from '../../siteSlice';
import { WarningIcon } from '@chakra-ui/icons';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { useAppDispatch } from '../../../../app/hooks';
import { ctConfigFormSchema } from './ct-config-validation-schema';
import { CommonFieldListProps, CTConfigFormData } from './ct-configuration-form-types';
import { MeterSetupPollingSubRouteProps } from '../MeterSetupPollingWrapper';
import useUpdateCTFunctions from './use-update-ct-functions';
import { COMPONENT_MIN_HEIGHT, SIDENAV_WIDTH } from '../../../../common/constants';
import { selectBatteries, selectEVChargers, selectInverters } from '../../system-details/systemDetailsSlice';
import {
  constructTabLabel,
  filterTabsBasedOnDevices,
  getMinTabButtonWidth,
  TAB_CONFIGURATION,
} from './ct-configuration-helpers';
import SubRouteTopNav from '../SubRouteTopNav';

export type TabSource = 'grid' | 'solar' | 'battery' | 'hybrid' | 'load' | 'evCharger';

export type TabComponentProps = CommonFieldListProps & MeterSetupPollingSubRouteProps;

export interface TabConfig {
  label: string;
  key: TabSource;
  component: React.ComponentType<TabComponentProps>;
  icon: ComponentWithAs<'svg', IconProps>;
}

const DEFAULT_FORM_VALUES = {
  grid: [],
  solar: [],
  load: [],
  battery: [],
  evCharger: [],
  hybrid: { inverter: [], backup: [] },
  hasAcCoupledInverter: false,
  hasDcCoupledInverter: false,
  hasBattery: false,
  hasSecondACOutput: false,
};

const INITIAL_STATE = {
  tabIndex: 0,
  clickedTabIndex: 0,
  isFormReady: false,
  isUpdatingCTs: false,
  openThreePhaseWarningDrawer: false,
  tabs: [] as TabConfig[],
};

export default function CTConfigurationContainer() {
  const { onCircuitChange, circuitState } = useOutletContext<MeterSetupPollingSubRouteProps>();
  const rawMeters = useSelector(selectRawMeters);
  const {
    register,
    control,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<CTConfigFormData>({
    // Add placeholder default values here then inject actual default values in useEffect below.
    // The exact rationale for this is slightly unclear to me.
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(ctConfigFormSchema),
  });
  const [state, setState] = useState(INITIAL_STATE);
  const site = useSelector(selectSite);
  const inverters = useSelector(selectInverters);
  const batteries = useSelector(selectBatteries);
  const evChargers = useSelector(selectEVChargers);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const appliances = useSelector(selectAppliances);

  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  const { textColor, background } = useColorModeValue(
    { textColor: 'black', background: 'white' },
    { textColor: 'white', background: 'gray.900' }
  );

  const handleTabsChange = useCallback((i: number, gotoClickedTabIndex = false) => {
    setState((prevState) => {
      const isBothIndexSame = prevState.tabIndex === prevState.clickedTabIndex;
      const tabIndex = gotoClickedTabIndex && !isBothIndexSame ? prevState.clickedTabIndex : i;
      return { ...prevState, tabIndex };
    });
  }, []);

  const handleIsUpdatingCTs = useCallback((value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isUpdatingCTs: value,
    }));
  }, []);

  const setOpenThreePhaseWarningDrawer = useCallback((value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      openThreePhaseWarningDrawer: value,
    }));
  }, []);

  const updateCTFunctions = useUpdateCTFunctions(
    getValues,
    trigger,
    reset,
    handleTabsChange,
    handleIsUpdatingCTs,
    setOpenThreePhaseWarningDrawer,
    state.tabs
  );

  useEffect(() => {
    if (!state.isFormReady) {
      const formValues = mapMetersToCTConfigurationForm(
        Object.values(rawMeters),
        inverters,
        appliances,
        batteries,
        evChargers
      );
      reset(formValues);
      setState((prevState) => ({
        ...prevState,
        isFormReady: true,
        tabs: filterTabsBasedOnDevices(formValues, inverters, batteries),
      }));
    }
  }, [
    site.clipsal_solar_id,
    rawMeters,
    reset,
    state.isFormReady,
    dispatch,
    appliances,
    batteries,
    inverters,
    evChargers,
  ]);

  if (!state.isFormReady) return <CenteredLoader h="50vh" />;

  return (
    <Box w="100%" minHeight={COMPONENT_MIN_HEIGHT}>
      <SubRouteTopNav
        onGoBack={() => {
          if (isDirty) {
            if (
              window.confirm(
                `Are you sure you want to leave the page without saving the CT configuration? Any changes will be discarded.`
              )
            ) {
              navigate(-1);
            }
          } else {
            navigate(-1);
          }
        }}
        title="Channel Configuration"
      />

      <Tabs
        index={state.tabIndex}
        isManual
        onChange={async (idx) => {
          setState((prevState) => ({ ...prevState, clickedTabIndex: idx }));

          const tabSource = TAB_CONFIGURATION[state.tabIndex].key;
          const isTabDirty = !!dirtyFields[tabSource];

          // we won't run load tab function on tab changes as it is meant to be
          // the last step and runs only when pressed next button
          if (isTabDirty && tabSource !== 'load') {
            await updateCTFunctions[tabSource](idx);
          } else {
            handleTabsChange(idx);
          }
        }}
        isFitted
        isLazy
      >
        <TabPanels pb={24} px={[4, 6]}>
          {state.tabs.map((tab, index) => {
            const Component = tab.component;
            const { isUpdatingCTs, openThreePhaseWarningDrawer } = state;
            return (
              <TabPanel key={index} px={[0, 2]}>
                <Component
                  {...{
                    reset,
                    trigger,
                    control,
                    setError,
                    clearErrors,
                    register,
                    errors,
                    setValue,
                    getValues,
                    onChangeTab: handleTabsChange,
                    onCircuitChange,
                    circuitState,
                    updateCTFunctions,
                    isUpdatingCTs,
                    openThreePhaseWarningDrawer,
                    setOpenThreePhaseWarningDrawer,
                  }}
                />
              </TabPanel>
            );
          })}
        </TabPanels>
        <Flex
          position={'fixed'}
          bottom={0}
          left={0}
          zIndex={99}
          width="100%"
          justifyContent={'center'}
          paddingLeft={isMobileViewport ? 0 : SIDENAV_WIDTH}
          paddingRight={isMobileViewport ? 0 : 15}
        >
          <TabList
            width={isMobileViewport ? '100%' : '60%'}
            background={background}
            borderBottomWidth="0px"
            minHeight={isMobileViewport ? 12 : 14}
          >
            {state.tabs.map((tab, index) => (
              <Tab
                data-testid={`${tab.label}-tab-button`}
                key={index + tab.label}
                outline="0px transparent"
                borderColor="slate.500"
                borderTopWidth="3px"
                borderBottomWidth="0px"
                _selected={{
                  color: textColor,
                  fontWeight: 'bold',
                  borderColor: 'customTabBlue.500',
                }}
                isDisabled={state.isUpdatingCTs}
                minW={getMinTabButtonWidth(state.tabs, getValues().hasDcCoupledInverter)}
                pt={4}
                pb={[6, 4]}
              >
                <Flex align="center">
                  <>
                    {state.tabs.length > 4 && isMobileViewport ? (
                      <tab.icon w={6} h={6} />
                    ) : (
                      <Text>{constructTabLabel(state.tabs, tab.label, isMobileViewport)}</Text>
                    )}
                    {errors[tab.key] && <WarningIcon ml={2} color={'red.500'} w={5} h={5} />}
                  </>
                </Flex>
              </Tab>
            ))}
          </TabList>
        </Flex>
      </Tabs>
    </Box>
  );
}
