import * as yup from 'yup';
import 'yup-phone';

const SUPPORTED_PHONE_NUMBER_REGIONS = [
  { region: 'AU', countryCode: '61' },
  { region: 'NZ', countryCode: '64' },
];

export const getPhoneValidationSchema = (
  invalidMessage = '*Invalid Phone Number',
  requiredMessage = 'Phone number is required'
) => {
  let schema = yup.string();

  // iterate and add validation for each region
  SUPPORTED_PHONE_NUMBER_REGIONS.forEach(({ region, countryCode }) => {
    const regionCheckSchema = yup
      .string()
      .trim()
      .required(requiredMessage)
      .test(`is-${region}-phoneNumber`, invalidMessage, (val) => {
        const isBasedOnRegion = val?.includes(`+${countryCode}`);
        return isBasedOnRegion ? yup.string().trim().phone(region, true, invalidMessage).isValidSync(val) : true;
      });

    // concat does not mutate original schema
    // hence we need to reassign it
    schema = schema.concat(regionCheckSchema);
  });

  return schema;
};
