import { FieldArrayWithId, useFieldArray, useWatch } from 'react-hook-form';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { CommonFieldListProps, SystemDetailsFormData } from './system-details-form-types';
import Counter from '../../../common/components/Counter';
import {
  CompassIcon,
  TiltFlatColoredIcon,
  TiltFlatGrayIcon,
  TiltPitchedColoredIcon,
  TiltPitchedGrayIcon,
} from '../../../styles/custom-icons';
import { del } from '../../../api/api-helpers';
import { InverterOrientation } from '../../../api/api-orientation';
import { CARDINALITIES, CARDINAL_TO_ORIENTATION_MAP, EMPTY_ORIENTATION_TEMPLATE } from './system-details-helpers';

type NestedOrientationFieldArrayProps = {
  nestIndex: number;
} & CommonFieldListProps;

export default function NestedOrientationFieldArray({
  nestIndex,
  register,
  control,
  errors,
  setValue,
  getValues,
}: NestedOrientationFieldArrayProps) {
  const { fields, append, remove } = useFieldArray({
    name: `inverters.${nestIndex}.orientations` as `inverters.${number}.orientations`,
    control,
  });
  const borderColor = useColorModeValue('#0000001A', 'dusk100.400');

  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  async function handleDeleteOrientation(orientationIndex: number) {
    if (window.confirm('Are you sure you want to delete this orientation?')) {
      const orientationId = fields[orientationIndex].orientationId;
      if (orientationId) await del<InverterOrientation>(`/fleet/orientations/${orientationId}`, {});
      remove(orientationIndex);
    }
  }

  useEffect(() => {
    setExpandedIndex(fields.length - 1);
  }, [fields.length]);

  return (
    <Box borderTop="1px solid" borderColor={borderColor} mt={4}>
      <Flex align="center" justify="space-between" px={[1, 2]} pb={4} mt={4}>
        <Text pr={8}>How many orientations does inverter {nestIndex + 1} have?</Text>
        <Counter
          value={fields.length}
          incrementAriaLabel="Add Orientation"
          decrementAriaLabel="Remove Orientation"
          onIncrement={() => {
            const totalOrientations = fields.length;

            if (totalOrientations > 0) {
              // get defaultPanelPower from previous orientation
              const defaultPanelPower = fields[totalOrientations - 1]?.panelPower || 250;
              append({ ...EMPTY_ORIENTATION_TEMPLATE, panelPower: defaultPanelPower });
            } else {
              append(EMPTY_ORIENTATION_TEMPLATE);
            }
          }}
          onDecrement={() => handleDeleteOrientation(fields.length - 1)}
          isDecrementDisabled={fields.length === 0}
          dataTestId={`inverters-${nestIndex}-add-remove-orientations-counter`}
        />
      </Flex>
      <Accordion allowToggle index={expandedIndex} onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}>
        {fields.map(
          (field: FieldArrayWithId<SystemDetailsFormData, `inverters.${number}.orientations`, any>, index) => {
            return (
              <AccordionItem data-testid="orientations-form" key={`${field.id}-${index}-${nestIndex}`}>
                <AccordionButton
                  data-testid={`inverters-${nestIndex}-orientations-${index}-expandBtn`}
                  role={'button'}
                  as={Box}
                  px={0}
                >
                  <Flex w={'100%'} justify={'space-between'} align={'center'}>
                    <Heading size={'md'}>Orientation {index + 1}</Heading>
                    <Flex align={'center'}>
                      {/* Display an error notification post-submission on this accordion button. */}
                      {!!errors?.inverters?.[nestIndex]?.orientations?.[index] && (
                        <WarningIcon mr={2} color={'red.500'} w={5} h={5} />
                      )}
                      <Button
                        data-testid={`orientations-${index}-removeBtn`}
                        variant={'ghost'}
                        onClick={() => handleDeleteOrientation(index)}
                        size={'xs'}
                        ml={2}
                        colorScheme="red"
                        aria-label="Delete orientation"
                      >
                        Remove
                      </Button>
                      <AccordionIcon />
                    </Flex>
                  </Flex>
                </AccordionButton>

                <AccordionPanel pb={4} px={0} ml={1}>
                  <NestedOrientationFields
                    {...{ nestIndex, errors, setValue, control, register, orientationIndex: index, field, getValues }}
                  />
                </AccordionPanel>
              </AccordionItem>
            );
          }
        )}
      </Accordion>
    </Box>
  );
}

type NestedOrientationFieldsProps = {
  nestIndex: number;
  orientationIndex: number;
  errors: CommonFieldListProps['errors'];
  setValue: CommonFieldListProps['setValue'];
  getValues: CommonFieldListProps['getValues'];
  control: CommonFieldListProps['control'];
  register: CommonFieldListProps['register'];
  field: FieldArrayWithId<SystemDetailsFormData, `inverters.${number}.orientations`, any>;
};

const COMMON_ICON_BUTTON_PROPS = {
  outline: '0px transparent',
  rounded: 8,
  p: 6,
  w: 8,
  h: 8,
};
const COMMON_ICON_PROPS = {
  h: 6,
  w: 6,
};
const UNSELECTED_TILT_TYPE = {
  border: '2px solid',
  borderColor: 'transparent',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
};
const SELECTED_TILT_TYPE = { border: '2px solid', borderColor: 'customPaleBlue.500' };

const NestedOrientationFields = ({
  errors,
  nestIndex,
  orientationIndex,
  setValue,
  control,
  register,
  field,
  getValues,
}: NestedOrientationFieldsProps) => {
  const { iconButtonBackground, textColor, buttonHoverColor } = useColorModeValue(
    { iconButtonBackground: 'white', textColor: 'dusk100.500', buttonHoverColor: 'gray.100' },
    { iconButtonBackground: 'customBodyBackground.800', textColor: 'dusk100.50', buttonHoverColor: 'gray.600' }
  );
  const tilt = useWatch({ control, name: `inverters.${nestIndex}.orientations.${orientationIndex}.tilt` });
  const panelPower =
    useWatch({ control, name: `inverters.${nestIndex}.orientations.${orientationIndex}.panelPower` }) || 0;
  const numOfModules =
    useWatch({
      control,
      name: `inverters.${nestIndex}.orientations.${orientationIndex}.numOfModules`,
    }) || 0;
  const cardinality = useWatch({
    control,
    name: `inverters.${nestIndex}.orientations.${orientationIndex}.cardinality`,
  });
  const isFlatTilted = tilt === 'flat';
  const flatTiltStyle = isFlatTilted ? SELECTED_TILT_TYPE : UNSELECTED_TILT_TYPE;
  const pitchedTiltStyle = !isFlatTilted ? SELECTED_TILT_TYPE : UNSELECTED_TILT_TYPE;
  const FlatTiltIcon = isFlatTilted ? TiltFlatColoredIcon : TiltFlatGrayIcon;
  const PitchedTiltIcon = !isFlatTilted ? TiltPitchedColoredIcon : TiltPitchedGrayIcon;

  useEffect(() => {
    const totalPanels = getValues().inverters[nestIndex].orientations.reduce(
      (total, orientation) => (total += orientation.numOfModules || 0),
      0
    );
    setValue(`inverters.${nestIndex}.totalPanels`, totalPanels);
  }, [numOfModules, getValues, setValue, nestIndex]);

  return (
    <>
      <Flex align="flex-start" flexWrap={'wrap'} columnGap={4}>
        <FormControl
          isInvalid={!!errors?.inverters?.[nestIndex]?.orientations?.[orientationIndex]?.panelPower}
          mt={3}
          id={`${field.id}_panelPower`}
          maxW={250}
        >
          <FormLabel>Panel Power</FormLabel>
          <Flex direction="column">
            <Input
              data-testid={`inverters-${nestIndex}-orientations-${orientationIndex}-panelPower`}
              defaultValue={field.panelPower || 0}
              {...register(`inverters.${nestIndex}.orientations.${orientationIndex}.panelPower` as const, {
                valueAsNumber: true,
              })}
              type="number"
              step="1"
              minWidth={150}
            />
            <Text color={textColor} fontSize="13px">
              Total power: {((panelPower * numOfModules) / 1000).toFixed(2)} kW
            </Text>
          </Flex>
          <FormErrorMessage>
            {errors?.inverters?.[nestIndex]?.orientations?.[orientationIndex]?.panelPower?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!errors?.inverters?.[nestIndex]?.orientations?.[orientationIndex]?.numOfModules}
          mt={3}
          id={`${field.id}_numOfModules`}
          maxW={250}
        >
          <FormLabel>No of Panels</FormLabel>
          <Flex direction="column">
            <Input
              data-testid={`inverters-${nestIndex}-orientations-${orientationIndex}-numOfModules`}
              defaultValue={field.numOfModules || 0}
              {...register(`inverters.${nestIndex}.orientations.${orientationIndex}.numOfModules` as const, {
                valueAsNumber: true,
              })}
              type="number"
              step="1"
              minWidth={150}
            />
            <Text color={textColor} fontSize="13px">
              Total power: {((panelPower * numOfModules) / 1000).toFixed(2)} kW
            </Text>
          </Flex>
          <FormErrorMessage>
            {errors?.inverters?.[nestIndex]?.orientations?.[orientationIndex]?.numOfModules?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!errors?.inverters?.[nestIndex]?.orientations?.[orientationIndex]?.tilt}
          mt={3}
          id={`${nestIndex}-${orientationIndex}-tilt`}
          maxW={200}
        >
          <FormLabel>Select Tilt Type</FormLabel>
          <Flex justify="space-between" maxW={130}>
            <IconButton
              {...{ ...COMMON_ICON_BUTTON_PROPS, ...flatTiltStyle, background: iconButtonBackground }}
              aria-label="Flat Tilt"
              icon={<FlatTiltIcon {...COMMON_ICON_PROPS} />}
              onClick={() => setValue(`inverters.${nestIndex}.orientations.${orientationIndex}.tilt`, 'flat')}
              data-testid={`inverters-${nestIndex}-orientations-${orientationIndex}-tilt-flat`}
            />
            <IconButton
              {...{ ...COMMON_ICON_BUTTON_PROPS, ...pitchedTiltStyle, background: iconButtonBackground }}
              ml={[2, 4]}
              aria-label="Pitched Tilt"
              icon={<PitchedTiltIcon {...COMMON_ICON_PROPS} />}
              onClick={() => setValue(`inverters.${nestIndex}.orientations.${orientationIndex}.tilt`, 'pitched')}
              data-testid={`inverters-${nestIndex}-orientations-${orientationIndex}-tilt-pitched`}
            />
          </Flex>
          <FormErrorMessage>
            {errors?.inverters?.[nestIndex]?.orientations?.[orientationIndex]?.tilt?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      {!isFlatTilted && (
        <FormControl
          isInvalid={!!errors?.inverters?.[nestIndex]?.orientations?.[orientationIndex]?.cardinality}
          mt={3}
          id={`${field.id}cardinality`}
          display="flex"
          flexDirection={'column'}
        >
          <FormLabel>Orientation</FormLabel>
          <Grid templateColumns={'repeat(3, 1fr)'} rowGap={4} mt={4} minWidth={'180px'} mx="auto">
            {CARDINALITIES.map((value, idx) => {
              const isSelected = cardinality === value;
              const rotationDegree = 45 * CARDINAL_TO_ORIENTATION_MAP[cardinality];
              return (
                <Center key={value + idx}>
                  {value === 'Compass' ? (
                    <CompassIcon transform={`rotate(${rotationDegree}deg)`} w={10} h={10} />
                  ) : (
                    <Button
                      background={isSelected ? 'customPaleBlue.500' : iconButtonBackground}
                      color={isSelected ? 'white' : textColor}
                      _hover={{ background: isSelected ? 'customPaleBlue.500' : buttonHoverColor }}
                      outline={'0px transparent'}
                      variant={isSelected ? 'solid' : 'outline'}
                      maxW={10}
                      onClick={() =>
                        setValue(`inverters.${nestIndex}.orientations.${orientationIndex}.cardinality`, value)
                      }
                    >
                      {value}
                    </Button>
                  )}
                </Center>
              );
            })}
          </Grid>
          <FormErrorMessage>
            {errors?.inverters?.[nestIndex]?.orientations?.[orientationIndex]?.cardinality?.message}
          </FormErrorMessage>
        </FormControl>
      )}
    </>
  );
};
