import React, { PropsWithChildren } from 'react';
import { Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';

import { SIDENAV_WIDTH } from '../../common/constants';
import { useSupportDrawerContext } from '../../context/context-exports';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigationState } from '../../context/NavigationProvider';

type DesktopSiteNavProps = {
  onGoBack: () => void;
  title: string;
};

export default function DesktopTopNav({ onGoBack, title, children }: PropsWithChildren<DesktopSiteNavProps>) {
  const { setNavigationState } = useNavigationState();
  const bgColor = useColorModeValue('customBodyBackground.500', 'customBodyBackground.800');
  const { onOpenSupportDrawer } = useSupportDrawerContext();

  return (
    <Flex
      align="center"
      p={2}
      position={'fixed'}
      top={0}
      right={0}
      zIndex={10}
      width={`calc(100vw - ${SIDENAV_WIDTH} - 25px)`}
      bg={bgColor}
      data-testid="desktop-site-top-nav"
    >
      <Flex w="100%" position={'relative'} maxWidth={`calc(100vw - ${SIDENAV_WIDTH} - 90px)`}>
        <Button
          data-testid="desktop-nav-back-btn"
          variant="ghost"
          size="sm"
          onClick={() => {
            setNavigationState({ direction: 'backward' });
            onGoBack();
          }}
          pt={1}
        >
          <ChevronLeftIcon w={6} h={6} />
          <Text mr={2}>Back</Text>
        </Button>
        <Text
          fontWeight={'bold'}
          fontSize="lg"
          ml="auto"
          position="absolute"
          top="50%"
          left=" 50%"
          transform="translate(-50%, -50%)"
          noOfLines={1}
        >
          {title} {children}
        </Text>

        <Button
          variant="link"
          color="customLinkBlue.500"
          ml="auto"
          mr={4}
          onClick={onOpenSupportDrawer}
          data-testid="help-button"
        >
          Help
        </Button>
      </Flex>
    </Flex>
  );
}
