import React, { useMemo, useRef, useState } from 'react';
import CustomDatePicker from '../../../../../common/components/date-picker/CustomDatePicker';
import { FiRefreshCw } from 'react-icons/fi';
import { utcToZonedTime } from 'date-fns-tz';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Select,
  Text,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import moment from 'moment';
import { OriginalWattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { formatDate, formatTime } from 'clipsal-cortex-utils/src/formatting/formatting';
import { buildLongChartData, buildShortChartData } from '../meter-config-helpers';
import { LongPowerDataType, ShortPowerDataType } from '../meter-config-types';
import { useLongEnergy, useShortEnergy } from '../meterConfigApi';
import { COLOURS, COMMON_CHART_DATETIME_FORMATS } from '../../../../../common/constants';
import {
  DATA_TYPE_TO_VALUE_SUFFIX,
  FORMATTED_TIMEZONES_FOR_SELECT,
  LONG_DATA_TYPE_TO_TITLE_MAP,
  SHORT_DATA_TYPE_TO_TITLE_MAP,
} from '../meter-config-constants';
import { cloneDeep } from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import LongEnergyTable from './LongEnergyTable';
import ShortEnergyTable from './ShortEnergyTable';

export type IntervalType = 'Short Energy' | 'Long Energy';

const INITIAL_STATE = {
  selectedInterval: 'Short Energy' as IntervalType,
  selectedShortPowerDataType: 'pRealKw' as ShortPowerDataType,
  selectedLongPowerDataType: 'pRealKw' as LongPowerDataType,
  selectedDate: new Date(),
  hiddenChannelIndexes: [] as number[],
  timezone: 'Australia/Sydney',
};

type Props = {
  serialNumber: string;
  channels: OriginalWattwatchersMeter['channels'];
};

export default function MeterConfigurationChart({ serialNumber, channels }: Readonly<Props>) {
  const [
    {
      selectedInterval,
      selectedShortPowerDataType,
      selectedLongPowerDataType,
      selectedDate,
      hiddenChannelIndexes,
      timezone,
    },
    setState,
  ] = useState(INITIAL_STATE);
  const isShortEnergySelected = selectedInterval === 'Short Energy';
  const theme = useTheme();
  const chartLabelColor = useColorModeValue(theme.colors.gray[500], theme.colors.gray[400]);
  const {
    data: longEnergyData,
    refetch,
    isFetching,
  } = useLongEnergy(
    serialNumber,
    {
      reading_date: formatDate(selectedDate),
      timezone: timezone,
    },
    selectedInterval === 'Short Energy'
  );
  const { data: shortEnergyData } = useShortEnergy(serialNumber, selectedInterval === 'Long Energy');

  const chartRef = useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  } | null>(null);
  // I wasted far too long debugging strange UI issues to figure out the React Highcharts wrapper breaks basic React
  // core principles by mutating the chart props object passed to it directly (apparently for "performance reasons").
  // Because of this, we have to deep clone the state object being passed to the chart, otherwise countless weird bugs
  // end up happening.
  // see: https://github.com/highcharts/highcharts-react#why-highcharts-mutates-my-data

  const checkDateBeforeToday = (date: Date) => date <= utcToZonedTime(new Date(), timezone);

  const { seriesData, chartOptions, latestCommunicatedTime, longChartData, shortChartData } = useMemo(() => {
    const longChartData = buildLongChartData(longEnergyData, channels);

    const shortChartData = buildShortChartData(shortEnergyData, channels);

    const seriesData = cloneDeep(
      isShortEnergySelected ? shortChartData[selectedShortPowerDataType] : longChartData[selectedLongPowerDataType]
    );

    const chartOptions = {
      title: {
        text: selectedInterval,
        style: { color: chartLabelColor },
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        itemStyle: { color: chartLabelColor },
      },
      yAxis: {
        title: {
          text: seriesData.name,
          style: { color: chartLabelColor },
        },
        labels: {
          style: { color: chartLabelColor },
          formatter: function () {
            return (
              this.value +
              ` ${
                DATA_TYPE_TO_VALUE_SUFFIX[
                  isShortEnergySelected ? selectedShortPowerDataType : selectedLongPowerDataType
                ]
              }`
            );
          },
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
        labels: {
          style: { color: chartLabelColor },
        },
      },
      time: {
        moment,
        timezone,
      },
      tooltip: {
        animation: false,
        shared: true,
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
      },
      series: seriesData.data.map((series, index) => {
        return {
          ...series,
          visible: !hiddenChannelIndexes.includes(index),
        };
      }),
      colors: COLOURS,
      chart: {
        zoomType: 'x',
        height: '400px',
        backgroundColor: 'transparent',
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
          events: {
            legendItemClick: function (e: Event) {
              e.preventDefault();
              toggleLineState(this.index);
            },
          },
        },
      },
    };

    let latestCommunicatedTime = 'N/A';
    const latestTime = seriesData.data[0].data[seriesData.data[0].data.length - 1]?.x;
    if (latestTime) latestCommunicatedTime = formatTime(utcToZonedTime(new Date(latestTime), timezone));

    return { seriesData, chartOptions, latestCommunicatedTime, longChartData, shortChartData };
  }, [
    selectedLongPowerDataType,
    selectedShortPowerDataType,
    selectedInterval,
    channels,
    timezone,
    shortEnergyData,
    longEnergyData,
    chartLabelColor,
    hiddenChannelIndexes,
  ]);

  function handleToggleLine(channelIndex: number) {
    if (chartRef.current)
      chartRef.current.chart.series[channelIndex].setVisible(!chartRef.current.chart.series[channelIndex].visible);
    toggleLineState(channelIndex);
  }

  function toggleLineState(channelIndex: number) {
    const hiddenKeys = hiddenChannelIndexes;

    if (hiddenKeys.includes(channelIndex)) {
      setState((prevState) => ({
        ...prevState,
        hiddenChannelIndexes: hiddenChannelIndexes.filter((key) => key !== channelIndex),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        hiddenChannelIndexes: [...hiddenChannelIndexes, channelIndex],
      }));
    }
  }

  return (
    <Box>
      <Grid templateColumns={'1fr 1fr'} gap={4} maxW={800}>
        <GridItem w="100%">
          <FormControl>
            <FormLabel>Select Interval</FormLabel>
            <Select
              data-testid="energy-type-select"
              value={selectedInterval}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  selectedInterval: e.target.value as IntervalType,
                }));
                // reset zoom on interval change so chart doesn't get blank
                chartRef.current?.chart.zoomOut();
              }}
            >
              <option value="Short Energy">Short Energy</option>
              <option value="Long Energy">Long Energy</option>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem w="100%">
          <FormControl>
            <FormLabel>Select Data Type</FormLabel>
            <Select
              data-testid="data-type-select"
              value={isShortEnergySelected ? selectedShortPowerDataType : selectedLongPowerDataType}
              onChange={
                isShortEnergySelected
                  ? (e) =>
                      setState((prevState) => ({
                        ...prevState,
                        selectedShortPowerDataType: e.target.value as ShortPowerDataType,
                      }))
                  : (e) =>
                      setState((prevState) => ({
                        ...prevState,
                        selectedLongPowerDataType: e.target.value as LongPowerDataType,
                      }))
              }
            >
              {isShortEnergySelected
                ? Object.entries(SHORT_DATA_TYPE_TO_TITLE_MAP).map(([type, label]) => (
                    <option key={type} value={type}>
                      {label}
                    </option>
                  ))
                : Object.entries(LONG_DATA_TYPE_TO_TITLE_MAP).map(([type, label]) => (
                    <option key={type} value={type}>
                      {label}
                    </option>
                  ))}
            </Select>
          </FormControl>
        </GridItem>
      </Grid>
      <Grid templateColumns={'1fr 1fr'} gap={4} mt={4} maxW={800}>
        <GridItem w="100%">
          <FormControl>
            <FormLabel>Set Timezone</FormLabel>
            <Select
              data-testid="timezone-select"
              value={timezone}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  timezone: e.target.value,
                }))
              }
            >
              {FORMATTED_TIMEZONES_FOR_SELECT.map((timezone) => (
                <option key={timezone.value} value={timezone.value}>
                  {timezone.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem w="100%">
          {isShortEnergySelected ? (
            <Flex w="100%" h="100%">
              <Text textAlign={'center'} mt="auto" mb={2}>
                <strong style={{ fontWeight: 600 }}>Last communicated at</strong> {latestCommunicatedTime}
              </Text>
            </Flex>
          ) : (
            <Flex direction="column">
              <Text fontWeight={600} ml={12} mb={2}>
                Set Effective Date
              </Text>
              <Flex align="center">
                <IconButton
                  data-testid={'prev-date'}
                  size={'md'}
                  rounded={20}
                  mr={2}
                  isDisabled={isFetching}
                  variant={'ghost'}
                  aria-label="Previous"
                  icon={<ChevronLeftIcon h={5} w={5} />}
                  onClick={() => {
                    const date = new Date(selectedDate.getTime());
                    date.setDate(date.getDate() - 1);

                    setState((prevState) => ({
                      ...prevState,
                      selectedDate: date,
                    }));
                  }}
                />
                <CustomDatePicker
                  maxDate={new Date()}
                  inputProps={{
                    'data-testid': 'meter-config-date-picker',
                    placeholder: 'dd/mm/yyyy',
                    minW: 150,
                  }}
                  disabled={isFetching}
                  popperPlacement="top-start"
                  dateFormat="dd/MM/yyyy"
                  selected={selectedDate}
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      selectedDate: new Date((e as Date).getTime()),
                    }));
                  }}
                />
                <IconButton
                  data-testid={'next-date'}
                  size={'md'}
                  rounded={20}
                  mr={2}
                  variant={'ghost'}
                  aria-label="Previous"
                  icon={<ChevronRightIcon h={5} w={5} />}
                  isDisabled={
                    isFetching || formatDate(selectedDate) === formatDate(utcToZonedTime(new Date(), timezone))
                  }
                  onClick={() => {
                    const date = new Date(selectedDate.getTime());

                    // Can't go into the future
                    if (!checkDateBeforeToday(date)) return;

                    date.setDate(date.getDate() + 1);

                    setState((prevState) => ({
                      ...prevState,
                      selectedDate: date,
                    }));
                  }}
                />

                <IconButton
                  data-testid={'refresh-data'}
                  size={'md'}
                  rounded={20}
                  mr={2}
                  aria-label="Previous"
                  isLoading={isFetching}
                  icon={<FiRefreshCw size={14} />}
                  onClick={refetch}
                />
              </Flex>
            </Flex>
          )}
        </GridItem>
      </Grid>

      <Box style={{ margin: '1rem 0' }} data-testid={`chart-${selectedInterval}`} minH={400}>
        {!seriesData.data.some(({ data }) => data.length) ? (
          <Box>There is no data for this device on this day.</Box>
        ) : (
          <Chart ref={chartRef} highcharts={Highcharts} options={chartOptions} />
        )}

        <Box mt={4}>
          <Flex mb={2}>
            <Text mr={1} fontWeight={500}>
              Latest Data
            </Text>
            <Text>(polled at {latestCommunicatedTime})</Text>
          </Flex>
          {isShortEnergySelected ? (
            <ShortEnergyTable
              channels={channels}
              onToggleLine={handleToggleLine}
              hiddenIndexes={hiddenChannelIndexes}
              shortChartData={shortChartData}
            />
          ) : (
            <LongEnergyTable
              channels={channels}
              onToggleLine={handleToggleLine}
              hiddenIndexes={hiddenChannelIndexes}
              longChartData={longChartData}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
