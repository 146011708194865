import { Navigate } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/user/userSlice';

interface Props {
  children?: ReactNode;
}

export const RouteAuthCheck = ({ children }: Props) => {
  const { isLoggedIn } = useSelector(selectUser);
  return isLoggedIn ? <>{children}</> : <Navigate to="/login" />;
};
