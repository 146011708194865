import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

export default function Account() {
  return (
    <Box>
      <Outlet />
    </Box>
  );
}
