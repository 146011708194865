import { yupResolver } from '@hookform/resolvers/yup';
import {
  OriginalChannelData,
  OriginalWattwatchersMeter,
  SwitchData,
  WattwatchersMeter,
} from 'clipsal-cortex-types/src/api/api-ww-meter';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ChannelConfiguration from './ChannelConfiguration';
import SwitchConfiguration from './SwitchConfiguration';
import CustomButton from '../../../../../common/components/CustomButton';
import {
  UpdateMeterDetailsBody,
  useUpdateMeterDetailsMutation,
  useUpdateSiteMeterDetailsMutation,
} from '../meterConfigApi';
import { mapWWChannelsToClipsalCircuits, meterFormSchema } from './meter-form-helpers';
import { useToast } from '@chakra-ui/react';

type MeterFormProps = {
  meter: OriginalWattwatchersMeter;
  siteMeter?: WattwatchersMeter;
  serialNumber: string;
};

const MeterForm = ({ meter, siteMeter, serialNumber }: MeterFormProps) => {
  const [currentSerialNumber, setCurrentSerialNumber] = useState(serialNumber);
  const [updateMeter, { isLoading: isUpdatingMeter }] = useUpdateMeterDetailsMutation();
  const [updateSiteMeter, { isLoading: isUpdatingSiteMeter }] = useUpdateSiteMeterDetailsMutation();
  const { channels, switches = [] } = meter;
  const methods = useForm({
    resolver: yupResolver(meterFormSchema),
    defaultValues: { channels, switches },
  });
  const toast = useToast({
    status: 'success',
    duration: 5000,
    isClosable: true,
  });

  useEffect(() => {
    // update form if meter has been changed and new data has arrived
    if (currentSerialNumber !== serialNumber) {
      const hasChannelsUpdated = channels?.[0]?.id.includes(serialNumber);
      if (hasChannelsUpdated) {
        setCurrentSerialNumber(serialNumber);
        methods.reset({ channels, switches });
      }
    }

    // update pending values to real values when available
    channels.forEach((channel, index) => {
      const pendingValues = channel.pending;
      if (pendingValues) {
        if (pendingValues.ctRating) methods.setValue(`channels.${index}.ctRating`, pendingValues.ctRating);
        if (pendingValues.polarity) methods.setValue(`channels.${index}.polarity`, pendingValues.polarity);
        if (pendingValues.voltageReference)
          methods.setValue(`channels.${index}.voltageReference`, pendingValues.voltageReference);
      }
    });

    switches.forEach((sw, index) => {
      const pendingValues = sw.pending;
      if (pendingValues) {
        if (pendingValues.contactorType)
          methods.setValue(`switches.${index}.contactorType`, pendingValues.contactorType);
        if (pendingValues.openStateLabel)
          methods.setValue(`switches.${index}.openStateLabel`, pendingValues.openStateLabel);
        if (pendingValues.closedStateLabel)
          methods.setValue(`switches.${index}.closedStateLabel`, pendingValues.closedStateLabel);
        if (pendingValues.state) methods.setValue(`switches.${index}.state`, pendingValues.state);
      }
    });
  }, [channels, switches, serialNumber]);

  const onSubmit = async (data: { channels: OriginalChannelData[]; switches: SwitchData[] }) => {
    try {
      if (siteMeter) {
        await updateSiteMeter({
          ...siteMeter,
          circuits: mapWWChannelsToClipsalCircuits(data.channels, siteMeter.circuits),
        }).unwrap();
      }

      const body: UpdateMeterDetailsBody = {
        id: meter.id,
        channels: data.channels.map((ch) => {
          // We dont need categoryLabel and pending in the body
          const { categoryLabel, pending, ...rest } = ch;
          return rest;
        }),
      };

      if (switches.length)
        body.switches = data.switches.map((sw) => {
          // We dont need categoryLabel and pending in the body
          const { categoryLabel, pending, ...rest } = sw;
          return rest;
        });
      await updateMeter(body).unwrap();
      toast({
        title: 'Meter Configuration Saved!',
      });
    } catch (error) {
      toast({
        status: 'error',
        title: 'Meter Configuration Failed!',
      });
      console.error(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <ChannelConfiguration meter={meter} />
      {meter.switches && <SwitchConfiguration meter={meter} />}
      <CustomButton
        data-testid="save-config-btn"
        minW={250}
        onClick={methods.handleSubmit(onSubmit)}
        isLoading={isUpdatingMeter || isUpdatingSiteMeter}
      >
        Save Configuration
      </CustomButton>
    </FormProvider>
  );
};

export default MeterForm;
