import { FleetSystemHealthStatus } from '../../api/api-fleet-data';
import {
  HouseFaultIcon,
  HouseNormalIcon,
  HouseOfflineDarkIcon,
  HouseOfflineIcon,
  OfflineIcon,
  ZeroProductionIcon,
} from '../../styles/custom-icons';

export const SYSTEM_HEALTH_STATUS_CONFIG = {
  NORMAL: {
    title: 'Normal',
    icon: HouseNormalIcon,
    darkIcon: HouseNormalIcon,
    alternateIcon: HouseNormalIcon,
    color: 'primaryBranding.500',
    alertDetails: '',
    troubleshootingSteps: [] as string[],
  },
  FAULT: {
    title: 'Fault',
    icon: HouseFaultIcon,
    darkIcon: HouseFaultIcon,
    alternateIcon: ZeroProductionIcon,
    color: 'customRed.500',
    alertDetails: `Zero Production alert is shown when the solar inverter is detected producing no energy. Some common factors, including but not limited to: weather events, tripped circuit breaker, inverter fault, and other system device faults.`,
    troubleshootingSteps: [
      `Look for the error code on the inverter monitoring portal and contact the inverter manufacturer for guidance on the next steps.`,
      'Connect with the homeowner and consider conducting basic troubleshooting steps.',
      'Plan and schedule a site visit to conduct a more in-depth diagnosis of the situation.',
    ],
  },
  OFFLINE: {
    title: 'Offline',
    icon: HouseOfflineIcon,
    darkIcon: HouseOfflineDarkIcon,
    alternateIcon: OfflineIcon,
    color: 'fixedCostGrey.500',
    alertDetails: 'The meter is not communicating with the Clipsal Cortex servers.',
    troubleshootingSteps: [],
  },
};

export const FIRST_YEAR_OF_DATA = 2019;

export const INITIAL_SYSTEM_HEALTH_STATUS: FleetSystemHealthStatus = {
  NORMAL: 0,
  FAULT: 0,
  OFFLINE: 0,
};
