import React, { useEffect, useState } from 'react';
import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Home } from './Home';
import { SubmitTicket } from './SubmitTicket';
import { ViewTicket } from './ViewTicket';
import { useSelector } from 'react-redux';
import { selectUser } from '../../userSlice';
import { Ticket, ZendeskUser } from '../../../../api/api-zendesk';
import { get } from '../../../../api/api-helpers';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { ModalProps } from '../../../../common/types/types';
import useInterval from '../../../../common/hooks/use-interval';

export type SupportRoute = 'home' | 'submitTicket' | 'viewTicket';

const ROUTE_TO_TITLE: Record<SupportRoute, string> = {
  home: 'Support Center',
  submitTicket: 'New Ticket',
  viewTicket: 'View Ticket',
};

export default function SupportModal({ isOpen, onClose }: ModalProps) {
  const user = useSelector(selectUser);
  const [isLoaded, setLoaded] = useState(false);
  const [route, setRoute] = useState<SupportRoute>('home');
  const [zendeskUser, setZendeskUser] = useState<ZendeskUser | null>(null);
  const [currentlyViewingTicket, setCurrentlyViewingTicket] = useState<Ticket | null>(null);

  const routeToContents: Record<SupportRoute, JSX.Element> = {
    home: <Home onChangeCurrentlyViewingTicket={setCurrentlyViewingTicket} onChangeRoute={setRoute} />,
    submitTicket: (
      <SubmitTicket
        zendeskUser={zendeskUser}
        onChangeUser={setZendeskUser}
        onChangeCurrentlyViewingTicket={(ticket) => {
          setCurrentlyViewingTicket(ticket);
          setRoute('viewTicket');
        }}
      />
    ),
    viewTicket: <ViewTicket zendeskUser={zendeskUser} ticket={currentlyViewingTicket} />,
  };

  useEffect(() => {
    async function fetchTicketFields() {
      try {
        const zendeskUser = await get<ZendeskUser>(`/fleet/users/${user.user_id}/zendesk_user`);
        setZendeskUser(zendeskUser);
      } catch (e) {
        console.info('No user in Zendesk, one will be created when a ticket is created.');
      }

      setLoaded(true);
    }

    fetchTicketFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoaded]);

  useInterval(async () => {
    if (isLoaded && !zendeskUser) {
      try {
        const zendeskUser = await get<ZendeskUser>(`/fleet/users/${user.user_id}/zendesk_user`);
        setZendeskUser(zendeskUser);
      } catch (e) {
        console.info('Still no Zendesk user, trying again in 5 seconds.');
      }
    }
  }, 5000);

  return (
    <Modal scrollBehavior="inside" isCentered={true} size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid="supportCenterModal" rounded={20}>
        <ModalHeader>
          <Flex align={'center'}>
            {route !== 'home' && (
              <IconButton
                onClick={() => setRoute('home')}
                mr={1}
                size={'sm'}
                variant={'ghost'}
                aria-label="Go back"
                icon={<ChevronLeftIcon w={'20px'} h={'20px'} />}
              />
            )}
            {ROUTE_TO_TITLE[route]}
          </Flex>
        </ModalHeader>
        <ModalCloseButton data-testid="supportCenterCloseBtn" />
        <ModalBody pb={6}>{isLoaded ? routeToContents[route] : <CenteredLoader />}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
