import React, { useMemo } from 'react';
import { Box, Flex, Icon, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { useFirstEnergyTimestamp } from './meterConfigApi';
import { OriginalWattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import CustomSignalIcon from 'clipsal-cortex-ui/src/components/CustomSignalIcon';
import { getMinuteDifferenceBetweenTimeStamps } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { formatDateTime } from 'clipsal-cortex-utils/src/formatting/formatting';
import { utcToZonedTime } from 'date-fns-tz';
import { AiFillCheckCircle } from 'react-icons/ai';
import { RiErrorWarningFill, RiCloseCircleFill } from 'react-icons/ri';
import { convertOriginalWWResponseToClipsalWWResponse } from './meter-config-helpers';
import { WW_MODEL_TO_CLIPSAL_MODEL_MAP } from 'clipsal-cortex-utils/src/constants/common-constants';

type MeterDetailsProps = {
  meterId: string;
  meterData: OriginalWattwatchersMeter;
};

const MeterDetails = ({ meterId, meterData }: MeterDetailsProps) => {
  const { data } = useFirstEnergyTimestamp(meterId, { timezone: meterData.timezone });
  const meterFirstCommunicatedTimestamp = data.timestamp;
  const meterLastCommunicatedTimestamp = meterData.comms.lastHeardAt;
  // convert timestamp to date object based on timezone
  const formattedMeterFirstCommunicatedDate = meterFirstCommunicatedTimestamp
    ? formatDateTime(utcToZonedTime(new Date(meterFirstCommunicatedTimestamp * 1000), meterData.timezone))
    : 'N/A';

  const {
    wwLastCommunicatedColor,
    wwLastCommunicatedIcon,
    wwLastCommunicatedTime,
    isGen2Meter,
    wifiSsid,
    cellularCarrier,
    wifiMode,
    cellularMode,
    networkId,
    mode,
  } = useMemo(() => {
    if (!meterLastCommunicatedTimestamp)
      return {
        wwLastCommunicatedColor: 'red',
        wwLastCommunicatedIcon: RiCloseCircleFill,
        wwLastCommunicatedTime: 'N/A',
      };

    const lastHeardInMinutes =
      getMinuteDifferenceBetweenTimeStamps(meterLastCommunicatedTimestamp, meterData.timezone) ?? 0;

    let wwLastCommunicatedColor = lastHeardInMinutes <= 1 ? 'green' : 'yellow.500';
    let wwLastCommunicatedIcon = lastHeardInMinutes <= 1 ? AiFillCheckCircle : RiErrorWarningFill;

    // if last heard is more than 10 minutes ago, show red color
    if (lastHeardInMinutes > 10) {
      wwLastCommunicatedColor = 'red';
      wwLastCommunicatedIcon = RiCloseCircleFill;
    }

    const getWWLastCommunicatedTime = () => {
      if (isNaN(lastHeardInMinutes)) return 'N/A';
      if (lastHeardInMinutes <= 1) {
        return '< 1m ago';
      } else if (lastHeardInMinutes < 60) {
        return `< ${lastHeardInMinutes}m ago`;
      } else {
        const differenceInHours = Math.ceil(lastHeardInMinutes / 60);
        if (differenceInHours < 24) {
          return `< ${differenceInHours}h ago`;
        } else {
          return `< ${Math.ceil(differenceInHours / 24)}d ago`;
        }
      }
    };

    const { networkId, mode, type, wifi, cellular } = meterData.comms;
    let wifiSsid = networkId;
    let cellularCarrier = networkId;
    let wifiMode = mode;
    let cellularMode = mode;

    // gen 2 meter have different schema for network details
    const isGen2Meter = type === 'cellular,wifi';
    if (isGen2Meter) {
      wifiSsid = wifi?.ssid ?? 'N/A';
      cellularCarrier = cellular?.carrier ?? 'N/A';

      wifiMode = wifi?.mode ?? 'N/A';
      cellularMode = cellular?.mode ?? 'N/A';
    }

    return {
      wwLastCommunicatedColor,
      wwLastCommunicatedIcon,
      wwLastCommunicatedTime: getWWLastCommunicatedTime(),
      isGen2Meter,
      wifiSsid,
      cellularCarrier,
      wifiMode,
      cellularMode,
      networkId,
      mode,
    };
  }, [meterData, meterId]);

  return (
    <Box>
      <TableContainer
        bg={'white'}
        _dark={{ bg: 'gray.900' }}
        w="100%"
        border={'1px solid'}
        borderColor={'gray.200'}
        borderRadius={8}
        data-testid="meter-details-table"
      >
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th fontSize="13px" py={3} textAlign={'center'}>
                Model
              </Th>
              <Th fontSize="13px" textAlign={'center'}>
                First Communicated
              </Th>
              <Th fontSize="13px" textAlign={'center'}>
                Last Communicated
              </Th>
              <Th fontSize="13px" textAlign={'center'}>
                Firmware Version
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td border="none !important" textAlign={'center'}>
                {WW_MODEL_TO_CLIPSAL_MODEL_MAP[meterData.model] ?? meterData.model}
              </Td>
              <Td border="none !important" textAlign={'center'}>
                {formattedMeterFirstCommunicatedDate}
              </Td>
              <Td border="none !important" textAlign={'center'}>
                <Flex direction="column" align="center">
                  <Icon w={6} h={6} color={wwLastCommunicatedColor} as={wwLastCommunicatedIcon} />
                  <Text fontWeight={600} color={wwLastCommunicatedColor} mt={2}>
                    {wwLastCommunicatedTime}
                  </Text>
                </Flex>
              </Td>
              <Td border="none !important" textAlign={'center'}>
                {meterData.firmwareVersion ?? 'N/A'}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <TableContainer
        bg={'white'}
        _dark={{ bg: 'gray.900' }}
        w="100%"
        border={'1px solid'}
        borderColor={'gray.200'}
        borderRadius={8}
        data-testid="meter-network-table"
        mt={2}
      >
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th fontSize="13px" textAlign={'center'}>
                Signal Strength (dBm)
              </Th>
              {isGen2Meter ? (
                <>
                  <Th fontSize="13px" textAlign={'center'}>
                    Wifi SSID
                  </Th>
                  <Th fontSize="13px" textAlign={'center'}>
                    Carrier
                  </Th>
                  <Th fontSize="13px" textAlign={'center'}>
                    Wifi Mode
                  </Th>
                  <Th fontSize="13px" textAlign={'center'}>
                    Cellular Mode
                  </Th>
                </>
              ) : (
                <>
                  <Th fontSize="13px" textAlign={'center'}>
                    Network ID
                  </Th>
                  <Th fontSize="13px" textAlign={'center'}>
                    Network Mode
                  </Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td border="none !important" textAlign={'center'}>
                <VStack>
                  <Flex align="center" justify="center">
                    <CustomSignalIcon meter={convertOriginalWWResponseToClipsalWWResponse(meterData)} />
                  </Flex>
                  <Text fontWeight={600}>{`(${meterData.comms.signalQualityDbm ?? 'N/A'} dBm)`}</Text>
                </VStack>
              </Td>
              {isGen2Meter ? (
                <>
                  <Td border="none !important" textAlign={'center'}>
                    {wifiSsid}
                  </Td>
                  <Td border="none !important" textAlign={'center'}>
                    {cellularCarrier}
                  </Td>
                  <Td border="none !important" textAlign={'center'}>
                    {wifiMode}
                  </Td>
                  <Td border="none !important" textAlign={'center'}>
                    {cellularMode}
                  </Td>
                </>
              ) : (
                <>
                  <Td border="none !important" textAlign={'center'}>
                    {networkId}
                  </Td>
                  <Td border="none !important" textAlign={'center'}>
                    {mode}
                  </Td>
                </>
              )}
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MeterDetails;
