import { Amplify, API } from 'aws-amplify';
import { ENV_TYPE } from '../env-type';
import { ENDPOINT } from '../common/constants';

/**
 * Configures the AWS Amplify end-point setup for this repo.
 *
 * Note that we need to separately configure the `API` class, as for some reason in this monorepo architecture it does
 * not correctly initialize itself.
 *
 * see: https://github.com/aws-amplify/amplify-js/issues/5756
 */
export function setupAPI() {
  const apiConfig = {
    endpoints: [
      {
        name: 'site',
        endpoint: ENDPOINT,
        region: 'ap-southeast-2',
      },
    ],
  };

  Amplify.configure({
    Auth: {
      region: 'ap-southeast-2',
      userPoolId: import.meta.env?.[`VITE_${ENV_TYPE}_USER_POOL_ID`],
      userPoolWebClientId: import.meta.env?.[`VITE_${ENV_TYPE}_CLIENT_ID`],
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    API: apiConfig,
  });

  API.configure(apiConfig);
}
