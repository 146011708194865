import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useAppDispatch } from '../../app/hooks';
import { checkUserLoggedIn, fetchUserDetails } from '../../features/user/userSlice';

export const useAppReady = (): boolean => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    async function checkIfUserIsLoggedIn() {
      const user = await dispatch(checkUserLoggedIn());
      const { isLoggedIn } = unwrapResult(user);
      if (isLoggedIn) {
        await dispatch(fetchUserDetails());
      }
      setIsLoaded(true);
    }

    checkIfUserIsLoggedIn();

    if (Capacitor.isNativePlatform()) {
      App.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          App.exitApp();
        } else {
          navigate(-1);
        }
      });
    }
  }, [dispatch]);

  return isLoaded;
};
