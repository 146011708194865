import { SiteNavLink } from './features/navigation/custom-nav-hooks';
import {
  AccountIconActive,
  AccountIconInactive,
  DashboardIconActive,
  DashboardIconInactive,
  SitesIconActive,
  SitesIconInactive,
  UpsellActiveIcon,
  UpsellIcon,
} from './styles/custom-icons';
import React from 'react';

export const NAV_LINKS: SiteNavLink[] = [
  {
    route: '/fleet_dashboard',
    routeName: 'Fleet Dashboard',
    ariaLabel: 'Fleet Dashboard',
    icon: <DashboardIconInactive />,
    activeIcon: <DashboardIconActive color="primaryBrandingStatic.500" />,
  },
  {
    route: '/dashboard',
    routeName: 'Sites',
    ariaLabel: 'Sites',
    icon: <SitesIconInactive />,
    activeIcon: <SitesIconActive color="primaryBrandingStatic.500" />,
  },
  {
    route: '/upsells',
    routeName: 'Upsells',
    ariaLabel: 'Upsells',
    isNew: true,
    icon: <UpsellIcon />,
    activeIcon: <UpsellActiveIcon color="primaryBrandingStatic.500" />,
  },
  {
    route: '/account/home',
    routeName: 'Account',
    ariaLabel: 'Account',
    icon: <AccountIconInactive />,
    activeIcon: <AccountIconActive color="primaryBrandingStatic.500" />,
  },
];
