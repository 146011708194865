import { Flex, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi';

const COMMON_ICON_BUTTON_PROPS = {
  shadow: 'lg',
  size: 'xs',
  isRound: true,
  _active: { opacity: '80%' },
  _hover: { opacity: '80%' },
  _disabled: { opacity: '60%', cursor: 'not-allowed' },
};

interface Props {
  value: number;
  onIncrement: () => void;
  onDecrement: () => void;
  isIncrementDisabled?: boolean;
  isDecrementDisabled?: boolean;
  incrementAriaLabel?: string;
  decrementAriaLabel?: string;
  dataTestId?: string;
}

/**
 *  Counter is a generic increment/decrement component.
 */
const Counter = ({
  value = 0,
  onIncrement,
  onDecrement,
  isIncrementDisabled = false,
  isDecrementDisabled = false,
  incrementAriaLabel = 'Increase',
  decrementAriaLabel = 'Decrease',
  dataTestId = '',
}: Props) => {
  const { background, buttonBackground } = useColorModeValue(
    { background: 'gray.100', buttonBackground: 'white' },
    { background: 'customBodyBackground.800', buttonBackground: 'whiteAlpha.400' }
  );
  return (
    <Flex
      data-testid={dataTestId}
      align="center"
      justify="space-between"
      px={1.5}
      rounded="20px"
      background={background}
      w={28}
      py={1}
    >
      <IconButton
        {...COMMON_ICON_BUTTON_PROPS}
        icon={<HiOutlineMinus />}
        background={buttonBackground}
        isDisabled={isDecrementDisabled}
        aria-label={decrementAriaLabel}
        onClick={onDecrement}
      />
      <Text px={2}>{value}</Text>
      <IconButton
        {...COMMON_ICON_BUTTON_PROPS}
        background={buttonBackground}
        icon={<HiOutlinePlus />}
        isDisabled={isIncrementDisabled}
        aria-label={incrementAriaLabel}
        onClick={onIncrement}
      />
    </Flex>
  );
};

export default Counter;
