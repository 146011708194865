import { Box, Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import React from 'react';

type Props = {
  color: string;
  title: string;
  value: number | null;
  isLoaded: boolean;
};

export default function FleetInfoStats({ color, title, value, isLoaded }: Props) {
  return (
    <Flex>
      <Box rounded={10} mr={2} w={'8px'} bg={color} />
      <Box>
        <Box>{title}</Box>
        <Skeleton isLoaded={isLoaded}>
          <Flex align={'center'}>
            <Heading data-testid={`fleet-info-stats-${title.replaceAll(' ', '-').toLowerCase()}`} size="lg">
              {value?.toFixed(2) ?? 'N/A'}
            </Heading>
            <Text ml={1} fontSize={'sm'}>
              MWh
            </Text>
          </Flex>
        </Skeleton>
      </Box>
    </Flex>
  );
}
