import {
  Action,
  CombinedState,
  combineReducers,
  configureStore,
  PayloadAction,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import wizardReducer from '../features/wizard/wizardSlice';
import userReducer from '../features/user/userSlice';
import siteReducer from '../features/site/siteSlice';
import systemDetailsReducer from '../features/site/system-details/systemDetailsSlice';
import meterSetupReducer from '../features/site/meter-setup/meterSetupSlice';
import webSocketReducer from '../features/site/meter-setup/webSocketSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import { baseApi } from './baseApi';

const rootReducer = combineReducers({
  wizard: wizardReducer,
  user: userReducer,
  site: siteReducer,
  systemDetails: systemDetailsReducer,
  meterSetup: meterSetupReducer,
  dashboard: dashboardReducer,
  webSocket: webSocketReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

/**
 * Wraps our root reducer, allowing store de-hydration from the top-level.
 *
 * @TODO: figure out why we can't use `RootState` generic type on `CombinedState`.
 *
 * @param state - The root state
 * @param action - The action sent.
 */
export const resettableRootReducer = (state: CombinedState<any>, action: PayloadAction<any>) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false }), baseApi.middleware],
});

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
