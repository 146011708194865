import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { CommonFieldListProps, CTConfigFormData } from './ct-configuration-form-types';

/**
 * This component watches changes in forms and composes new reservedCTs object
 * when form state changes. Also solves syncing state issues in react hook form
 * @param control control object of react hook form
 * @param solarApplianceIndex index of the solarAppliance
 */
export const useReservedCTsForSolar = (control: CommonFieldListProps['control'], solarApplianceIndex: number) => {
  const watchFormValues = useWatch({ control }) as CTConfigFormData;

  return useMemo(() => {
    const { grid, solar, battery, hybrid, load, evCharger } = watchFormValues;
    return solar.reduce(
      (acc: ChannelData[], val, index) => {
        if (!val.circuits || solarApplianceIndex === index) return acc;
        else return [...acc, ...val.circuits];
      },
      [
        ...grid,
        ...battery.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
        ...load.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
        ...Object.values(hybrid).flat(),
        ...evCharger.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
      ]
    );
  }, [watchFormValues, solarApplianceIndex]);
};
