import React, { useEffect, useMemo, useState } from 'react';
import { Box, Center, Heading, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import OTPInput from 'clipsal-cortex-ui/src/components/OTPInput';
import { ReactComponent as MailOtp } from '../../../assets/images/mail_otp.svg';
import CustomButton from '../../../common/components/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { ErrorType, OTP_ERROR_TYPE_TO_TOAST_CONFIG } from './signup-helpers';

function OTPVerification() {
  const navigate = useNavigate();
  const borderColor = useColorModeValue('rgba(0, 0, 0, 0.25)', 'rgba(255, 255, 255, 0.25)');

  // check if otp verification is link based
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const username = urlParams.get('user_name');
  const otpCode = urlParams.get('code') || '';

  const [{ otp, isLoading, isError, isSuccess }, setState] = useState({
    isLoading: !!username && !!otpCode,
    isError: false,
    isSuccess: false,
    otp: otpCode,
  });
  const toast = useToast({ isClosable: true, duration: 3000, status: 'error' });

  useEffect(() => {
    const autoSignIn = async (username: string, otpCode: string) => {
      try {
        await Auth.confirmSignUp(username, otpCode);
        toast({
          title: `You have been successfully signed up!`,
          description: `Redirecting to login page...`,
          status: 'success',
        });
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 2000);
        setState((prevState) => ({ ...prevState, isLoading: false, isError: false, isSuccess: true }));
      } catch (e) {
        const errorType = ((e as any)?.name || (e as any)?.response?.name || '') as ErrorType;
        const errorKey = errorType in OTP_ERROR_TYPE_TO_TOAST_CONFIG ? errorType : 'Default';
        const { title, description } = OTP_ERROR_TYPE_TO_TOAST_CONFIG[errorKey];
        toast({ title, description });
        setState((prevState) => ({ ...prevState, isLoading: false, isError: true }));
      }
    };

    if (username) autoSignIn(username, otpCode);
  }, []);

  const { buttonName, buttonIcon, colorScheme } = useMemo(() => {
    const config = {
      buttonName: 'Continue',
      buttonIcon: undefined as JSX.Element | undefined,
      colorScheme: undefined as string | undefined,
    };
    if (otpCode) {
      if (isSuccess) {
        config.buttonName = 'Verified. Redirecting...';
        config.buttonIcon = <CheckCircleIcon />;
        config.colorScheme = 'green';
      } else if (isLoading) {
        config.buttonName = 'Verifying OTP...';
        config.buttonIcon = <Spinner size="sm" />;
        config.colorScheme = undefined;
      } else {
        config.buttonName = 'OTP Not Verified';
        config.buttonIcon = <WarningIcon />;
        config.colorScheme = 'red';
      }
    }

    return config;
  }, [otpCode, isSuccess, isLoading, isError]);

  return (
    <>
      <Center flexDirection={'column'} textAlign={'center'} mt={5}>
        <Center cursor={'pointer'} my={6} w={'60%'}>
          <MailOtp />
        </Center>
        <Heading fontWeight={700} size="md" mb={4}>
          Verify your Email
        </Heading>

        <Text>Please wait while we verify your account...</Text>

        <Box w="35%" maxW={100} mx="auto" borderTop="1px solid" borderColor={borderColor} />
      </Center>

      <Center data-testid="otp-form">
        <Box minW={320} mx="auto" my={4}>
          <Text fontWeight={'bold'}>Verification code</Text>

          <Box mt={2} maxW={320}>
            <OTPInput
              defaultValue={otp}
              isDisabled
              onChange={(otp) => setState((prevState) => ({ ...prevState, otp, isError: false }))}
              bg="transparent"
              isError={isError}
            />
            <Text color={'red.400'} h={4} mt={1} data-testid="otp-error-message">
              {isError && 'Could not verify OTP. Please try again!'}
            </Text>
            <CustomButton
              data-testid="submit-otp-button"
              isLoading={isLoading}
              loadingText={'Verifying OTP...'}
              w="100%"
              maxW={320}
              rounded={3}
              py={6}
              mt={1}
              isDisabled
              colorScheme={colorScheme}
              leftIcon={buttonIcon}
            >
              {buttonName}
            </CustomButton>
          </Box>
        </Box>
      </Center>

      <Center>
        <Text fontSize={'sm'} textAlign="center">
          Didn’t work?
          <Box
            data-testid="resend-otp-button"
            cursor={'pointer'}
            _hover={{ textDecoration: 'underline', cursor: isLoading ? 'not-allowed' : 'pointer' }}
            onClick={async () => {
              if (isLoading) return;
              try {
                await Auth.resendSignUp(username || '');
                toast({
                  title: 'OTP has been sent!',
                  status: 'success',
                  isClosable: true,
                });
              } catch (e) {
                const errorType = (e as any)?.name || (e as any)?.response?.name || '';
                const errorMessage = (e as any)?.message || (e as any)?.response?.data || '';

                const isUserNotInPending =
                  errorType === 'UserLambdaValidationException' &&
                  errorMessage.includes('UserPending matching query does not exist');

                let title = 'Something went wrong while resending OTP!';
                let description = 'Please contact us if this issue persists.';
                let status = 'error' as 'error' | 'info';

                if (isUserNotInPending) {
                  title = 'Verification not required!';
                  description = 'Please try logging in.';
                  status = 'info';
                }

                toast({
                  title,
                  description,
                  status,
                  duration: 5000,
                });

                if (isUserNotInPending) navigate('/login', { replace: true });
              }
            }}
            as={'span'}
            fontWeight="bold"
            color="customLinkBlue.500"
            mx={1}
          >
            Resend
          </Box>
        </Text>
      </Center>
    </>
  );
}

export default OTPVerification;

export function AccountActivationInfo() {
  const navigate = useNavigate();
  return (
    <Center flexDirection={'column'} textAlign={'center'} mt={5} data-testid="activate-account-info-screen">
      <Center cursor={'pointer'} my={6} w={'60%'}>
        <MailOtp />
      </Center>
      <Heading fontWeight={700} size="md" mb={4}>
        Activate your account
      </Heading>
      <Text textAlign={'center'}>Please click the activation link sent to your email.</Text>
      <CustomButton
        mt={10}
        data-testid="back-to-login-button"
        onClick={() => {
          navigate('/login');
        }}
      >
        Back to Login
      </CustomButton>
    </Center>
  );
}
