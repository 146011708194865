import React from 'react';
import { EditIcon } from '@chakra-ui/icons';
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useColorModeValue,
} from '@chakra-ui/react';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { EyeIcon } from '../../styles/custom-icons';
import { openCortexAppAtRoute } from './dashboard-helpers';
import { SiteData } from '../../api/api-site';
import { useAlertDrawerContext } from '../../context/context-exports';
import SiteAlertIcon from './SiteAlertIcon';

const COMMON_BUTTON_PROPS = {
  _hover: { bg: 'gray.100' },
  _dark: {
    _hover: {
      bg: 'gray.900',
    },
  },
};

const COMMON_ICON_PROPS = {
  w: 5,
  h: 5,
};

type SiteActionMenuProps = { siteData: SiteData; dataIndex: number };

const SiteActionMenu = ({ siteData, dataIndex }: SiteActionMenuProps) => {
  const navigate = useNavigate();
  const siteId = siteData.clipsal_solar_id;
  const operatingStatus = siteData.operating_status;
  const { onOpenAlertDrawer } = useAlertDrawerContext();
  const iconColor = useColorModeValue('dusk100.500', 'white');

  return (
    <Menu closeOnSelect={false} isLazy>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon as={IoEllipsisVerticalSharp} />}
        variant="unstyled"
        p={1}
        rounded={20}
        minW={7}
        h={7}
        data-testid={`site-action-menu-button-${dataIndex}`}
        {...COMMON_BUTTON_PROPS}
        _dark={{
          _hover: {
            bg: 'gray.700',
          },
        }}
      />
      <Portal>
        <MenuList zIndex={10} border="2px solid" borderColor="gray.200" py={0} data-testid="action-menu-list">
          <MenuItem
            icon={<EditIcon color={iconColor} h={5} w={5} />}
            {...COMMON_BUTTON_PROPS}
            data-testid="edit-site-button"
            onClick={() => navigate(`/site/${siteId}/start?redirect=true`)}
          >
            Edit Site
          </MenuItem>
          <MenuDivider my={0} />
          <MenuItem
            {...COMMON_BUTTON_PROPS}
            data-testid="view-alerts-button"
            icon={<SiteAlertIcon operatingStatus={operatingStatus} />}
            onClick={() => onOpenAlertDrawer(siteId)}
          >
            View Alerts
          </MenuItem>
          <MenuDivider my={0} />
          <MenuItem
            icon={<EyeIcon {...COMMON_ICON_PROPS} />}
            {...COMMON_BUTTON_PROPS}
            data-testid="view-site-in-cortex-button"
            onClick={() => openCortexAppAtRoute(siteId)}
          >
            View Site in Cortex
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default SiteActionMenu;
