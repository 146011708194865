import { ModalProps } from '../../../common/types/types';
import { ElectricalConfiguration } from '../../../api/api-site';
import React, { useState } from 'react';
import install1PhaseSetupImg from '../../../assets/images/install_1ph.svg';
import install2PhaseSetupImg from '../../../assets/images/install_2ph.svg';
import install3PhaseSetupImg from '../../../assets/images/install_3ph.svg';
import {
  Button,
  Center,
  Checkbox,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

type VoltageConfigModalProps = ModalProps & { selectedPhaseValue: ElectricalConfiguration };

export default function VoltageConfigurationTutorialModal({
  isOpen,
  onClose,
  selectedPhaseValue,
}: VoltageConfigModalProps) {
  const [isChecked, setIsChecked] = useState<boolean>(
    localStorage.getItem(`voltageConfigModalDisabled_${selectedPhaseValue}`) === 'true'
  );
  const buttonColorScheme = useColorModeValue('customBlack', 'whiteAlpha');
  const selectedPhaseValueToImage: Record<ElectricalConfiguration, { imageSrc: string; text: string }> = {
    '1ph': { imageSrc: install1PhaseSetupImg, text: 'One phase installation' },
    '2ph': { imageSrc: install2PhaseSetupImg, text: 'Two phase installation' },
    '3ph': { imageSrc: install3PhaseSetupImg, text: 'Three phase installation' },
  };

  return (
    <Modal size={'md'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid="voltage-config-tutorial-modal">
        <ModalHeader>How to wire Cortex units</ModalHeader>
        <ModalCloseButton data-testid="voltage-config-tutorial-modal-close-btn" />
        <ModalBody>
          <Center
            flexDirection={'column'}
            p={5}
            bg={'backgroundGrey.500'}
            mb={2}
            data-testid={`${selectedPhaseValue}-details`}
          >
            <Image mb={2} w={'50%'} src={selectedPhaseValueToImage[selectedPhaseValue].imageSrc} />
            <Text>{selectedPhaseValueToImage[selectedPhaseValue].text}</Text>
          </Center>

          <Checkbox
            onChange={(e) => {
              localStorage.setItem(
                `voltageConfigModalDisabled_${selectedPhaseValue}`,
                e.currentTarget.checked ? 'true' : 'false'
              );
              setIsChecked(e.currentTarget.checked);
            }}
            isChecked={isChecked}
            mb={3}
            data-testid="dont-show-again-checkbox"
          >
            Don't show me again
          </Checkbox>

          <Center>
            <Button
              data-testid="voltage-config-tutorial-done-btn"
              w={'50%'}
              rounded={20}
              color="white"
              colorScheme={buttonColorScheme}
              mr={3}
              onClick={onClose}
            >
              Done
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
