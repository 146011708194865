import React from 'react';
import pageNotFoundImg from '../../../assets/images/system_down.svg';
import { Center, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT, TOP_NAV_SPACING_AFFORDANCE } from '../../constants';
import { useSupportDrawerContext } from '../../../context/context-exports';
import CustomButton from '../CustomButton';
import { useNavigate } from 'react-router-dom';

export const RouteNotFound = () => {
  const { onOpenSupportDrawer } = useSupportDrawerContext();
  const navigate = useNavigate();

  return (
    <Flex
      height={`calc(${COMPONENT_MIN_HEIGHT} - ${TOP_NAV_SPACING_AFFORDANCE} - ${BOTTOM_NAV_HEIGHT})`}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      data-testid="page-not-found"
    >
      <Image mb={3} width={'50%'} src={pageNotFoundImg} alt="Page not found" />
      <Heading size={'lg'} textAlign={'center'} my={2}>
        Oh No! Page Not Found!
      </Heading>

      <Text size={'sm'} px={6} textAlign={'center'} color="gray.500">
        Sorry, the page you are looking for doesn't exist.
      </Text>

      <Center mt={2}>
        <CustomButton w={120} variant={'outline'} data-testid="back-button" onClick={() => navigate(-1)}>
          Go Back
        </CustomButton>

        <CustomButton ml={4} onClick={onOpenSupportDrawer} w={120} data-testid="help-button">
          Help
        </CustomButton>
      </Center>
    </Flex>
  );
};
