import { Box, Button, Center, Spinner } from '@chakra-ui/react';
import React from 'react';
import { NotificationIcon } from '../../styles/custom-icons';
import { useAlertDrawerContext } from '../../context/context-exports';

const AlertsNotificationIconButton = () => {
  const { onOpenAlertDrawer, isAlertsLoaded, tenantAlertsCount } = useAlertDrawerContext();

  return (
    <Button
      maxW={[8, 10]}
      minW={[8, 10]}
      h={[8, 10]}
      variant="ghost"
      data-testid="alerts-notification-icon-button"
      disabled={!isAlertsLoaded}
      onClick={() => onOpenAlertDrawer()}
      borderRadius={100}
    >
      <Box position={'relative'}>
        <NotificationIcon h={[5, 6]} w={[5, 6]} />
        {!isAlertsLoaded ? (
          <Center w={'100%'} h={'100%'} position="absolute" right={0} top={0} cursor="progress">
            <Spinner color={'primaryBranding.500'} size={'xs'} />
          </Center>
        ) : (
          !!tenantAlertsCount && (
            <Center
              cursor="pointer"
              position="absolute"
              right={-1}
              top={0}
              w="14px"
              h="14px"
              rounded={50}
              background="customRed.500"
              fontSize="10px"
              fontWeight="semibold"
              color="white"
            >
              {tenantAlertsCount}
            </Center>
          )
        )}
      </Box>
    </Button>
  );
};

export default AlertsNotificationIconButton;
