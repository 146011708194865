import React, { useEffect, useMemo, useState } from 'react';
import { useLogs } from './meterConfigApi';
import { Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { TablePagination } from '../../../../common/components/Table';
import { formatDateTime } from 'clipsal-cortex-utils/src/formatting/formatting';
import { utcToZonedTime } from 'date-fns-tz';

type LogTableProps = {
  meterId: string;
  timezone: string;
};

const DATA_TYPE_KEY_AND_LABELS_MAP = [
  ['timestamp', 'Timestamp'],
  ['channel', 'Channel'],
  ['attribute_changed', 'Attribute Changed'],
  ['original', 'Original'],
  ['changed_to', 'Changed To'],
  ['derms_user', 'User'],
] as const;

const LogTable = ({ meterId, timezone }: LogTableProps) => {
  const [{ pageIndex, pageSize }, setState] = useState({ pageIndex: 0, pageSize: 5 });
  const { data, isLoading, isFetching } = useLogs(meterId);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pageIndex: 0 }));
  }, [meterId]);

  const logs = useMemo(() => {
    return data.filter((_, index) => {
      const start = pageIndex * pageSize;
      const end = start + pageSize;
      return index >= start && index < end;
    });
  }, [pageIndex, pageSize, data]);

  return (
    <Skeleton isLoaded={!isLoading && !isFetching}>
      <TableContainer
        bg={'white'}
        _dark={{ bg: 'gray.900' }}
        w="100%"
        border={'1px solid'}
        borderColor={'gray.200'}
        borderRadius={8}
        data-testid="log-table"
      >
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              {DATA_TYPE_KEY_AND_LABELS_MAP.map(([, label]) => (
                <Th key={label} textAlign={'center'}>
                  {label}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {logs.map((log) => {
              return (
                <Tr cursor="pointer" _hover={{ bg: 'gray.100' }} _dark={{ _hover: { bg: 'gray.700' } }} key={log.id}>
                  {DATA_TYPE_KEY_AND_LABELS_MAP.map(([key]) => {
                    // Format the timestamp to a human-readable format
                    const value =
                      key === 'timestamp' ? formatDateTime(utcToZonedTime(new Date(log[key]), timezone)) : log[key];
                    return (
                      <Td key={key} textAlign={'center'} py={2}>
                        {value}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <TablePagination
        gotoPage={(pageIndex) => setState((prevState) => ({ ...prevState, pageIndex }))}
        previousPage={() => setState((prevState) => ({ ...prevState, pageIndex: prevState.pageIndex - 1 }))}
        nextPage={() => setState((prevState) => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }))}
        canNextPage={(pageIndex + 1) * pageSize < data.length}
        canPreviousPage={pageIndex > 0}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={Math.ceil(data.length / pageSize)}
        setPageSize={(newPageSize) => {
          // check if new pagesize outside of the bounds of the data
          const maxPageIndex = Math.ceil(data.length / newPageSize) - 1;

          setState((prevState) => ({
            ...prevState,
            pageSize: newPageSize,
            pageIndex: prevState.pageIndex > maxPageIndex ? maxPageIndex : prevState.pageIndex,
          }));
        }}
        isLoaded={!isLoading}
      />
    </Skeleton>
  );
};

export default LogTable;
