import { Box, Center, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { DateRangeType } from './DateRangeTypePicker';
import { ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { formatDate } from '../../utils/datetime-utils';
import { FIRST_YEAR_OF_DATA } from './fleet-dashboard-helpers';
import CustomDatePicker from '../../common/components/date-picker/CustomDatePicker';
import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { parseDate } from 'clipsal-cortex-utils/src/formatting/formatting';
import { format } from 'date-fns';
import { CustomDatePickerProps } from 'clipsal-cortex-ui/src/components/date-picker/CustomDatePicker';

type Props = {
  rangeType: DateRangeType;
  selectedPeriod: string;
  onChangeSelectedPeriod: (newPeriod: string) => void;
  isDisabled: boolean;
};

type Direction = 'forward' | 'back';

export default function PeriodPicker({ selectedPeriod, onChangeSelectedPeriod, rangeType, isDisabled }: Props) {
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });

  const datePickerProps: CustomDatePickerProps = useMemo(() => {
    const commonProps: CustomDatePickerProps = {
      onChange: (date: Date) => date && onChangeSelectedPeriod(formatDate(date)),
      maxDate: new Date(),
      selected: parseDate(selectedPeriod),
      inputProps: {
        border: 'none',
        fontSize: '15px',
        textAlign: 'center',
        'data-testid': `fleet-dashboard-date-input-${rangeType.toLowerCase()}`,
      },
      inputGroupProps: {
        justifyContent: 'center',
      },
      iconPlacement: 'right',
      popperPlacement: 'top-start',
    };

    const dateRangeTypeToProps = {
      [DateRangeType.All]: {
        dateFormat: 'dd/MM/yyyy',
        ...commonProps,
      },
      [DateRangeType.Day]: {
        dateFormat: 'dd/MM/yyyy',
        ...commonProps,
      },
      [DateRangeType.Week]: {
        dateFormatFn: (value: string | undefined) => {
          if (!value) return '';
          const firstDayOfWeek = getFirstDayOfWeek(new Date(value));
          const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
          const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 6));
          return `${format(firstDayOfWeek, 'dd LLL')} - ${format(lastDayOfWeek, 'dd LLL')}`;
        },
        ...commonProps,
      },
      [DateRangeType.Month]: {
        dateFormat: 'LLLL yyyy',
        showMonthYearPicker: true,
        ...commonProps,
      },
      [DateRangeType.Year]: {
        dateFormat: 'yyyy',
        minDate: new Date(FIRST_YEAR_OF_DATA),
        showYearPicker: true,
        ...commonProps,
      },
    };

    return dateRangeTypeToProps[rangeType];
  }, [rangeType, selectedPeriod]);

  const directionToFunction: Record<Direction, (a: number, b: number) => number> = {
    forward: (a: number, b: number) => a + b,
    back: (a: number, b: number) => a - b,
  };

  function handleMoveByDay(direction: Direction) {
    const fn = directionToFunction[direction];
    const selectedDate = new Date(selectedPeriod);
    selectedDate.setDate(fn(selectedDate.getDate(), 1));
    const selectedDateFormatted = formatDate(selectedDate);

    onChangeSelectedPeriod(selectedDateFormatted);
  }

  function handleMoveByWeek(direction: Direction) {
    const fn = directionToFunction[direction];
    const selectedDate = new Date(selectedPeriod);
    selectedDate.setDate(fn(selectedDate.getDate(), 7));
    const selectedDateFormatted = formatDate(selectedDate);
    onChangeSelectedPeriod(selectedDateFormatted);
  }

  function handleMoveByMonth(direction: Direction) {
    const fn = directionToFunction[direction];
    const selectedDate = new Date(selectedPeriod);
    selectedDate.setMonth(fn(selectedDate.getMonth(), 1));
    onChangeSelectedPeriod(formatDate(selectedDate));
  }

  function handleMoveByYear(direction: Direction) {
    const fn = directionToFunction[direction];
    const selectedDate = new Date(selectedPeriod);
    selectedDate.setFullYear(fn(selectedDate.getFullYear(), 1));
    onChangeSelectedPeriod(formatDate(selectedDate));
  }

  function handleMove(direction: Direction) {
    const rangeTypeToCaller: Record<DateRangeType, (direction: Direction) => void> = {
      [DateRangeType.Day]: handleMoveByDay,
      [DateRangeType.Week]: handleMoveByWeek,
      [DateRangeType.Month]: handleMoveByMonth,
      [DateRangeType.Year]: handleMoveByYear,
      [DateRangeType.All]: () => undefined,
    };
    const fn = rangeTypeToCaller[rangeType];
    fn(direction);
  }

  function checkIfMoveBackButtonIsDisabled() {
    if (rangeType === DateRangeType.Year && selectedPeriod === FIRST_YEAR_OF_DATA.toString()) return true;
    return rangeType === DateRangeType.All || isDisabled;
  }

  function checkIfMoveForwardButtonIsDisabled() {
    if (rangeType === DateRangeType.Week) {
      // Increment the selected period by 7 days, and check if that date is after today.
      // If so, the user can't move forward.
      const selectedDate = new Date(selectedPeriod);
      selectedDate.setDate(selectedDate.getDate() + 7);
      selectedDate.setHours(0, 0, 0, 0);
      const today = new Date();

      if (selectedDate > today) return true;
    }

    return rangeType === DateRangeType.All || isDisabled || selectedPeriod === formatDate(new Date());
  }

  return (
    <Flex py={[2, 2, 0]} align={'center'} justify={['space-between', 'space-between', 'center']} w="100%">
      <IconButton
        data-testid={'fleet-dashboard-prev-date-chevron'}
        size={isMobileViewport ? 'sm' : 'md'}
        rounded={20}
        mr={2}
        variant={'ghost'}
        aria-label="Previous"
        icon={<ChevronLeftIcon h={5} w={5} />}
        isDisabled={checkIfMoveBackButtonIsDisabled()}
        onClick={() => handleMove('back')}
      />
      {rangeType === DateRangeType.All ? (
        <Center w="100%" h={10} minW={150}>
          <Box>All</Box>
        </Center>
      ) : (
        <CustomDatePicker {...datePickerProps} />
      )}
      <Flex>
        <IconButton
          data-testid={'fleet-dashboard-next-date-chevron'}
          size={isMobileViewport ? 'sm' : 'md'}
          rounded={20}
          ml={2}
          variant={'ghost'}
          aria-label="Next"
          icon={<ChevronRightIcon h={5} w={5} />}
          onClick={() => handleMove('forward')}
          isDisabled={checkIfMoveForwardButtonIsDisabled()}
        />
        {isMobileViewport && (
          <IconButton
            data-testid={'fleet-dashboard-current-date-mobile-arrow'}
            size={isMobileViewport ? 'sm' : 'md'}
            rounded={20}
            ml={2}
            variant={'ghost'}
            aria-label="Open date picker"
            icon={<ArrowRightIcon h={3} w={3} />}
            isDisabled={rangeType === DateRangeType.All || isDisabled || checkIfMoveForwardButtonIsDisabled()}
            onClick={() => onChangeSelectedPeriod(formatDate(new Date()))}
          />
        )}
      </Flex>
    </Flex>
  );
}
