import { BoxProps, Button, ButtonProps, Flex } from '@chakra-ui/react';
import React from 'react';

const CustomButton = ({
  colorScheme = 'primaryButton',
  type = 'submit',
  my = 5,
  py = 6,
  px = 6,
  rounded = 50,
  borderColor = 'slate.500',
  containerProps,
  ...props
}: ButtonProps & { containerProps?: BoxProps }) => {
  const { variant, w } = props;
  const justify = variant ? 'flex-start' : 'center';
  const buttonWidth = variant ? ['75%', '75%', '40%'] : w;

  return (
    <Flex align="center" justify={justify} my={my} w={'100%'} {...containerProps}>
      <Button
        px={px}
        py={py}
        type={type}
        colorScheme={colorScheme}
        rounded={rounded}
        borderColor={borderColor}
        w={buttonWidth}
        {...props}
      >
        {props.children}
      </Button>
    </Flex>
  );
};

export default CustomButton;
