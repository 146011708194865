/**
 * Implemented in a separate file due to the inability to mix multiple exports from `index.tsx`
 * with `react-testing-library`.
 */

export type EnvironmentType = 'development' | 'production' | 'test';
export type ShorthandEnvironmentType = 'DEV' | 'PROD' | 'TEST';

export let ENV_TYPE = import.meta.env['MODE'] === 'production' ? 'PROD' : 'DEV';

// if (window['Cypress' as any]) {
//   ENV_TYPE = 'PROD';
// }

// Check local storage for a different env setting
const fromStorage = localStorage.getItem('cortexEnvType');
if (!!fromStorage) {
  ENV_TYPE = fromStorage;
}

export const ENV_TYPE_TO_SHORTHAND: Record<EnvironmentType, ShorthandEnvironmentType> = {
  development: 'DEV',
  production: 'PROD',
  test: 'TEST',
};
