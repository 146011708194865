import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { Box, Slide, SlideDirection, useBreakpointValue } from '@chakra-ui/react';
import { BlockerContext } from '../../context/BlockerContextProvider';

interface Props {
  transitionTimeMs: number;
  direction: SlideDirection;
}

/*
 * SlideBiDirectional is a component that animates the transition of its children.
 *
 * @param transitionTimeMs - The time in milliseconds for the transition to complete.
 * @param direction - The direction of the slide animation.
 */
export const SlideBiDirectional = ({
  transitionTimeMs,
  children,
  direction: externalDirection,
}: PropsWithChildren<Props>) => {
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [direction, setDirection] = useState<SlideDirection>('right');
  const { blocker, unBlock, disabled } = useContext(BlockerContext);
  const wrapRef = useRef<HTMLDivElement>(null);
  const duration = transitionTimeMs / 1000 / 2;

  useEffect(() => {
    if (!disabled) {
      setDirection(externalDirection);
      setShow(false);
      const timerOut = setTimeout(() => {
        setOpacity(0);
        setShow(true);
        setDirection(externalDirection === 'right' ? 'left' : 'right');
        if (unBlock && blocker) unBlock(blocker);
        setShow(false);
      }, transitionTimeMs / 2);
      const timerIn = setTimeout(() => {
        setOpacity(1);
        setShow(true);
      }, transitionTimeMs);
      return () => {
        clearTimeout(timerOut);
        clearTimeout(timerIn);
      };
    }
  }, [blocker?.state, disabled]);

  useEffect(() => {
    if (wrapRef.current) {
      wrapRef.current.scrollTop = 0;
    }
  }, [blocker?.state]);

  return (
    <Slide
      direction={direction}
      in={show}
      style={{ height: 'calc(100vh - 2px)', position: 'absolute' }}
      transition={{ enter: { duration }, exit: { duration } }}
    >
      <Box position="absolute" left={isMobileViewport ? 0 : 250} right="0" top="0" bottom="0" opacity={opacity}>
        <Box height="100%" overflowY="auto" ref={wrapRef}>
          {children}
        </Box>
      </Box>
    </Slide>
  );
};
