import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useAppDispatch } from '../../../app/hooks';
import { fetchModelsByManufacturerId, selectModels } from './systemDetailsSlice';
import { useSelector } from 'react-redux';
import { SystemDetailsFormData } from './system-details-form-types';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { InstalledDevice } from '../../../api/api-device';
import { selectSite } from '../siteSlice';
import { post } from '../../../api/api-helpers';
import { DeviceModelRequest } from '../../../api/api-device-model-request';
import CustomButton from '../../../common/components/CustomButton';

type MissingModelType = 'SOLAR_MODULE' | 'BATTERY_PACK' | 'INVERTER' | 'EV_CHARGER';

type MissingModelDrawerProps = {
  modelType: MissingModelType;
  isOpen: boolean;
  onClose(): void;
  setValue: UseFormSetValue<SystemDetailsFormData>;
  index: number;
};

const MODEL_TYPE_TO_TITLE = {
  SOLAR_MODULE: 'Solar Module',
  BATTERY_PACK: 'Battery',
  INVERTER: 'Inverter',
  EV_CHARGER: 'EV Charger',
};

export function MissingModelDrawer({ modelType, isOpen, onClose, setValue, index }: MissingModelDrawerProps) {
  return (
    <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Request New {MODEL_TYPE_TO_TITLE[modelType]} Model</DrawerHeader>
        <DrawerBody>
          <MissingModelForm onClose={onClose} index={index} setValue={setValue} modelType={modelType} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

type MissingModelFormData = {
  model: string;
  manufacturer: string;
};

const schema = yup.object().shape({
  manufacturer: yup.string().required(),
  model: yup.string().required(),
});

type MissingModelFormProps = {
  modelType: MissingModelType;
  setValue: UseFormSetValue<SystemDetailsFormData>;
  index: number;
  onClose(): void;
};

const CLIPSAL_SOLAR_MANUFACTURER_ID = 12;

function MissingModelForm({ modelType, setValue, index, onClose }: MissingModelFormProps) {
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<MissingModelFormData>({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const allModels = useSelector(selectModels);
  const models = allModels[modelType];
  // Retrieve the generic device
  const genericModel = models?.['12']?.find((m) => m.model.toLowerCase().includes('generic'));
  const site = useSelector(selectSite);
  const toast = useToast();

  useEffect(() => {
    async function fetchAPI() {
      if (!genericModel) {
        await dispatch(
          fetchModelsByManufacturerId({
            manufacturerId: CLIPSAL_SOLAR_MANUFACTURER_ID,
            deviceType: modelType,
          })
        );
      }
    }

    fetchAPI();
  }, [dispatch, modelType, genericModel]);

  async function handleSubmit(values: MissingModelFormData) {
    await handleSaveSiteDevice(values);
  }

  async function handleSaveSiteDevice(values: MissingModelFormData) {
    if (!genericModel) {
      toast({
        title: 'Something went wrong saving the missing device (no generic model available)',
        description: 'Try again. If this persists, please contact support.',
        status: 'error',
        isClosable: true,
      });
      onClose();
      return;
    }

    const genericModelToSave: InstalledDevice = {
      api_key: '',
      clipsal_solar_id: site.clipsal_solar_id,
      serial_number: '',
      meta_data: genericModel,
      site_identifier: '',
      quantity: 1,
      device_metadata_id: genericModel.device_metadata_id,
    };

    try {
      const [installedDevice] = await post<[InstalledDevice]>(`/fleet/sites/${site.clipsal_solar_id}/devices`, [
        genericModelToSave,
      ]);

      const deviceModelRequest: DeviceModelRequest = {
        model: values.model,
        manufacturer: values.manufacturer,
        installed_device_id: installedDevice.row_id as number,
      };

      await post<DeviceModelRequest>(`/fleet/unknown_device`, deviceModelRequest);

      // Different device types will have different paths to update
      const typeToPath: Record<'BATTERY_PACK' | 'INVERTER' | 'EV_CHARGER', { path: string; idProperty: string }> = {
        INVERTER: { path: `inverters.${index}`, idProperty: 'inverterId' },
        BATTERY_PACK: { path: `batteries.${index}`, idProperty: 'deviceId' },
        EV_CHARGER: { path: `evChargers.${index}`, idProperty: 'deviceId' },
      };

      // This function is called only for these types, so asserting is safe.
      const { path, idProperty } = typeToPath[modelType as 'BATTERY_PACK' | 'INVERTER' | 'EV_CHARGER'];

      setValue(`${path}.${idProperty}` as any, installedDevice.row_id);
      setValue(`${path}.manufacturer` as any, CLIPSAL_SOLAR_MANUFACTURER_ID, { shouldValidate: true });
      setValue(`${path}.model` as any, genericModel.device_metadata_id, { shouldValidate: true });

      toast({
        title: 'Device request sent',
        status: 'success',
        isClosable: true,
      });

      onClose();
    } catch (e) {
      console.error(e);

      toast({
        title: 'Error submitting device model request',
        description: 'Try again. If this persists, please contact support.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  return (
    <Center data-testid="missing-model-drawer-contents">
      <Box w={['100%', '80%', '50%']}>
        <Alert my={2} status="info">
          <AlertIcon />
          <Text data-testid="missing-model-drawer-text" fontSize={'sm'}>
            If this customer's device model does not exist in the provided options, you can submit a request to add it
            to our system. Your request will be submitted to our Energy Adviser team, who will review it and update this
            customer's device when the new model becomes available in our system.
          </Text>
        </Alert>

        <FormControl my={3} isInvalid={!!errors.manufacturer} id="missing_manufacturer">
          <FormLabel>Manufacturer</FormLabel>
          <Input
            data-testid="missing-manufacturer-input"
            placeholder={'e.g. Tesla'}
            {...register('manufacturer')}
            type="text"
          />
          <FormErrorMessage>Manufacturer is required.</FormErrorMessage>
        </FormControl>

        <FormControl my={3} isInvalid={!!errors.model} id="missing_model">
          <FormLabel>Model</FormLabel>
          <Input data-testid="missing-model-input" placeholder={'e.g. Power Wall'} {...register('model')} type="text" />
          <FormErrorMessage>Model is required.</FormErrorMessage>
        </FormControl>

        <CustomButton data-testid="missing-model-manufacturer-form-submit" onClick={handleFormSubmit(handleSubmit)}>
          Submit
        </CustomButton>
      </Box>
    </Center>
  );
}
