import {
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import lowEnvironmentalImpact from '../../../assets/images/environmental_impact_low.svg';
import mediumEnvironmentalImpact from '../../../assets/images/environmental_impact_medium.svg';
import highEnvironmentalImpact from '../../../assets/images/environmental_impact_high.svg';
import negativeEnvironmentalImpact from '../../../assets/images/environmental_impact_negative.svg';
import noDataEnvironmentalImpact from '../../../assets/images/environmental_impact_no_data.svg';
import { CustomInfoIcon, PieIcon } from '../../../styles/custom-icons';
import { get } from '../../../api/api-helpers';
import { roundNumber } from 'clipsal-cortex-utils/src/calculations/number-utils';

const getImageSource = (treesPlanted: number) => {
  if (treesPlanted >= 50) return highEnvironmentalImpact;
  if (treesPlanted >= 10) return mediumEnvironmentalImpact;
  if (treesPlanted >= 0) return lowEnvironmentalImpact;
  return negativeEnvironmentalImpact;
};

type EnvironmentalImpact = { emitted: number; net: number; offset: number; tree_eq: number };

const EnvironmentalImpactWidget = ({ selectedTenantId }: { selectedTenantId: number }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [{ treesPlanted, netImpact, emittedCo2, offsetCo2, isLoading }, setState] = useState({
    treesPlanted: 0,
    netImpact: 0,
    emittedCo2: 0,
    offsetCo2: 0,
    isLoading: true,
  });
  const isMobileViewport = useBreakpointValue({
    base: true,
    lg: false,
  });
  const isPositiveImpact = treesPlanted >= 0;

  // if both emit and offset are 0 means the tenant does not have any data yet
  const hasNoDataYet = !emittedCo2 && !offsetCo2;
  const imgSource = hasNoDataYet ? noDataEnvironmentalImpact : getImageSource(treesPlanted);

  useEffect(() => {
    const fetchEnvironmentalImpact = async () => {
      try {
        const { emitted, net, offset, tree_eq } = await get<EnvironmentalImpact>(
          `/fleet/tenants/${selectedTenantId}/fleet_emissions`
        );
        setState((prevState) => ({
          ...prevState,
          treesPlanted: roundNumber(tree_eq, 1),
          netImpact: roundNumber(net, 1),
          emittedCo2: roundNumber(emitted, 1),
          offsetCo2: roundNumber(offset, 1),
          isLoading: false,
        }));
      } catch (error) {
        // since this widget loads only for macquarie team,
        // 422 error will not come up. So, this is either
        // backend data processing issue or network error.
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };

    fetchEnvironmentalImpact();
  }, [selectedTenantId]);

  return (
    <>
      <>
        <Flex
          position={'absolute'}
          width="53%"
          height="70%"
          left="7px"
          top="-2px"
          background={isPositiveImpact || hasNoDataYet ? 'rgba(142, 210, 206, 0.3)' : 'rgba(192, 216, 231, 0.3)'}
          filter={'blur(100px)'}
        />
        <Flex
          position={'absolute'}
          width="42%"
          height="50%"
          bottom="-5px"
          right="5px"
          background={isPositiveImpact || hasNoDataYet ? 'rgba(255, 198, 52, 0.3)' : 'rgba(171, 186, 195, 0.4)'}
          filter={'blur(100px)'}
        />
        <Flex
          position={'absolute'}
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={1}
          flexDirection="column"
          px={4}
        >
          <Flex mt={4} align="center">
            <Heading size={'md'}>Net Environmental Impact</Heading>
            <Popover placement={isMobileViewport ? 'top' : 'right'} isOpen={isOpen} onClose={onClose}>
              <Button variant="ghost" ml={2} p={0} px={2} onClick={onToggle}>
                <PopoverTrigger>
                  <CustomInfoIcon w={6} h={6} color="customLinkBlue.500" />
                </PopoverTrigger>
              </Button>
              <Portal>
                <PopoverContent borderRadius={8} shadow="md">
                  <PopoverArrow />
                  <PopoverBody>
                    <Flex direction="column" align="center">
                      <Flex>
                        <PieIcon w={5} h={5} mr={1} />
                        <Text fontSize="17px" fontWeight={'semibold'}>
                          How is environmental impact calculated?
                        </Text>
                      </Flex>
                      <Text my={4}>
                        The number shown is the quantity of CO2 emissions that would have been generated by an
                        equivalent fossil fuel system. This number depends on the systems’ location and the emissions
                        level in each country.
                      </Text>
                      <Text fontSize="13px">
                        Net Value = [kWh consumed x CO2 Factor ] - [kWh produced x CO2 Factor]
                      </Text>
                      <Button
                        ml={'auto'}
                        variant={'ghost'}
                        color="customLinkBlue.500"
                        fontSize="14px"
                        mt={1}
                        onClick={onClose}
                      >
                        Got it!
                      </Button>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>

          {isLoading ? (
            <Skeleton w="100%" h="75%" rounded={6} mt={2} />
          ) : (
            <>
              {hasNoDataYet ? (
                <Center height={'100%'} flexDirection="column">
                  <Heading size={'md'}>No Data Available</Heading>
                  <Text textAlign="center" mt={2} color="dusk100.400">
                    No Solar production or load consumption data available to calculate emissions.
                  </Text>
                </Center>
              ) : (
                <Flex width="100%" mt="auto" height={'80%'}>
                  <Flex direction={'column'} mt="auto">
                    <Flex alignItems={'flex-end'}>
                      <Text fontWeight={'bold'} fontSize="34px" lineHeight={'34px'}>
                        {(isPositiveImpact ? 1 : -1) * netImpact}
                      </Text>
                      <Text ml={1}>{(isPositiveImpact ? 1 : -1) * netImpact === 1 ? 'Ton' : 'Tonnes'}</Text>
                    </Flex>
                    <Flex alignItems={'flex-end'} mt={4}>
                      <Text fontSize={'20px'} lineHeight={'20px'}>
                        all-time net CO
                      </Text>
                      <Text fontSize={'14px'} lineHeight={'14px'}>
                        2
                      </Text>
                      <Text fontSize={'20px'} lineHeight={'20px'} ml={1}>
                        {isPositiveImpact ? 'saved' : 'emitted'}
                      </Text>
                    </Flex>
                    {isPositiveImpact && (
                      <Text fontSize={'16px'} color="dusk100.400" mt={2}>
                        {`equivalent to ${treesPlanted} ${treesPlanted === 1 ? 'tree' : 'trees'} planted`}
                      </Text>
                    )}

                    <Flex alignItems={'flex-end'} mt={isPositiveImpact ? 8 : 16}>
                      <Text fontSize={'16px'} lineHeight={'16px'}>
                        {`${emittedCo2} ${emittedCo2 === 1 ? 'Ton' : 'Tonnes'} CO`}
                      </Text>
                      <Text fontSize={'10px'} lineHeight={'10px'}>
                        2
                      </Text>
                      <Text fontSize={'16px'} lineHeight={'16px'} ml={1} color="customPink.500">
                        Emitted
                      </Text>
                    </Flex>
                    <Flex alignItems={'flex-end'} mb={8} mt={[2, 4]}>
                      <Text fontSize={'16px'} lineHeight={'16px'}>
                        {`${offsetCo2} ${offsetCo2 === 1 ? 'Ton' : 'Tonnes'} CO`}
                      </Text>
                      <Text fontSize={'10px'} lineHeight={'10px'}>
                        2
                      </Text>
                      <Text fontSize={'16px'} lineHeight={'16px'} ml={1} color="customGreen.500">
                        Offset
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              )}
              <Flex width={hasNoDataYet ? '100%' : '50%'} position={'absolute'} top={'20%'} right={0}>
                <Image
                  src={imgSource}
                  objectFit="contain"
                  marginBottom="auto"
                  marginLeft="auto"
                  marginRight={hasNoDataYet ? 'auto' : 'initial'}
                  width={isPositiveImpact || hasNoDataYet ? '90%' : '70%'}
                  maxW={hasNoDataYet ? '280px' : '220px'}
                />
              </Flex>
            </>
          )}
        </Flex>
      </>
    </>
  );
};

export default EnvironmentalImpactWidget;
