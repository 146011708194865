import { OriginalWattwatchersMeter, WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import * as yup from 'yup';

const channelSchema = yup.object().shape({
  id: yup.string().required().label('Channel ID'),
  label: yup.string().required().label('Channel Label'),
  categoryLabel: yup.string().required().label('Category Label'),
  polarity: yup.string().required().label('Polarity'),
  voltageReference: yup.string().required().label('Voltage Reference'),
  ctRating: yup.number().required().label('CT Rating'),
  categoryId: yup.number().required().label('Category ID'),
});

const switchSchema = yup.object().shape({
  id: yup.string().required().label('Switch ID'),
  label: yup.string().required().label('Switch Label'),
  categoryId: yup.number().required().label('Category ID'),
  categoryLabel: yup.string().required().label('Category Label'),
  contactorType: yup.string().required().label('Contactor Type'),
  openStateLabel: yup.string().required().label('Open State Label'),
  closedStateLabel: yup.string().required().label('Closed State Label'),
  state: yup.string().required().label('State'),
});

export const meterFormSchema = yup.object().shape({
  channels: yup.array().of(channelSchema).label('Channels'),
  switches: yup.array().of(switchSchema).label('Switches'),
});

export const mapWWChannelsToClipsalCircuits = (
  channels: OriginalWattwatchersMeter['channels'],
  circuits: WattwatchersMeter['circuits']
): WattwatchersMeter['circuits'] => {
  return circuits.map((circuit) => {
    const channel = channels.find((ch) => ch.id === circuit.ww_circuit_id);
    if (channel) {
      return {
        ...circuit,
        clipsal_monitors: channel.categoryLabel,
        circuit_name: channel.label,
        ct_rating: channel.ctRating,
        polarity: channel.polarity,
        voltage_reference: channel.voltageReference,
      };
    }
    return circuit;
  });
};
