import React from 'react';
import { Children } from '../common/types/types';
import SupportDrawerContextProvider from './SupportDrawerContext';
import AlertDrawerContextProvider from '../features/dashboard/alert-drawer/AlertDrawerContextProvider';

const CombinedContextProvider = ({ children }: Children) => {
  return (
    <SupportDrawerContextProvider>
      <AlertDrawerContextProvider>{children}</AlertDrawerContextProvider>
    </SupportDrawerContextProvider>
  );
};

export default CombinedContextProvider;
