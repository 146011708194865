import React, { PropsWithChildren, useRef, useState } from 'react';
import {
  Flex,
  Box,
  useTheme,
  Heading,
  Button,
  useToast,
  Divider,
  ButtonProps,
  LightMode,
  Portal,
} from '@chakra-ui/react';
import SelfPoweredWidget from './SelfPoweredWidget';
// import BatteryBackupWidget from './BatteryBackupWidget';
import EstimatedBillsWidget from './EstimatedBillsWidget';
import AverageDailyEnergyChart from './AverageDailyEnergyChart';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import SiteStats from './SiteStats';
import { BatterySimulation, LoadProfile } from '../../api/api-battery-upsell';
import { SiteData } from '../../api/api-site';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { FileOpener } from '@capacitor-community/file-opener';

type PrintPdfButtonProps = {
  isLoaded: boolean;
  simulations: BatterySimulation[];
  loadProfiles: Record<string, LoadProfile[]>;
  site: SiteData;
} & ButtonProps;

const PrintPdfButton = ({
  isLoaded,
  simulations,
  loadProfiles,
  site,
  children = 'Export as PDF',
  ...buttonProps
}: PropsWithChildren<PrintPdfButtonProps>) => {
  const theme = useTheme();
  const backgroundColor = theme.colors.customBodyBackground[500];
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({ isClosable: true });
  const componentRef = useRef<HTMLDivElement | null>(null);

  const onDownloadPDF = async () => {
    setIsLoading(true);
    const elementToPrint = componentRef.current;
    if (!elementToPrint) return;

    try {
      const pdf = new jsPDF('portrait', 'pt', 'a4');
      const data = await html2canvas(elementToPrint, { backgroundColor });
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // set background for pdf
      pdf.setFillColor(backgroundColor);
      pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');
      const img = data.toDataURL('image/png');
      const imgProperties = pdf.getImageProperties(img);
      const imgHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      // print into multiple pages if the image is too tall
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pdfHeight;

      // Enable FAST compression to reduce pdf file size
      // File size is reduced from ~60MB to ~0.5MB
      pdf.addImage(img, 'PNG', 0, position, pdfWidth, imgHeight, undefined, 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        // set background for page
        pdf.setFillColor(backgroundColor);
        pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');
        pdf.addImage(img, 'PNG', 0, position, pdfWidth, imgHeight, undefined, 'FAST');
        heightLeft -= pdfHeight;
      }

      // save pdf
      const { site_name } = site;
      const pdfFilename = `${site_name} - Battery Upsell Report.pdf`;

      const isNativePlatform = Capacitor.isNativePlatform();

      // Capacitor still does not support saving files on web
      // So use native file system to save and open the file
      if (isNativePlatform) {
        const base64Pdf = pdf.output('datauristring');
        const fileURI = await Filesystem.writeFile({
          path: pdfFilename,
          data: base64Pdf,
          directory: Directory.Data,
        });
        await FileOpener.open({ filePath: fileURI.uri });
      } else {
        pdf.save(pdfFilename);
      }
    } catch (error) {
      toast({
        title: 'Something went wrong creating PDF.',
        description: 'Please try again or contact support!',
        status: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <Box>
      {/* Lock PDF to light mode for consistent prints */}
      <LightMode>
        <Portal>
          {/* Leaving this rendered to avoid race condition in small devices*/}
          <Flex position={'absolute'} opacity={0} pointerEvents={'none'} left={-15000} top={-15000}>
            <Flex direction={'column'} ref={componentRef} width={1150} bg={backgroundColor} p={4} className="pdfFix">
              <Heading size={'xl'} color="black">
                Battery Upsell Report
              </Heading>
              <Heading size={'md'} my={4} color="black">
                {site?.site_name} ({site?.street_address})
              </Heading>
              <Box>
                {simulations.map((simulation, index) => {
                  const isFirstSimulation = index === 0;
                  const isOnlySimulation = simulations.length === 1;
                  const isLastSimulation = index === simulations.length - 1;
                  const spaceAtBottom = isLastSimulation ? 0 : 590;
                  const loadProfile = loadProfiles[simulation.battery_simulation_id.toString()];
                  return (
                    <Box
                      key={`pdf - simulation - ${index}`}
                      mb={isFirstSimulation && !isOnlySimulation ? 470 : spaceAtBottom}
                    >
                      {isFirstSimulation && <Divider bg="black" />}
                      <Heading size={'md'} fontWeight={600} mt={4} mb={10} color="black">
                        Battery - {simulation.battery_model}
                      </Heading>
                      <Flex w="100%" my={4} columnGap={4} rowGap={4} wrap={'wrap'}>
                        <SiteStats {...{ isMobileViewport: false, simulation }} isRenderingPdf />
                      </Flex>
                      <Flex width="100%" flexDirection={'column'} px={0}>
                        <Flex width={'100%'}>
                          <AverageDailyEnergyChart
                            {...{ simulation, loadProfile, isMobileViewport: false, isRenderingPdf: true }}
                          />
                        </Flex>
                        <Flex width={'100%'} pl={0} h={400} mt={4}>
                          <SelfPoweredWidget {...{ isMobileViewport: false, simulation }} hideShadow />
                          {/* <BatteryBackupWidget isMobileViewport={false} hideShadow adjustTextMargins /> */}
                          <EstimatedBillsWidget
                            {...{ isMobileViewport: false, dataLabelOffset: -8, simulation }}
                            hideShadow
                          />
                        </Flex>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>
            </Flex>
          </Flex>
        </Portal>
      </LightMode>

      <Button
        onClick={onDownloadPDF}
        background={'white'}
        color="primaryBranding.600"
        _hover={{ bg: 'whiteAlpha.400' }}
        _dark={{ bg: 'whiteAlpha.300', color: 'primaryBranding.500' }}
        shadow={'md'}
        rounded={10}
        isDisabled={!isLoaded}
        isLoading={isLoading}
        loadingText="Printing..."
        data-testid="print-pdf-button"
        {...buttonProps}
      >
        {children}
      </Button>
    </Box>
  );
};

export default PrintPdfButton;
