import { ENV_TYPE } from '../../../../env-type';
import { CircuitTestStatus, TestTypeV2 } from './test-types';

export type CircuitTestResultData = {
  polarity_test_status?: CircuitTestStatus;
  power_factor_test_status?: CircuitTestStatus;
  power_factor: number;
  current_a: number;
  circuit_id: string; // WW circuit id
  power_kw: number;
  id: number;
  clipsal_monitors: string; // Clipsal circuit id
};

type TestTypeResult = {
  [TestTypeV2.Polarity]: {
    circuits: CircuitTestResultData[];
    status: CircuitTestStatus;
  };
  [TestTypeV2.PowerFactor]: {
    circuits: CircuitTestResultData[];
    status: CircuitTestStatus;
  };
  last_updated_utc: number;
  status: CircuitTestStatus;
};

export type ApplianceLoadResult = {
  appliance_id: number;
  appliance_type: string;
} & TestTypeResult;

export type WebsocketStatusResponseV2 = {
  expires_at: number; // Unix timestamp
  clipsal_solar_id: number;
  grid: TestTypeResult;
  loads?: ApplianceLoadResult[];
  solar?: TestTypeResult;
  hybrid?: TestTypeResult;
  battery?: TestTypeResult;
};

export type SourceType = 'grid' | 'solar' | 'loads' | 'hybrid' | 'battery';

export type WebSocketTestType = 'GRID' | 'SOLAR' | 'APPLIANCE' | 'HYBRID' | 'BATTERY';

export type WebSocketTestAction = 'runTests' | 'subscribeSite' | 'deleteTest';

export type WebSocketMessage = {
  action: WebSocketTestAction;
  test_types?: WebSocketTestType[];
  clipsal_solar_id: number;
  appliance_ids?: number[];
};

export const WEBSOCKET_ENDPOINT = import.meta.env[`VITE_WEBSOCKET_${ENV_TYPE}_API_URL`] as string;
