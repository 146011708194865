import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { UserData } from '../../api/api-user';
import { get } from '../../api/api-helpers';
import * as Sentry from '@sentry/react';
import * as amplitude from '@amplitude/analytics-browser';
import { IS_NOT_DEVELOPMENT, IS_PRODUCTION_BUILD, IS_RUNNING_CYPRESS_TESTS } from '../../common/constants';
import { Capacitor } from '@capacitor/core';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { setupAmplitude, setupGA, setupLogRocket } from './user-helpers';

export type User = {
  isLoggedIn: boolean;
  token: string;
  accessToken: string;
  cognitoUser: CognitoUser | null;
  loginError: string | null;
  tempPassword: string;
  hasFetchedTempUser: boolean;
  hasFetchedUserDetails: boolean;
} & UserData;

export const checkUserLoggedIn = createAsyncThunk('user/checkUserLoggedIn', async () => {
  let session: CognitoUserSession;
  let isLoggedIn = false;
  let idToken = '';
  let accessToken = '';

  try {
    session = await Auth.currentSession();
    isLoggedIn = true;
    idToken = session.getIdToken().getJwtToken();
    accessToken = session.getAccessToken().getJwtToken();
  } catch (error) {
    console.error(error);
  }

  // Return the successfully authorized user's token.
  return {
    ...initialState,
    isLoggedIn,
    accessToken,
    token: idToken,
  };
});

/**
 * Fetches user details from the `User` end-point.
 */
export const fetchUserDetails = createAsyncThunk<User, void, { state: RootState }>(
  'user/fetchUserDetails',
  async (_, { getState }) => {
    let userData: UserData | null = null;
    const { user } = getState();

    let loginError: string | null = null;

    try {
      userData = await get<UserData>('/fleet/current_user');
    } catch (e) {
      const errorStatus = (e as any)?.response?.status;
      const errorType = (e as any)?.response?.data?.type;
      if (errorStatus === 401 && errorType === 'USER_NOT_FOUND') {
        loginError = errorType;
      } else {
        loginError = 'FETCH_USER_FAILED';
      }
    }

    // Only send events to sentry in staging and production environments
    if (import.meta.env['MODE'] !== 'development' && userData?.user_id && !IS_RUNNING_CYPRESS_TESTS) {
      Sentry.setUser({ id: userData?.user_id.toString() });
    }

    if (userData && userData?.user_id) {
      if (IS_PRODUCTION_BUILD) {
        // ATT is required for GA
        if (Capacitor.getPlatform() === 'ios') {
          const response = await AppTrackingTransparency.requestPermission();
          if (response.status === 'authorized') {
            setupGA(userData);
          }
        } else {
          setupGA(userData);
        }
      }

      // ATT shouldn't be required for LR/Amplitude
      if (IS_NOT_DEVELOPMENT && userData.role !== 'SUPER_ADMIN') {
        setupAmplitude(userData);
        setupLogRocket(userData);
      }
    }

    return {
      ...user,
      ...userData,
      loginError,
    };
  }
);

export type UserReducers = {
  addUserDetails: (state: User, action: PayloadAction<Partial<User>>) => User;
  logOut: (state: User, action: PayloadAction<void>) => User;
};

export const initialState: User = {
  isLoggedIn: false,
  cognitoUser: null,
  token: '',
  accessToken: '',
  user_email: '',
  user_name: '',
  user_first_name: '',
  user_full_name: '',
  user_last_name: '',
  user_phone: '',
  user_id: 0,
  role: '',
  tenant: {
    tenant_id: 0,
    tenant_name: '',
    address: '',
    city: '',
    country: '',
    logo_url: '',
    state: '',
    zipcode: '',
  },
  tempPassword: '',
  hasFetchedTempUser: false,
  hasFetchedUserDetails: false,
  loginError: null,
  tenant_id: 0,
};

export const userSlice = createSlice<User, UserReducers>({
  name: 'user',
  initialState,
  reducers: {
    addUserDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    logOut: () => {
      if (IS_PRODUCTION_BUILD) amplitude.reset();
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUserLoggedIn.fulfilled, (state, action) => {
        return {
          ...state,
          isLoggedIn: action.payload.isLoggedIn,
          token: action.payload.token,
          accessToken: action.payload.accessToken,
        };
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          hasFetchedUserDetails: true,
        };
      });
  },
});

export const { logOut, addUserDetails } = userSlice.actions;

export const selectUser = (state: RootState): User => {
  return state.user;
};

export default userSlice.reducer;
