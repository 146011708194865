import React from 'react';
import { Center } from '@chakra-ui/layout';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import {
  BackgroundProps,
  BorderProps,
  BoxProps,
  SpaceProps,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { COMPONENT_MIN_HEIGHT, TOP_NAV_HEIGHT, WIZARD_HEIGHT } from '../../common/constants';

interface AuxProps {
  children: React.ReactNode;
  backgroundColor?: BackgroundProps['bg'];
  p?: SpaceProps['p'];
  pt?: SpaceProps['pt'];
  borderTopRadius?: BorderProps['borderTopRadius'];
  borderBottomRadius?: BorderProps['borderBottomRadius'];
  containerProps?: BoxProps;
  cardContainerProps?: BoxProps;
}

export const WizardSubRouteCardWrapper = ({
  children,
  backgroundColor,
  p,
  pt,
  borderTopRadius,
  borderBottomRadius,
  containerProps,
  cardContainerProps,
}: AuxProps) => {
  const bgColor = useColorModeValue('customBodyBackground.500', 'customBodyBackground.800');
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  return (
    <Center
      bg={bgColor}
      width={'100%'}
      pt={`calc(${isMobileViewport ? 100 : 120}px + env(safe-area-inset-top))`}
      {...containerProps}
    >
      <Card
        borderRadius="initial"
        borderTopRadius={borderTopRadius || isMobileViewport ? 0 : 20}
        borderBottomRadius={borderBottomRadius || isMobileViewport ? 0 : 20}
        width={isMobileViewport ? '100%' : '60%'}
        mt={0}
        p={p || isMobileViewport ? 3 : 6}
        pt={pt}
        pb={0}
        mb={isMobileViewport ? 0 : 4}
        minHeight={
          isMobileViewport ? `calc(${COMPONENT_MIN_HEIGHT} - ${TOP_NAV_HEIGHT}px - ${WIZARD_HEIGHT}px)` : '70vh'
        }
        shadow="0px 4px 20px rgba(43, 46, 45, 0.1)"
        position="relative"
        backgroundColor={backgroundColor}
        overflow="hidden"
        {...cardContainerProps}
      >
        {children}
      </Card>
      {/*{import.meta.env['MODE'] === 'development' && <WizardDebugDrawer />}*/}
    </Center>
  );
};
