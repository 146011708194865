import React, { useMemo } from 'react';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { COLOURS } from '../../constants';

export default function HighchartContainer(options: Partial<Options>) {
  // Add default chart options
  const chartOptions: Options = useMemo(() => {
    // destructure the options object and pass the rest to the Highcharts options object
    const { series, title, legend, yAxis, tooltip, colors, chart, credits, plotOptions, ...otherOptions } = options;

    return {
      title: {
        text: '',
        ...title,
      },
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
        ...legend,
      },
      yAxis: {
        title: {
          text: '',
        },
        ...yAxis,
      },
      tooltip: {
        shared: true,
        ...tooltip,
      },
      series: series,
      colors: colors || COLOURS,
      chart: {
        zoomType: 'x',
        height: '400px',
        ...chart,
      },
      credits: {
        enabled: false,
        ...credits,
      },
      plotOptions: {
        series: {
          connectNulls: true,
          clip: false,
          lineWidth: 1,
          marker: {
            enabled: false,
          },
        },
        ...plotOptions,
      },
      ...otherOptions,
    };
  }, [options]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}
