import {
  Box,
  Center,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Image,
  Select,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { CTRating, ChannelData, Polarity, VoltageReference } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  CircuitTestStatus,
  DISPLAYED_VOLTAGE_REF_TYPES,
  ORDERED_TEST_RESULTS_BY_IMPORTANCE,
  POLARITY_TYPES,
  TEST_STATUS_TO_CONFIG,
  TestTypeV2,
} from './test-types';
import MultiToggleSwitch from '../../../../common/components/MultiToggleSwitch';
import './circuit-status-card.css';
import { useSelector } from 'react-redux';
import { selectSite } from '../../siteSlice';
import { roundNumber } from 'clipsal-cortex-utils/src/calculations/number-utils';
import FieldIconWrapper from '../ct-configuration/FieldIconWrapper';
import { CTRatings } from '../ct-configuration/SelectedCircuitsCard';
import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';

type Props = {
  meterName: string;
  circuit: ChannelData; // The CT this card represents.
  phaseValue: VoltageReference;
  isPhaseValuePending: boolean;
  onChangePhaseValue: (newPhase: VoltageReference) => void;
  polarityValue: Polarity;
  isPolarityValuePending: boolean;
  onChangePolarityValue: (newPolarity: Polarity) => void;
  ctRatingValue: CTRating;
  isCtRatingValuePending: boolean;
  onChangeCtRatingValue: (newCtRating: CTRating) => void;
  testedPowerFactorValue: number;
  testedPowerValue: number;
  polarityResult: CircuitTestStatus;
  powerFactorResult: CircuitTestStatus;
  onOpenDrawer: (circuit: ChannelData, type: TestTypeV2) => void;
  isInitiallyExpanded?: boolean;
};

/**
 * Discerns the status of the either test, by using an ordered array of result types and matching to at least one.
 * If none are found, defaults to Undetermined.
 *
 * @param polarityTestStatus - The status to check against for polarity.
 * @param powerFactorTestStatus - The status to check against for power factor.
 * @returns The final test status.
 */
function getStatus(polarityTestStatus: CircuitTestStatus, powerFactorTestStatus: CircuitTestStatus): CircuitTestStatus {
  for (const resultType of ORDERED_TEST_RESULTS_BY_IMPORTANCE) {
    if ([polarityTestStatus, powerFactorTestStatus].includes(resultType)) {
      return resultType;
    }
  }

  return CircuitTestStatus.Undetermined;
}

export default function CircuitStatusCard({
  circuit,
  testedPowerValue,
  testedPowerFactorValue,
  meterName,
  onChangePhaseValue,
  onChangePolarityValue,
  phaseValue,
  isPhaseValuePending,
  polarityValue,
  isPolarityValuePending,
  ctRatingValue,
  isCtRatingValuePending,
  onChangeCtRatingValue,
  polarityResult,
  powerFactorResult,
  onOpenDrawer,
  isInitiallyExpanded = true,
}: Props) {
  const [isExpanded, setExpanded] = useState(isInitiallyExpanded);
  const status = getStatus(polarityResult, powerFactorResult);
  const { color, title, icon } = TEST_STATUS_TO_CONFIG[status];
  const polarityStatusConfig = TEST_STATUS_TO_CONFIG[polarityResult];
  const phasingStatusConfig = TEST_STATUS_TO_CONFIG[powerFactorResult];
  const site = useSelector(selectSite);
  const phaseSelectOptions = DISPLAYED_VOLTAGE_REF_TYPES[site.electrical_config];
  const visiblePhaseValue = phaseSelectOptions.find((option) => option.value === phaseValue);
  const Icon = CIRCUIT_TYPE_TO_ICON[circuit.clipsal_monitors];
  const isMobileViewport = useBreakpointValue({
    base: window.innerWidth < 768,
    md: false,
  });
  const { shadowColor, switchClassName } = useColorModeValue(
    {
      shadowColor: 'rgba(0, 0, 0, 0.25)',
      switchClassName: 'light-mode-toggle-switch',
    },
    {
      shadowColor: 'rgba(255, 255, 255, 0.25)',
      switchClassName: 'dark-mode-toggle-switch-2',
    }
  );

  return (
    <Flex className="circuit-card" shadow={`0px 0px 4px ${shadowColor}`} rounded={8}>
      <Box
        w={'3%'}
        bg={status === CircuitTestStatus.InProgress ? 'dusk050.500' : color}
        borderTopLeftRadius={10}
        borderBottomLeftRadius={10}
      />
      <Box p={1} w={'97%'}>
        <Flex>
          <Center ml={2}>
            <FieldIconWrapper>
              <Icon w={6} h={6} />
            </FieldIconWrapper>
          </Center>

          <Box w="90%">
            <Flex justify={'space-between'} align={'flex-start'}>
              <Heading size={'sm'}>{circuit.circuit_name}</Heading>
              <Flex align={'center'}>
                {status === CircuitTestStatus.InProgress ? (
                  <Spinner size="xs" mr={1} color={'primaryBranding.500'} />
                ) : (
                  <Image mr={1} w={4} src={icon} alt={title} />
                )}
                <Text color={color} fontSize={'xs'}>
                  {title}
                </Text>
                <IconButton
                  size={'xs'}
                  variant={'ghost'}
                  aria-label={isExpanded ? 'Minimize' : 'Maximize'}
                  icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={() => setExpanded(!isExpanded)}
                />
              </Flex>
            </Flex>
            <Text color={'fixedCostGrey.500'} fontSize={'xs'}>
              {meterName}, CT{circuit.ww_circuit_id.charAt(circuit.ww_circuit_id.length - 1)},{' '}
              {visiblePhaseValue?.label} Phase
            </Text>
          </Box>
        </Flex>

        <Collapse in={isExpanded}>
          {/* PF/Power measurements */}
          <Flex mt={2}>
            <Box w={['5%', '10%']} />
            <Flex w={['95%', '90%']} justifyContent={'space-between'}>
              <Flex w="45%">
                <Center ml="auto" mr={[4, 8]}>
                  PF{' '}
                  <Box
                    as={'button'}
                    cursor="pointer"
                    onClick={() => onOpenDrawer(circuit, TestTypeV2.PowerFactor)}
                    ml={2}
                    p={1}
                    rounded={100}
                    border={'1px'}
                    borderColor={
                      powerFactorResult === CircuitTestStatus.InProgress ? 'dusk050.500' : phasingStatusConfig.color
                    }
                    bg={
                      powerFactorResult === CircuitTestStatus.InProgress
                        ? 'rgba(155, 160, 161, 0.3)'
                        : phasingStatusConfig.secondaryColor
                    }
                    fontWeight={'bold'}
                    minW={14}
                  >
                    {roundNumber(testedPowerFactorValue)}
                  </Box>
                </Center>
              </Flex>

              <Center borderLeft="2px solid rgba(155, 160, 161, 0.3)" w="55%">
                Power{' '}
                <Box
                  as={'button'}
                  cursor="pointer"
                  onClick={() => onOpenDrawer(circuit, TestTypeV2.Polarity)}
                  ml={2}
                  p={1}
                  rounded={100}
                  border={'1px'}
                  minW={16}
                  borderColor={
                    powerFactorResult === CircuitTestStatus.InProgress ? 'dusk050.500' : polarityStatusConfig.color
                  }
                  bg={
                    powerFactorResult === CircuitTestStatus.InProgress
                      ? 'rgba(155, 160, 161, 0.3)'
                      : polarityStatusConfig.secondaryColor
                  }
                  fontWeight={'bold'}
                >
                  {roundNumber(testedPowerValue)} kW
                </Box>
              </Center>
            </Flex>
          </Flex>

          {/* PF/Phase controls */}
          <Flex mt={[4, 6]} mx={2} justify="space-between">
            <Flex direction="column" align="center" h="100%">
              <Select
                data-testid="ct-rating-select"
                h={isMobileViewport ? 8 : 35}
                cursor={'pointer'}
                bg="#f5f5f5"
                _dark={{ bg: '#1c2230' }}
                border="none"
                rounded={25}
                value={ctRatingValue}
                disabled={isCtRatingValuePending}
                onChange={async (e) => {
                  const newCTRating = (Number(e.target.value) || 60) as CTRating;
                  await onChangeCtRatingValue(newCTRating);
                }}
              >
                {CTRatings.map((rating) => (
                  <option value={rating} key={rating}>
                    {rating}
                  </option>
                ))}
              </Select>
              {isCtRatingValuePending ? (
                <Flex justify="center" py={1} align="center" fontSize={'xs'}>
                  <Spinner emptyColor="gray.200" color="blue.500" size="sm" />
                  <Text fontWeight="normal" color="gray.500" ml={1}>
                    Updating...
                  </Text>
                </Flex>
              ) : (
                <Text fontSize="xs" mt={1}>
                  CT Rating (A)
                </Text>
              )}
            </Flex>

            <Box className={switchClassName + ` meter-test-${powerFactorResult.toLowerCase()}`}>
              <MultiToggleSwitch<VoltageReference>
                switchOptions={phaseSelectOptions}
                fontSize="xs"
                value={phaseValue}
                onChange={onChangePhaseValue}
                disabled={isPhaseValuePending}
                enableBottomLabel
                setValueAsBottomLabel={false}
                label="Phasing"
                customClassName={`container-height-${isMobileViewport ? 'small' : 'large'}`}
                data-testid="voltage-ref-toggle"
              />
            </Box>

            <Box className={switchClassName + ` meter-test-${polarityResult.toLowerCase()}`}>
              <MultiToggleSwitch<Polarity>
                switchOptions={POLARITY_TYPES}
                fontSize="xs"
                value={polarityValue}
                onChange={onChangePolarityValue}
                disabled={isPolarityValuePending}
                enableBottomLabel
                setValueAsBottomLabel={false}
                label="Polarity"
                customClassName={`container-height-${isMobileViewport ? 'small' : 'large'}`}
                data-testid="polarity-toggle"
              />
            </Box>
          </Flex>
        </Collapse>
      </Box>
    </Flex>
  );
}
