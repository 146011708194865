import { SourceType, WebsocketStatusResponseV2 } from './circuit-tests/circuit-status-websocket-types';
import { Appliance } from '../../../api/api-appliance';
import { CircuitTestStatus } from './circuit-tests/test-types';
import { MeterData } from './meter-setup-form-types';
import { InstalledDevice } from '../../../api/api-device';
import { getTestStatusBasedOnPolarityConfirmation } from './circuit-tests/use-meter-test-handlers';

export const getEmptyMeterTemplate = (label = ''): MeterData => ({
  meterId: null,
  isLoaded: false,
  serialNumber: '',
  model: '',
  label,
});

// @TODO: maybe find a more relevant place to put these?
export const GRID_CIRCUIT_TYPES = ['ac_load_net', 'ac_load'];
export const SOLAR_CIRCUIT_TYPES = ['pv_site_net', 'pv_site'];
export const HYBRID_CIRCUIT_TYPES = ['hybrid_inverter', 'backup_circuit'];
export const BATTERY_CIRCUIT_TYPES = ['battery_storage'];

export const NON_LOAD_CIRCUIT_TYPES = [
  ...GRID_CIRCUIT_TYPES,
  ...SOLAR_CIRCUIT_TYPES,
  ...BATTERY_CIRCUIT_TYPES,
  ...HYBRID_CIRCUIT_TYPES,
  'consumption',
];

export const VALID_STATUSES = [CircuitTestStatus.Skipped, CircuitTestStatus.Success];

export type LoadType = {
  title: string;
  source: SourceType;
  link: string;
};

/**
 * Returns a tuple of load types, indexed by stage:
 * 0 - Stage 1
 * 1 - Stage 2
 * Uses the appliances array as a source of truth, as validation in the appliance configuration step should ensure all
 * the appropriate appliances have been assigned so far.
 *
 * @param appliances - Array of appliances supplied from Redux.
 * @returns Tuple of load types.
 */
export function getDisplayedTestsByStage(appliances: Appliance[]): [LoadType[], LoadType[]] {
  let hasAddedSolarType = false;
  let hasAddedBatteryType = false;
  let hasAddedLoadsType = false;
  let hasAddedHybridType = false;
  let hasAddedBackupType = false;

  // Reduce all tests in a single loop of appliances -- the condition for adding each test type is based on whether
  // at least one of a certain appliance type exists in the appliances array.
  // The result is a tuple containing stage 1 at index 0, and stage 2 at index 1.
  const allTests = appliances.reduce<[LoadType[], LoadType[]]>(
    (tests, appliance) => {
      if (appliance.appliance_type === 'pv_site_net' && !hasAddedSolarType) {
        hasAddedSolarType = true;
        tests[0].push({ title: 'Solar', source: 'solar', link: '/site/:id/meter_setup/configure/:type' });
      } else if (appliance.appliance_type === 'battery_storage' && !hasAddedBatteryType) {
        hasAddedBatteryType = true;
        tests[0].push({ title: 'Battery', source: 'battery', link: '/site/:id/meter_setup/configure/:type' });
      } else if (
        appliance.appliance_type?.includes('load_') &&
        !GRID_CIRCUIT_TYPES.includes(appliance.appliance_type) &&
        !hasAddedLoadsType
      ) {
        hasAddedLoadsType = true;
        tests[0].push({ title: 'Loads', source: 'loads', link: '/site/:id/meter_setup/configure/loads_list' });
      } else if (appliance.appliance_type === 'hybrid_inverter' && !hasAddedHybridType) {
        hasAddedHybridType = true;
      } else if (appliance.appliance_type === 'backup_circuit' && !hasAddedBackupType) {
        hasAddedBackupType = true;
      }

      return tests;
    },
    [[], []]
  );

  // hybrid inverters with no backup circuit are treated same as ac coupled inverters
  // so, if no backup circuit in hybrid inverters, it is stage 1 else stage 2
  if (hasAddedHybridType) {
    const stageNumber = hasAddedBackupType ? 1 : 0;
    allTests[stageNumber].push({ title: 'Hybrid', source: 'hybrid', link: '/site/:id/meter_setup/configure/:type' });
  }

  // The grid is constant, and will always be included in the second stage of the tests.
  allTests[1].push({ title: 'Grid', source: 'grid', link: '/site/:id/meter_setup/configure/:type' });

  return allTests;
}

/**
 * Checks if tests in both stages (if applicable) are complete.
 *
 * @param testResults
 * @param appliances
 */
export function checkIfTestsAreComplete(
  testResults: WebsocketStatusResponseV2,
  appliances: Appliance[],
  polarityConfirmationStatus: Record<SourceType, boolean>
) {
  if (!appliances?.length) return false;

  const sourcesForSite: Exclude<SourceType, 'loads'>[] = getDisplayedTestsByStage(appliances).flatMap(
    (loadTypesAtStage) => loadTypesAtStage.flatMap((t) => (t.source === 'loads' ? [] : [t.source]))
  );

  // Ensure that all tests exist on the test object
  for (const testSource of sourcesForSite) {
    const testStatus = testResults[testSource]?.status;
    const testStatusBasedOnPolarityConfirmation = getTestStatusBasedOnPolarityConfirmation(
      polarityConfirmationStatus[testSource],
      testStatus
    );
    if (!testStatus || !VALID_STATUSES.includes(testStatusBasedOnPolarityConfirmation)) return false;
  }

  if (testResults?.loads) {
    // Check each load result as well
    for (const loadResult of testResults.loads) {
      if (!VALID_STATUSES.includes(loadResult.status)) return false;
    }
  }

  return true;
}

export function checkIfAppliancesAreAssigned(
  appliances: Appliance[],
  inverters: InstalledDevice[],
  batteries: InstalledDevice[]
) {
  if (!appliances?.length) return false;

  let hasAcCoupledInverter = false,
    hasDcCoupledInverter = false,
    totalDCCoupledInverters = 0;
  const hasBattery = !!batteries.length;

  inverters.forEach((inverter) => {
    // two conditions as both type of inverters can co-exist
    hasDcCoupledInverter = hasDcCoupledInverter || !!inverter.is_hybrid_inverter;
    hasAcCoupledInverter = hasAcCoupledInverter || !inverter.is_hybrid_inverter;
    if (inverter.is_hybrid_inverter) totalDCCoupledInverters += 1;
  });

  let hasGridAppliance = false,
    hasSolarAppliance = false,
    hasBatteryAppliance = false,
    hasHybridAppliance = false;

  appliances.forEach((appliance) => {
    if (!appliance.circuits.length) return;

    // only update when flags are false
    hasGridAppliance = hasGridAppliance || GRID_CIRCUIT_TYPES.includes(appliance.appliance_type);
    hasSolarAppliance = hasSolarAppliance || SOLAR_CIRCUIT_TYPES.includes(appliance.appliance_type);
    hasBatteryAppliance = hasBatteryAppliance || appliance.appliance_type === 'battery_storage';
    hasHybridAppliance = hasHybridAppliance || appliance.appliance_type === 'hybrid_inverter';
  });

  // conditionally checking if appliances are properly assigned
  if (hasAcCoupledInverter && !hasSolarAppliance) return false;
  if (hasDcCoupledInverter && !hasHybridAppliance) return false;
  if (hasBattery && batteries.length > totalDCCoupledInverters && !hasBatteryAppliance) return false;

  // Sites might not have any loads (e.g. consumption only, or 3 phase sites monitoring solar on 3 CTs and grid on 3
  // with a single meter. This means all 6 CTs are assigned to other source types.
  return hasGridAppliance;
}
