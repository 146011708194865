import {
  Box,
  Button,
  Heading,
  useBreakpointValue,
  useColorModeValue,
  Center,
  Flex,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import React from 'react';
import MobileTopNav from '../../../../common/components/MobileTopNav';
import { Link } from 'react-router-dom';
import { MANAGE_USERS_TAB_CONFIG } from './manage-users-helpers';
import InviteMemberForm from './InviteMemberForm';
import UserListWithFilters from './UserListWithFilters';

export default function ManageUsers() {
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });
  const background = useColorModeValue('white', 'gray.900');

  return (
    <Box px={[0, 0, 10]}>
      {isMobileViewport ? (
        <MobileTopNav title={'Manage users'} backURL={'/account/home'} />
      ) : (
        <Button as={Link} to={'/account/home'} mt={3}>
          Back
        </Button>
      )}
      <Center mx={isMobileViewport ? 0 : 10}>
        <Flex
          direction={'column'}
          w={isMobileViewport ? '100%' : '70%'}
          maxW={700}
          minH={isMobileViewport ? '90vh' : '75vh'}
          borderRadius={isMobileViewport ? 0 : 10}
          overflow="hidden"
        >
          <Box bg={background} borderBottomRadius={8}>
            {isMobileViewport || (
              <Heading mb={5} size={'lg'} paddingLeft={isMobileViewport ? 0 : 6} paddingTop={isMobileViewport ? 0 : 6}>
                Manage users
              </Heading>
            )}
            <Divider />

            <InviteMemberForm />
          </Box>

          <Box bg={background} mt={[2, 4]} pt={2} borderTopRadius={8}>
            <Tabs
              colorScheme={'primaryBranding'}
              isFitted={true}
              isLazy
              display={'flex'}
              flexDirection="column"
              flex={1}
            >
              <TabList>
                {MANAGE_USERS_TAB_CONFIG.map(({ status, title }, index) => (
                  <Tab px={isMobileViewport ? '' : 16} key={index} data-testid={`${status}-members-tab`}>
                    {title}
                  </Tab>
                ))}
              </TabList>

              <TabPanels flex={1} display={'flex'} flexDirection="column" mt={2}>
                {MANAGE_USERS_TAB_CONFIG.map(({ status }, index) => (
                  <TabPanel
                    padding={'0'}
                    flex={1}
                    display={'flex'}
                    flexDirection="column"
                    data-testid={`${status}-members-tab-panel`}
                    key={index}
                  >
                    <UserListWithFilters status={status} />
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Center>
    </Box>
  );
}
