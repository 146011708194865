import { ModalProps } from '../../../common/types/types';
import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Checkbox,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import gridCTControlledLoad from '../../../assets/images/grid_ct_controlled_load.svg';
import CustomButton from '../../../common/components/CustomButton';

type ControlledLoadModalProps = ModalProps;

export function ControlledLoadTutorialModal({ isOpen, onClose }: ControlledLoadModalProps) {
  const [isChecked, setIsChecked] = useState<boolean>(localStorage.getItem(`dontShowControlledLoadModal`) === 'true');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="fit-content" maxWidth={'90vw'} data-testid="site-has-controlled-load-modal">
        <ModalHeader fontSize="md" pr={12}>
          How to connect the grid CT for a site with a controlled load
        </ModalHeader>
        <ModalCloseButton mt={1} data-testid="site-has-controlled-load-modal-close-btn" />
        <ModalBody bg={'backgroundGrey.500'} display="flex" flexDirection="column" justifyContent="center" mx={5}>
          <Image src={gridCTControlledLoad} height="fit-content" objectFit="contain" />
          <Alert status="error" background="transparent" px={0} mt={2}>
            <AlertIcon boxSize="24px" />
            <Text fontWeight="600" color="red" fontSize="sm">
              Note: Grid CT should{' '}
              <Box as="span" display="inline" textDecoration="underline">
                NOT
              </Box>{' '}
              contain the controlled load
            </Text>
          </Alert>
        </ModalBody>

        <ModalFooter display="flex" flexDirection="column" alignItems="flex-start">
          <Checkbox
            mb={4}
            border="darkgray"
            isChecked={isChecked}
            onChange={(e) => {
              localStorage.setItem('dontShowControlledLoadModal', `${e.target.checked}`);
              setIsChecked(e.target.checked);
            }}
            data-testid="dont-show-again-checkbox"
          >
            Don't show me again
          </Checkbox>
          <CustomButton type="button" mx="auto" w="fit-content" px={10} py={4} my={0} onClick={onClose}>
            Done
          </CustomButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
