import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Image,
  ListItem,
  OrderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { ImInfo } from 'react-icons/im';
import blueInfoIcon from '../../../../assets/images/blue_info_icon.svg';
import wiringDiagram from '../../../../assets/images/wiring_diagram.svg';
import CustomButton from '../../../../common/components/CustomButton';
import InfoBottomDrawer from '../../../../common/components/InfoBottomDrawer';
import { InfoIcon } from '../../../../styles/custom-icons';

type Route = 'home' | 'wiringDiagram' | 'isolatorInstructions';

export default function GridHybridInfoDrawerButton({ isOpenByDefault = false }: { isOpenByDefault?: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [route, setRoute] = useState<Route>('home');

  useEffect(() => {
    if (isOpenByDefault) onOpen();
  }, [isOpenByDefault, onOpen]);

  const commonProps = {
    onChangeRoute: setRoute,
    onClose: () => {
      setRoute('home');
      onClose();
    },
  };

  const routeToContents = {
    home: <Home {...commonProps} />,
    wiringDiagram: <WiringDiagram {...commonProps} />,
    isolatorInstructions: <IsolatorInstructions {...commonProps} />,
  };

  return (
    <>
      {!isOpenByDefault && (
        <Box ml={2} onClick={onOpen} as={'button'}>
          <Box as={ImInfo} color={'customBlue.500'} size="22px" />
        </Box>
      )}

      <Drawer
        placement={'bottom'}
        isOpen={isOpen}
        onClose={() => {
          setRoute('home');
          onClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {routeToContents[route]}
        </DrawerContent>
      </Drawer>
    </>
  );
}

type Props = {
  onChangeRoute: (route: Route) => void;
  onClose: () => void;
};

function Home({ onChangeRoute }: Props) {
  return (
    <Center px={2} textAlign={'center'} flexDirection={'column'} data-testid="skip-all-test-drawer">
      <Image my={2} w={['30%', '30%', '10%']} src={blueInfoIcon} alt="Info icon" />
      <Heading my={2} size={'md'}>
        For Hybrid and Grid CT test: Safely isolate the battery and solar
      </Heading>
      <OrderedList textAlign={'start'}>
        <ListItem>Turn off other solar inverters</ListItem>
        <ListItem>Safely turn off the battery DC isolator and solar DC isolator</ListItem>
        <ListItem>Ensure the hybrid AC isolator and backup AC isolator are both on</ListItem>
        <ListItem>Turn on some backup loads so they are drawing power</ListItem>
      </OrderedList>

      <Button
        my={2}
        onClick={() => onChangeRoute('isolatorInstructions')}
        colorScheme={'customBlue'}
        type="button"
        variant="ghost"
      >
        How to safely turn off the battery DC isolator
      </Button>

      <Button
        mb={2}
        onClick={() => onChangeRoute('wiringDiagram')}
        colorScheme={'customBlue'}
        type="button"
        variant="ghost"
      >
        View the wiring diagram
      </Button>
    </Center>
  );
}

function WiringDiagram({ onClose }: Props) {
  return (
    <Center px={2} textAlign={'center'} flexDirection={'column'} data-testid="skip-all-test-drawer">
      <Heading w="80%" my={2} size={'md'}>
        How to wire CTs for a hybrid inverter
      </Heading>
      <Center py={1} w={['90%', '90%', '50%']} mt={3} bg={'dusk005.500'}>
        <Image w={['90%', '90%', '50%']} src={wiringDiagram} alt="Wiring diagram" />
      </Center>

      <CustomButton onClick={onClose}>Done</CustomButton>
    </Center>
  );
}

function IsolatorInstructions({ onClose }: Props) {
  return (
    <Center px={2} textAlign={'center'} flexDirection={'column'} data-testid="skip-all-test-drawer">
      <Image my={2} w={['30%', '30%', '10%']} src={blueInfoIcon} alt="Info icon" />
      <Heading my={2} size={'md'}>
        How to safely turn off the battery DC isolator
      </Heading>
      <OrderedList textAlign={'start'}>
        <ListItem>Turn off the solar DC isolator, hybrid AC isolator and backup AC isolator</ListItem>
        <ListItem>Check the battery is not charging or discharging</ListItem>
        <ListItem>Turn off the battery DC isolator</ListItem>
        <ListItem>Turn on the hybrid AC isolator and the backup AC isolator</ListItem>
      </OrderedList>

      <CustomButton onClick={onClose}>Done</CustomButton>
    </Center>
  );
}

export function GridInfoDrawerButton({ isOpenByDefault = false }: { isOpenByDefault?: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isOpenByDefault) onOpen();
  }, [isOpenByDefault, onOpen]);

  return (
    <>
      {!isOpenByDefault && (
        <Box ml={2} onClick={onOpen} as={'button'}>
          <Box as={ImInfo} color={'customBlue.500'} size="22px" />
        </Box>
      )}

      <InfoBottomDrawer
        isOpen={isOpen}
        onClose={onClose}
        useDefaultLayout
        drawerHeight={'370px'}
        heading="For Grid CT test: Safely isolate Solar"
        subHeading="Turn off all Solar Inverters and then continue test"
        icon={<InfoIcon w={16} h={16} />}
      >
        <CustomButton onClick={onClose} w="55%" maxW="250px">
          Continue Test
        </CustomButton>
      </InfoBottomDrawer>
    </>
  );
}
