import { Center, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const FieldIconWrapper = ({ children }: { children?: React.ReactNode }) => {
  const { iconFillColor, shadowColor, shadowColor2, iconColor } = useColorModeValue(
    {
      iconFillColor: 'white',
      shadowColor: 'rgba(60, 64, 67, 0.3)',
      shadowColor2: 'rgba(60, 64, 67, 0.15)',
      iconColor: 'dusk100.500',
    },
    {
      iconFillColor: 'customBodyBackground.800',
      shadowColor: 'rgba(220, 220, 220, 0.3)',
      shadowColor2: 'rgba(220, 220, 220, 0.15)',
      iconColor: 'dusk100.50',
    }
  );

  return (
    <Center
      rounded="full"
      shadow={` ${shadowColor} 0px 1px 2px 0px, ${shadowColor2} 0px 2px 6px 2px`}
      w={[10, 12]}
      h={[10, 12]}
      my="auto"
      mr={2}
      background={iconFillColor}
    >
      {/* Injecting colors to icon */}
      {React.cloneElement(children as React.ReactElement<any>, { color: iconColor })}
    </Center>
  );
};

export default FieldIconWrapper;
