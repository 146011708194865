import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'yup-phone';
import 'react-phone-input-2/lib/bootstrap.css';
import '../../../../styles/react-phone-input-2.css';
import { EmailIcon } from '@chakra-ui/icons';
import PhoneInput from 'react-phone-input-2';
import CustomButton from '../../../../common/components/CustomButton';
import { InvitationStatus, UserData } from '../../../../api/api-user';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { USER_ROLES, USER_ROLE_MAP } from '../account-helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUpdateConfirmedUserMutation, useUpdateInvitedUserMutation, useGetUser } from './manageUsersApi';
import { useInvitedSiteUsers, useSiteUsers } from '../../../site/customer-details/siteUsersApi';
import { UserFormData, mapApiUserToForm, userSchema } from './manage-users-helpers';

type EditUserFormProps = {
  type: InvitationStatus;
  user: UserFormData;
  handleClose: () => void;
};
const EditUserForm = ({ type, user, handleClose }: EditUserFormProps) => {
  const {
    register,
    getValues,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setValue,
  } = useForm<UserFormData>({
    resolver: yupResolver(userSchema),
    defaultValues: user,
    reValidateMode: 'onChange',
  });

  const toast = useToast({ isClosable: true });
  const { phoneInputClassName } = useColorModeValue(
    {
      phoneInputClassName: 'light-mode-phone-input',
      hoverBgColor: 'gray.50',
    },
    {
      phoneInputClassName: 'dark-mode-phone-input dark-mode-phone-input-transparent-bg',
      hoverBgColor: 'gray.500',
    }
  );
  const [updateConfirmedUser, { isLoading: isUpdatingConfirmedUser }] = useUpdateConfirmedUserMutation();
  const [updateInvitedUser, { isLoading: isUpdatingInvitedUser }] = useUpdateInvitedUserMutation();

  const handleSubmit = async ({ firstName, lastName, email, phoneNumber, role, userId, username }: UserFormData) => {
    const body = {
      user_first_name: firstName,
      user_last_name: lastName,
      user_email: email,
      user_phone: phoneNumber,
      role,
    };

    const response = await (type === 'CONFIRMED' && userId
      ? updateConfirmedUser({ body, userId })
      : updateInvitedUser({ body, username }));
    const isError = 'error' in response;
    const status = isError ? 'error' : 'success';
    const title = isError
      ? response.error.message || 'Something went wrong updating user!'
      : 'User updated successfully!';
    toast({ status, title });
    handleClose();
  };

  return (
    <Box as="form" onSubmit={handleFormSubmit(handleSubmit)} data-testid="edit-user-form">
      <Flex mt={2}>
        <FormControl isInvalid={!!errors?.firstName}>
          <FormLabel>First Name</FormLabel>
          <Input data-private data-testid={`firstName`} placeholder={'Enter first name'} {...register(`firstName`)} />
          <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.lastName} ml={2}>
          <FormLabel>Last Name</FormLabel>
          <Input data-private data-testid={`lastName`} placeholder={'Enter last name'} {...register(`lastName`)} />

          <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <FormControl isInvalid={!!errors?.role} my={3}>
        <FormLabel>Role</FormLabel>

        <Select {...register(`role`)} data-testid={`role`}>
          {USER_ROLES.map((role, index) => (
            <option value={role} key={index} data-testid={`${role.toLowerCase()}-role`}>
              {USER_ROLE_MAP[role]}
            </option>
          ))}
        </Select>

        <FormErrorMessage>{errors?.role?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.email} my={3}>
        <FormLabel>Email</FormLabel>
        <InputGroup>
          <InputLeftElement>
            <EmailIcon opacity={0.7} />
          </InputLeftElement>
          <Input
            data-private
            data-testid={`email`}
            placeholder={'Enter email'}
            {...register(`email`)}
            isDisabled={type === 'CONFIRMED'}
          />
        </InputGroup>
        <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.phoneNumber} className={phoneInputClassName} data-testid="phone">
        <FormLabel>Phone</FormLabel>
        <PhoneInput
          isValid={!errors?.phoneNumber}
          placeholder="+XX 0XXX XXX XXX"
          country="au"
          autoFormat
          masks={{ au: '.... ... ...' }}
          onlyCountries={['au', 'nz']}
          countryCodeEditable={false}
          enableAreaCodeStretch
          value={getValues().phoneNumber}
          onChange={(phone) => {
            setValue(`phoneNumber`, '+' + phone, {
              shouldDirty: !!errors?.phoneNumber,
              shouldValidate: !!errors?.phoneNumber,
            });
          }}
        />
        <FormErrorMessage>{errors?.phoneNumber?.message}</FormErrorMessage>
      </FormControl>

      <CustomButton
        minW={180}
        data-testid="update-user-button"
        isLoading={isUpdatingConfirmedUser || isUpdatingInvitedUser}
      >
        Update
      </CustomButton>
    </Box>
  );
};

type EditUserModalProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  status?: InvitationStatus;
  userDetails?: UserData;
};

const EditUserModal = ({ isOpen, onOpen, onClose, status = 'CONFIRMED', userDetails }: EditUserModalProps) => {
  const { users } = useSiteUsers();
  const { invitedUsers } = useInvitedSiteUsers();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const userId = params.get('userId');
  const username = params.get('username');
  const backUrl = params.get('backUrl');
  const invitationStatus = params.get('status') as InvitationStatus;
  const isConfirmedUser = invitationStatus === 'CONFIRMED';

  /* Minor optimization to not fetch if user data is present in redux */
  const userDataFromRedux = useMemo(() => {
    // If this is editing within manage users, we don't need to search in redux
    if (!userDetails) return null;

    let userData: UserFormData | null = null;

    if (isConfirmedUser) {
      const data = users.find((user) => user.user.user_id === Number(userId || 0))?.user;
      userData = data ? mapApiUserToForm(data) : null;
    } else {
      const invitedUserData = invitedUsers.find((user) => user.user_name === username);
      userData = invitedUserData ? mapApiUserToForm(invitedUserData) : null;
    }

    return userData;
  }, [userId, username]);

  // distinguish whether to fetch user data from api or not
  const needToFetchUser = !userDetails && !userDataFromRedux;

  const { user, isLoading, isFetching } = useGetUser({
    id: isConfirmedUser ? userId : username,
    status: invitationStatus,
    skip: !needToFetchUser,
  });

  const userData = useMemo(() => {
    if (userDetails) return mapApiUserToForm(userDetails);
    if (!userDataFromRedux && user) return mapApiUserToForm(user);
    return userDataFromRedux;
  }, [needToFetchUser, userDataFromRedux, user, userDetails]);

  const invitationType = needToFetchUser ? invitationStatus : status;

  const isFetchingUserDetails = needToFetchUser && (isLoading || isFetching);

  useEffect(() => {
    // open modal to show loading state
    const hasRequiredParams = (userId || username) && invitationStatus;
    if (needToFetchUser && hasRequiredParams) onOpen();
  }, [needToFetchUser]);

  const handleClose = () => {
    if (backUrl) navigate(backUrl);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent minH={250} mx={4}>
        <ModalHeader fontWeight={700}>Edit User</ModalHeader>
        <Divider borderWidth={1} />
        <ModalCloseButton />
        <ModalBody>
          {!isFetchingUserDetails && userData && invitationType ? (
            <EditUserForm {...{ type: invitationType, user: userData, handleClose }} />
          ) : (
            <CenteredLoader />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
