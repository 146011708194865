import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { MouseEvent, TouchEvent, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactComponent as Logo } from '../../../assets/images/clipsal_logo.svg';
import { ReactComponent as LogoDark } from '../../../assets/images/clipsal_logo_dark.svg';
import { selectUser } from '../../user/userSlice';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomButton from '../../../common/components/CustomButton';
import { COMPONENT_MIN_HEIGHT, PRIVACY_POLICY_URL } from '../../../common/constants';
import { TermsAndConditionsModal } from '../TermsAndConditionsModal';
import { Browser } from '@capacitor/browser';
import ProductFeatureCarousel from '../ProductFeatureCarousel';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import { SHORTHAND_ENV_TYPE } from '../../user/user-helpers';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import OTPForm from './OTPForm';
import { ENV_TYPE } from '../../../env-type';
import * as Sentry from '@sentry/react';
import { ForgotPassword } from './ForgotPassword';
import { ConfirmForgotPassword } from './ConfirmForgotPassword';

type LoginFormData = {
  email: string;
  password: string;
  isOTPLogin: boolean;
};

export enum LoginRoute {
  Login,
  EnterOTP,
  ForgotPassword,
  ConfirmForgotPassword,
}

const schema = yup.object().shape({
  email: yup.string().email().trim().required('Email is required'),
  isOTPLogin: yup.boolean().required(),
  password: yup
    .string()
    .nullable()
    .when('isOTPLogin', {
      is: false,
      then: yup.string().required('Password is required'),
    }),
});

export default function Login() {
  const isDesktopViewport = useBreakpointValue({
    base: window.innerWidth > 991,
    lg: true,
  });
  const user = useSelector(selectUser);
  const backgroundColor = useColorModeValue('white', 'customBodyBackground.800');
  if (user.isLoggedIn) return <Navigate to="/" />;

  return (
    <Box
      position={'absolute'}
      top={'env(safe-area-inset-top)'}
      left={0}
      w="100vw"
      bg={backgroundColor}
      h={COMPONENT_MIN_HEIGHT}
      px={[2, 2, 0]}
    >
      {isDesktopViewport ? (
        <Flex boxSize={'full'}>
          <Box minW={'50%'}>
            <ProductFeatureCarousel />
          </Box>
          <Box minW={'50%'} maxW={'50%'} overflowX="hidden">
            <Center h={'100%'}>
              <Card
                w={'100%'}
                minH={600}
                maxH="100vh"
                overflowY="auto"
                maxW="450px"
                rounded={10}
                padding={8}
                shadow="0px 4px 4px rgba(0, 0, 0, 0.1), 0px -1px 4px rgba(0, 0, 0, 0.1)"
              >
                <LoginFormContainer />
              </Card>
            </Center>
          </Box>
        </Flex>
      ) : (
        <Box px={4}>
          <LoginFormContainer />
        </Box>
      )}
    </Box>
  );
}

type LoginFormContainerState = {
  route: LoginRoute;
  isEnvironmentSwitchVisible: boolean;
  cognitoUser: CognitoUser | null;
  email: string | null;
};

const INITIAL_STATE: LoginFormContainerState = {
  route: LoginRoute.Login,
  isEnvironmentSwitchVisible: false,
  cognitoUser: null,
  email: null,
};

function LoginFormContainer() {
  const [state, setState] = useState<LoginFormContainerState>(INITIAL_STATE);
  const { isEnvironmentSwitchVisible, cognitoUser, route, email } = state;
  const isMouseDown = useRef(false);
  const LogoIcon = useColorModeValue(Logo, LogoDark);
  const toast = useToast({ duration: 3000, isClosable: true });

  const routeMap = {
    [LoginRoute.Login]: <LoginForm onChangeRoute={handleSetRoute} onSubmit={handleSubmit} />,
    [LoginRoute.EnterOTP]: <OTPForm cognitoUser={cognitoUser} onChangeRoute={handleSetRoute} />,
    [LoginRoute.ForgotPassword]: (
      <ForgotPassword
        setEmail={(newEmail) => {
          setState((p) => ({
            ...p,
            email: newEmail,
          }));
        }}
        onChangeRoute={handleSetRoute}
      />
    ),
    [LoginRoute.ConfirmForgotPassword]: <ConfirmForgotPassword email={email ?? ''} onChangeRoute={handleSetRoute} />,
  };

  function handleSetRoute(newRoute: LoginRoute) {
    setState((p) => ({
      ...p,
      route: newRoute,
    }));
  }

  async function handleSubmit(values: LoginFormData) {
    if (values.isOTPLogin) await handleOTPLogin(values);
    else await handlePasswordLogin(values);
  }

  async function handleOTPLogin(values: LoginFormData) {
    try {
      const cognitoUser = await Auth.signIn(values.email.toLowerCase());

      toast({
        title: `OTP code sent!`,
        description: `We sent a code to ${values.email}, please check your inbox.`,
        status: 'info',
      });

      setState({
        ...state,
        cognitoUser,
        email: values.email.toLowerCase(),
        route: LoginRoute.EnterOTP,
      });
    } catch (e) {
      console.error(e);

      toast({
        title: 'Something went wrong while sending OTP!',
        description: 'Please contact us if this issue persists.',
        status: 'error',
      });
    }
  }

  async function handlePasswordLogin(values: LoginFormData) {
    try {
      await Auth.signIn(values.email.toLowerCase(), values.password);

      toast({
        title: `Successfully logged in`,
        status: 'success',
      });

      // User is fully logged in
      window.location.replace('/');
    } catch (e) {
      Sentry.captureException(e);
      toast({
        title: 'Error logging in',
        description: 'Check that your email and password are correct and try again.',
        status: 'error',
      });
    }
  }

  // For easter egg
  const handleMouseDown = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    isMouseDown.current = true;

    setTimeout(() => {
      if (isMouseDown.current) {
        setState({
          ...state,
          isEnvironmentSwitchVisible: true,
        });
      }
    }, 3000);
  };

  const handleMouseUp = () => {
    isMouseDown.current = false;
  };

  return (
    <>
      <Center flexDirection={'column'} textAlign={'center'} my={5} data-testid="login-form">
        {route === LoginRoute.Login && (
          <>
            <Box
              data-testid="login-heading-easter-egg"
              cursor={'pointer'}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
              my={5}
              w={'60%'}
            >
              <LogoIcon />
            </Box>
            <Heading mb={2} size={'md'}>
              Welcome back
            </Heading>
            <Text fontSize={'md'}>Login to your account!</Text>
          </>
        )}
      </Center>

      {isEnvironmentSwitchVisible && (
        <>
          <Box mb={1} data-testid="environment-select">
            Select environment
          </Box>
          <RadioGroup
            onChange={(e) => {
              localStorage.setItem('cortexEnvType', e.toString());
              window.location.reload();
            }}
            mb={4}
            value={SHORTHAND_ENV_TYPE as string}
            defaultValue={'PROD'}
          >
            <Radio mr={2} value="PROD">
              Production
            </Radio>
            <Radio ml={2} value="DEV">
              Development
            </Radio>
          </RadioGroup>
        </>
      )}

      {routeMap[route]}
    </>
  );
}

type LoginFormProps = { onSubmit: (values: LoginFormData) => void; onChangeRoute: (route: LoginRoute) => void };

function LoginForm({ onSubmit, onChangeRoute }: LoginFormProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      isOTPLogin: true,
    },
  });
  const { isOpen: isPasswordSetupReminderPopoverOpen, onClose: onClosePasswordSetupReminder } = useDisclosure({
    defaultIsOpen: !localStorage.getItem('firstTimePasswordSetupFlowViewed'),
  });
  const passwordInput = register('password');
  const isOTPLogin = watch('isOTPLogin');

  function handleSwitchAuthFlows() {
    Auth.configure({
      region: 'ap-southeast-2',
      userPoolId: import.meta.env?.[`VITE_${ENV_TYPE}_USER_POOL_ID`],
      userPoolWebClientId: import.meta.env?.[`VITE_${ENV_TYPE}_CLIENT_ID`],
      authenticationFlowType: isOTPLogin ? 'USER_SRP_AUTH' : 'CUSTOM_AUTH',
    });

    setValue('isOTPLogin', !isOTPLogin);
  }

  return (
    <>
      <Box>
        <Box data-testid="request-otp-form" onSubmit={handleSubmit(onSubmit)} as={'form'}>
          <FormControl mt={3} isInvalid={!!errors.email} data-testid="email-field">
            <FormLabel>Email</FormLabel>
            <Input
              {...register('email')}
              type="email"
              autoComplete="email"
              data-testid="email"
              placeholder={'Email'}
              isInvalid={!!errors.email}
              autoFocus
              data-private
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          {!isOTPLogin && (
            <>
              <Popover
                onClose={() => {
                  localStorage.setItem('firstTimePasswordSetupFlowViewed', 'true');
                  onClosePasswordSetupReminder();
                }}
                isOpen={isPasswordSetupReminderPopoverOpen}
              >
                <PopoverTrigger>
                  <FormControl mt={2} isInvalid={!!errors.password}>
                    <Input
                      {...passwordInput}
                      type="password"
                      autoComplete="password"
                      data-testid="password"
                      placeholder={'Password'}
                      isInvalid={!!errors.password}
                      data-private
                    />
                    <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent
                  data-testid="create-password-popover"
                  p={2}
                  rounded={20}
                  borderWidth={'0px'}
                  bgColor={'#5041F9'}
                  color="white"
                >
                  <PopoverArrow bgColor={'#5041F9'} />
                  <PopoverCloseButton data-testid="create-password-popover-close-btn" />
                  <PopoverHeader fontWeight="bold">Create password</PopoverHeader>
                  <PopoverBody>
                    <Text>If you haven't created a password yet, continue below to set one up:</Text>
                    <Center>
                      <Button
                        onClick={() => {
                          localStorage.setItem('firstTimePasswordSetupFlowViewed', 'true');
                          onChangeRoute(LoginRoute.ForgotPassword);
                        }}
                        mt={2}
                        data-testid="create-password-popover-proceed-btn"
                        variant="outline"
                        _hover={{ bg: '#6b5eff' }}
                        color={'#fff'}
                        rounded={20}
                      >
                        Create Password
                      </Button>
                    </Center>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <Flex justify={'flex-end'}>
                <Text
                  data-testid="forgot-password-link"
                  cursor={'pointer'}
                  onClick={() => {
                    onChangeRoute(LoginRoute.ForgotPassword);
                  }}
                  fontWeight={'bolder'}
                  color={'customBlue.500'}
                  fontSize={'sm'}
                >
                  Forgot password?
                </Text>
              </Flex>
            </>
          )}

          <CustomButton
            data-testid="login-button"
            id={'login-button'}
            w="100%"
            rounded={3}
            my={0}
            py={0}
            mt={6}
            isLoading={isSubmitting}
            loadingText={'Logging in..'}
          >
            {isOTPLogin && 'One Time Password '}Login
          </CustomButton>

          <Text textAlign="center" mt={1} mb={3} fontSize={'sm'}>
            <Box
              data-testid="toggle-login-preference"
              cursor={'pointer'}
              onClick={handleSwitchAuthFlows}
              as={'span'}
              color={'customBlue.500'}
            >
              Prefer to use {isOTPLogin ? 'your password' : 'a one-time password'}?
            </Box>
          </Text>
        </Box>

        <Center mt={1}>
          <Text fontSize={'sm'} textAlign="center">
            By logging in, you accept the
            <Box
              mx={1}
              cursor={'pointer'}
              _hover={{ textDecoration: 'underline' }}
              onClick={onOpen}
              as={'span'}
              color="customLinkBlue.500"
            >
              Terms and Conditions
            </Box>
            and
            <Box
              ml={1}
              cursor={'pointer'}
              _hover={{ textDecoration: 'underline' }}
              onClick={() => Browser.open({ url: PRIVACY_POLICY_URL })}
              as={'span'}
              color="customLinkBlue.500"
            >
              Privacy Policy
            </Box>
          </Text>
        </Center>
        <Center mt={6}>
          <Text fontSize={'sm'}>
            Don't have an account?
            <Box
              data-testid="signup-link"
              cursor={'pointer'}
              _hover={{ textDecoration: 'underline' }}
              onClick={() => navigate('/signup/user-details')}
              fontWeight={'bold'}
              as={'span'}
              color="customLinkBlue.500"
              ml={1}
            >
              Sign Up
            </Box>
          </Text>
        </Center>
      </Box>

      <TermsAndConditionsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
