import React from 'react';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Button, Flex, Heading } from '@chakra-ui/react';
import { ANDROID_PLAY_STORE_URL, APPLE_APP_STORE_URL } from '../constants';

export const ToastAppUpdate = () => {
  const onClick = async () => {
    const url = Capacitor.getPlatform() === 'ios' ? APPLE_APP_STORE_URL : ANDROID_PLAY_STORE_URL;
    await Browser.open({ url });
  };

  return (
    <Flex direction={'column'} alignItems={'center'} borderRadius={10} color="white" p={3} bg="green.500">
      <Flex mb={2} align={'center'}>
        <Heading mr={1} size={'sm'}>
          A new version of Clipsal Cortex Fleet is available
        </Heading>
      </Flex>
      <Button width={'50%'} variant={'outline'} size={'xs'} onClick={onClick}>
        Update
      </Button>
    </Flex>
  );
};
