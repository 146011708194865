import { useWatch } from 'react-hook-form';
import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { CommonFieldListProps, CTConfigFormData } from './ct-configuration-form-types';

/**
 * This component watches changes in forms and composes new reservedCTs object
 * when form state changes. Also solves syncing state issues in react hook form
 * @param control control object of react hook form
 * @param loadApplianceIndex index of the LoadAppliance
 */
export const useReservedCTsForLoad = (control: CommonFieldListProps['control'], loadApplianceIndex: number) => {
  const { grid, solar, battery, hybrid, load, evCharger } = useWatch({ control }) as CTConfigFormData;

  return load.reduce(
    (acc: ChannelData[], val, index) => {
      if (!val.circuits || loadApplianceIndex === index) return acc;
      else return [...acc, ...val.circuits];
    },
    [
      ...grid,
      ...solar.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
      ...battery.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
      ...Object.values(hybrid).flat(),
      ...evCharger.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
    ]
  );
};
