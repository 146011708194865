import React from 'react';
import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  useColorModeValue,
  Box,
  Heading,
} from '@chakra-ui/react';
import { PRIVACY_POLICY_URL } from '../../common/constants';
import { ModalProps } from '../../common/types/types';

export function TermsAndConditionsModal({ isOpen, onClose }: ModalProps) {
  const buttonColorScheme = useColorModeValue('customBlack', 'whiteAlpha');
  return (
    <Modal scrollBehavior={'inside'} size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid="termsConditionsModalBody">
        <ModalHeader>Terms & Conditions</ModalHeader>
        <ModalCloseButton data-testid="termsConditionsCloseBtn" />
        <ModalBody>
          <TermsAndConditionsText />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme={buttonColorScheme} color="white" mr={3} onClick={onClose}>
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function TermsAndConditionsText() {
  return (
    <Box>
      <Heading mb={3} size="md">
        Clipsal Cortex Fleet Mobile Application
      </Heading>
      <Heading my={3} size="md">
        TERMS OF USE
      </Heading>
      <Text fontSize={'sm'} mb={2}>
        Latest update: Oct 2021
      </Text>
      <Text my={2}>
        THESE TERMS OF USE GOVERN YOUR USE OF THE CLIPSAL CORTEX FLEET MOBILE APPLICATION (HEREINAFTER "THE MOBILE
        APPLICATION") PUBLISHED BY NEW ENERGY GROUP PTY LTD (AS HEREINAFTER DEFINED) AND MADE AVAILABLE FOR DOWNLOAD
        FROM APPLICATIONS STORES PROPRIETARY TO NEW ENERGY GROUP PTY LTD OR THIRD-PARTY APPLICATIONS STORES OFFICIALLY
        AUTHORIZED BY NEW ENERGY GROUP PTY LTD TO MAKE THE MOBILE APPLICATION AVAILABLE FOR DOWNLOAD.
      </Text>
      <Text my={2}>
        THESE TERMS OF USE APPLY UNDER THE CONSIDERATION THAT THE INTENDED PURPOSE FOR WHICH THE MOBILE APPLICATION IS
        MADE, IS THAT THE MOBILE APPLICATION MAY BE USED BY PROFESSIONALS IN THEIR ORDINARY COURSE OF BUSINESS. IF YOU
        ARE NOT A PROFESSIONAL USING THE MOBILE APPLICATION IN THE ORDINARY COURSE OF YOUR BUSINESS THEN YOU HAVE NO
        RIGHT NOR LICENSE TO USE THE MOBILE APPLICATION.
      </Text>
      <Text my={2}>
        BEFORE ACCESSING AND USING THE MOBILE APPLICATION, PLEASE READ THESE TERMS OF USE CAREFULLY. BY USING THE MOBILE
        APPLICATION, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREED TO THESE TERMS OF USE AND THAT YOU AGREE
        TO BE BOUND BY THEM.
      </Text>
      <Text my={2}>
        EFFECTIVE AS OF THE DATE OF YOUR ACCEPTANCE THEREOF AS DEFINED HEREINABOVE, THESE TERMS OF USE FORM A LEGAL
        AGREEMENT BETWEEN THE CORPORATION, COMPANY OR OTHER LEGAL ENTITY, TO WHICH THE MOBILE APPLICATION HAS BEEN MADE
        AVAILABLE FOR DOWNLOAD, (HEREINAFTER "YOU"), AND NEW ENERGY GROUP PTY LTD (AS DEFINED HEREUNDER), HEREINAFTER
        THE &#39;AGREEMENT&#39;). AS A CORPORATION, COMPANY OR OTHER LEGAL PERSON, IT IS YOUR RESPONSIBILITY TO
        ASCERTAIN THAT ANY INDIVIDUAL PERSON COMPLETING THE DOWNLOAD AND/OR THE INSTALLATION OF THE MOBILE APPLICATION
        HAS THE MANDATE OR LEGAL AUTHORITY TO BIND YOU AND CONFIRM YOUR ACCEPTANCE OF THESE TERMS OF USE IN WHICH EVENT,
        "YOU" AND "YOUR" WILL BE DEEMED TO REFER TO THAT CORPORATION, COMPANY OR OTHER LEGAL ENTITY.
      </Text>
      <Heading my={3} size="md">
        1. DEFINITIONS
      </Heading>
      <Text my={2}>
        For the purposes of these Terms of Use, the capitalized terms below shall have the meaning defined herein below:
      </Text>
      <Text my={2}>
        <strong>Affiliates</strong> : means, with respect to each of New Energy Group Pty Ltd and the User, any
        corporation, company or other legal entity that is directly or indirectly (i) controlling New Energy Group Pty
        Ltd or the User, or (ii) controlled by New Energy Group Pty Ltd or the User, or (iii) under common control with,
        respectively, New Energy Group Pty Ltd or the User, and the term "control" shall, for the purpose of this
        definition, mean the direct or indirect control of at least 50% of the stock capital and/or of the voting
        rights.
      </Text>
      <Text my={2}>
        " <strong>Authorized Users"</strong> : means any employee or contractor acting under the authority or control
        and on behalf of the User when accessing and making use of the Mobile Application.
      </Text>
      <Text my={2}>
        " <strong>Contents"</strong> : means, this list not being limitative, the structure of the Mobile Application,
        its editorial contents, the drawings, the illustrations, the images, the photos, the trademarks, the logos, the
        initials, the company names, the audiovisual or multimedia works, the visual, audio or sound contents, as well
        as any other file or element present within the Mobile Application or otherwise forming part thereof.
      </Text>
      <Text my={2}>
        " <strong>Documentation"</strong> : means printed, online or electronic information, instructions or other
        material (e.g. tutorials) related to the Mobile Application, as such may be provided by New Energy Group Pty Ltd
        within the Mobile Application and/or on New Energy Group Pty Ltd website(s) promoting or otherwise presenting
        the Mobile Application and/or by contacting New Energy Group Pty Ltd&#39;s local customer care center. You agree
        that the Documentation may be supplied only in the English language, unless otherwise required by local law
        without the possibility of contractual waiver or limitation.
      </Text>
      <Text my={2}>
        " <strong>Intellectual Property Rights"</strong> : means any and all rights of any kind and any type arising
        under statute, regulation, ordinance, common law, treaty, convention or otherwise, and including, without
        limitation any patents, utility models, moral rights, copyright and neighbouring rights, trademarks and trade
        dress rights, mask work rights, service marks, right in domain names, designs rights, rights in computer
        software, database rights, rights in confidential information (including but not limited to all rights in
        know-how and trade secrets) and any other intellectual property rights, in each case whether registered or not,
        including but not limited to applications and renewals, and all rights and forms of protection having equivalent
        or similar effect, recognized under the laws of each and every jurisdiction throughout the world.
      </Text>
      <Text my={2}>
        " <strong>Mobile Application"</strong> : means the Clipsal Cortex Fleet mobile software application that
        provides the functions described or referred to in these Terms of Use, as published and made available by New
        Energy Group Pty Ltd or its Affiliates for download from applications&#39; stores proprietary to New Energy
        Group Pty Ltd or officially authorized third-party applications&#39; stores. A reference to the Mobile
        Application in these Terms of Use will be deemed to include a reference to the Contents, the software programs,
        the programming and navigation tools, the data bases, the Documentation and any other structural components of
        the Mobile Application, as well as any updates and upgrades thereto that may be provided by New Energy Group Pty
        Ltd in its sole discretion.
      </Text>
      <Text my={2}>
        <strong>"Personal Information":</strong> means any information relating to an identified or identifiable natural
        person; an identifiable natural person is one who can be identified, directly or indirectly, in particular by
        reference to an identifier such as a name, an identification number, location data, an online identifier or to
        one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social
        identity of that natural person.
      </Text>
      <Text my={2}>
        <strong>"New Energy Group Pty Ltd"</strong> : means New Energy Group Pty Ltd trading as "New Energy Group Pty
        Ltd" having its registered office at 503/323 Castlereagh St Haymarket, NSW, 2000, Australia.
      </Text>
      <Text my={2}>
        <strong>"User Data"</strong> : means all information, content and data, such as but not limited to text, sound
        or files, that the User uploads, collects, stores and/or processes in the Mobile Application, and/or creates
        and/or modifies, through the User&#39;s use of the Mobile Application.
      </Text>
      <Text my={2}>
        <strong>"You"</strong> or <strong>"the User"</strong> : means each corporation, company or other legal entity
        that downloads or makes use of the Mobile Application. If You are an individual person, You are agreeing to
        these Terms of Use on behalf of that corporation, company or other legal entity and declare to New Energy Group
        Pty Ltd that You have the authority to do so and thereby bind that corporation, company or other legal entity to
        these Terms of Use in which event, "You" and "Your" will be deemed to refer to that corporation, company or
        other legal entity.
      </Text>
      <Heading my={3} size="md">
        2. DESCRIPTION OF THE MOBILE APPLICATION
      </Heading>
      <Text my={2}>
        <strong>2.1</strong>. The Documentation provides for the description of the Mobile Application as to the purpose
        for which it is intended to be used and, if applicable to the Mobile Application, any instruction on how to use
        the Mobile Application and any related safety instructions. For a proper use of the Mobile Application, User
        must refer to and strictly follow the Documentation available with or within the Mobile Application. The
        information on the type of mobile devices and operating systems on which the Mobile Application is compatible
        for use, is indicated on the applications&#39; stores that make the Mobile Application available for download.
      </Text>
      <Text my={2}>
        <strong>2.2.</strong> New Energy Group Pty Ltd reserves the right but is not obligated to, at any time, update
        or upgrade the then current version of the Mobile Application and its Documentation so as to enhance current
        functionalities, add or remove functionalities of the Mobile Application and/or to create temporary or permanent
        conditions or limitations to the use of the Mobile Application. Any such update or upgrade to the Mobile
        Application and its Documentation will be published online by New Energy Group Pty Ltd. New Energy Group Pty Ltd
        recommends that every User, including returning Users, carefully review New Energy Group Pty Ltd&#39;s corporate
        website <a href="https://clipsalcortex.com">https://clipsalcortex.com</a> or the applications&#39; stores that
        make the Mobile Application available for download. It is the User&#39;s responsibility to visit such website or
        pages prior to each use of the Mobile Application. The User&#39;s continued use of the Mobile Application after
        any such update or upgrade to the Mobile Application is published by New Energy Group Pty Ltd, shall be deemed
        as the User&#39;s acceptance of such update or upgrade and the User&#39;s agreement to use the updated or
        upgraded Mobile Application in accordance with these Terms of Use (or any then-current, revised Terms of Use).
        If the User does not consent to such updates or upgrades, the User shall have the immediate obligation to cease
        any use of the Mobile Application and to delete or expunge the Mobile Application from all computers, hard
        disks, servers, tablets, smart phones or other mobile devices onto which the User downloaded the Mobile
        Application, with the effect that the legal agreement formed between the User and New Energy Group Pty Ltd by
        the User&#39;s acceptance of these Terms of Use shall be deemed immediately and automatically terminated.
      </Text>
      <Heading my={3} size="md">
        3. REGISTRATION REQUIREMENTS
      </Heading>
      <Text my={2}>
        <strong>3.1</strong> Once You have downloaded the Mobile Application, and depending upon the settings of the
        Mobile Application, you may have the possibility or the obligation to create a user account within the Mobile
        Application to use it.
      </Text>
      <Text my={2}>
        <strong>3.2</strong> If such possibility is offered in the Mobile Application, You shall use the "create an
        account" or any equivalent feature of the Mobile Application and thereby provide such information required
        thereon with at least the name and email address of one individual person You authorize to use the Mobile
        Application, it being understood that, depending upon the jurisdiction, such information You provided to create
        Your user account may be considered as Personal Information and shall in such case be treated as such by each of
        You and New Energy Group Pty Ltd in compliance with these Terms of Use and any applicable laws and regulations.
      </Text>
      <Text my={2}>
        <strong>3.3</strong> When creating Your user account, You agree (i) to create a unique password that You do not
        use with any other online product or service; (ii) to provide accurate, truthful, current and complete
        information; (iii) to promptly update upon any change the information provided to create Your user account; (iv)
        maintain the confidentiality and security of Your user account by protecting Your identification information and
        password and restricting access to Your user account; (v) promptly notify New Energy Group Pty Ltd if You
        discover or otherwise suspect any security breach related to Your user account or any misuse thereof; and (vi)
        take responsibility for all activities that occur under Your user account and accept all risks of any authorized
        or unauthorized access thereto.
      </Text>
      <Text my={2}>
        <strong>3.4</strong> You may authorize Your Authorized Users to access to Your user account and make use of the
        Mobile Application for Your benefit and only for the purposes described in these Terms of Use. You shall be
        responsible to ensure these Terms of Use are made available in a legible manner to each of Your Authorized Users
        before any of them can log and make use of the Mobile Application. You shall be fully responsible for the acts
        and omissions of Your Authorized Users, including for all activities that are made through the use of Your user
        account, and their compliance with these Terms of Use. You shall bear responsibility for all activities that are
        made through the use of Your user account and for any resulting harm caused to You, Your Authorized Users or any
        other third-party, the Mobile Application and/or New Energy Group Pty Ltd. New Energy Group Pty Ltd will have
        the right to rely upon any information received from any legal or individual person accessing to and/or using
        Your user account and New Energy Group Pty Ltd will incur no liability arising out of such reliance.
      </Text>
      <Heading my={3} size="md">
        4. FINANCIAL CONDITIONS
      </Heading>
      <Text my={2}>
        Unless New Energy Group Pty Ltd indicates on the applications&#39; stores making the Mobile Application
        available for download that the payment of a license or other fee is required before You can access or use the
        Mobile Application, the Mobile Application is free for download by the Users; this means that no license or
        other fee is payable by You to New Energy Group Pty Ltd in order to download, access or use the Mobile
        Application. In other cases, the payment of a license or other fee is required before You can download, access
        or use the Mobile Application; if a license or other fee is required such fee will be as stated on the
        applicable applications&#39; stores.
      </Text>
      <Text my={2}>
        Users acknowledge and agree that Users shall solely bear the cost and expense of any toll charges related to or
        arising from such Users&#39; access and use of the Mobile Application through the use of internet or telephone
        services, including but not limited to any costs and expenses related to subscribing to any third-party service
        that allows access to mobile telecommunications and the internet and any cost and expense related to the upload
        or download of any data in or from the Mobile Application.
      </Text>
      <Heading my={3} size="md">
        5. GRANT OF LICENSE
      </Heading>
      <Text my={2}>
        <strong>5.1</strong> Subject to Your acceptance and continuous compliance with all of the terms and conditions
        contained in these Terms of Use, New Energy Group Pty Ltd grants to You a non-exclusive, non-transferable,
        revocable, limited and non-assignable license right to use the Mobile Application in object code form
        (machine-readable) only, without the right to grant any sub-license thereof, for Your own ordinary business
        purposes, to the exclusion of any other purpose, and subject to the terms and conditions contained in these
        Terms of Use and the Documentation.
      </Text>
      <Text my={2}>
        <strong>5.2</strong> The license to use the Mobile Application is granted to Users for the duration and in the
        geographies which are made accessible or indicated on the applications&#39; stores that make the Mobile
        Application available for download. In the absence of accessibility or other license duration or geographic
        restrictions set forth in these Terms of Use, the Documentation or on the applications&#39; store, the license
        right to use the Mobile Application is granted to Users on perpetual (but subject to revocation as set forth
        herein) and worldwide basis.
      </Text>
      <Text my={2}>
        <strong>5.3</strong> The Mobile Application is available to the Users in object code (machine-readable) form
        only and under no circumstances is New Energy Group Pty Ltd obligated to disclose or make available to Users the
        source code of the Mobile Application unless required by mandatory provisions of applicable law.
      </Text>
      <Text my={2}>
        <strong>5.4</strong> You may download and use the Mobile Application on more than one single mobile device if
        the Mobile Application is used on each of said devices under the same user account under which the Mobile
        Application is first downloaded.
      </Text>
      <Text my={2}>
        <strong>5.5</strong> These Terms of Use only give you some rights to use the Mobile Application and Clipsal
        Solar and its licensors reserve all other rights. You do not acquire any rights, express or implied, other than
        those expressly granted in these Terms of Use. You agree that You will use the Mobile Application only as
        expressly permitted in these Terms of Use and in accordance with its Documentation. You agree that You will
        comply with any and all technical limitations in the Mobile Application that only allow You to use the Mobile
        Application in certain ways and that You will not nor will You permit others to circumvent such limitations.
      </Text>
      <Text my={2}>
        <strong>5.6</strong> If technological measures are designed to prevent unlicensed or illegal use of the Mobile
        Application, You agree that New Energy Group Pty Ltd may use those measures to prevent unlicensed or illegal use
        of for any other legal business purpose and You agree to comply with any requirements regarding such
        technological measures. Such measures do not constitute a defect in the Mobile Application nor do they entitle
        You to any warranty rights.
      </Text>
      <Text my={2}>
        <strong>5.7</strong> These Terms of Use describe and govern Your right to download, install and use the Mobile
        Application. It is expressly agreed that these Terms of Use will prevail over and supersede any other terms and
        conditions contained in any purchase order or other document issued or submitted by You in connection with Your
        download, installation or use of the Mobile Application and such other terms and conditions are expressly
        rejected by New Energy Group Pty Ltd to the extent such other terms and conditions conflict with these Terms of
        Use.
      </Text>
      <Text my={2}>
        <strong>5.8</strong> These Terms of Use will also apply to You and shall remain fully enforceable between New
        Energy Group Pty Ltd and You even if You obtained the right to access and use the Mobile Application from an
        authorized New Energy Group Pty Ltd distributor or reseller or a certified New Energy Group Pty Ltd partner.
      </Text>
      <Heading my={3} size="md">
        6. TITLE AND TRADEMARKS
      </Heading>
      <Text my={2}>
        <strong>6.1.</strong> The Mobile Application, as well as all rights, title, interest, technology and know-how,
        whether patented or not, embodied in the Mobile Application, as well as all Intellectual Property Rights
        contained in, associated with or attached to the Mobile Application, including but not limited to copyrights,
        shall remain the sole property of New Energy Group Pty Ltd, to the exclusion of any third-party software
        embedded in or bundled with the Mobile Application or otherwise provided to You with the Mobile Application.
      </Text>
      <Text my={2}>
        <strong>6.2.</strong> Nothing in these Terms of Use shall be deemed or implied to transfer or convey to You any
        of New Energy Group Pty Ltd&#39;s proprietary rights in the Mobile Application; all rights not specifically
        granted in these Terms of Use are reserved by New Energy Group Pty Ltd. New Energy Group Pty Ltd does not sell
        the Mobile Application to You but only grants You the license rights expressly set forth in these Terms of Use.
      </Text>
      <Text my={2}>
        <strong>6.3.</strong> All Intellectual Property Rights pertaining to any third-party software embedded in the
        Mobile Application or otherwise provided to You with the Mobile Application shall remain vested in the relevant
        third-party and there will be no deemed or implied transfer of ownership to You of such third-party proprietary
        rights.
      </Text>
      <Text my={2}>
        <strong>6.4.</strong> Should You become aware of (i) any infringement to any proprietary rights in the Mobile
        Application , or (ii) any claim or lawsuit by a third party against You to the extent such claim alleges that
        the Mobile Application, used in accordance with these Terms of Use, infringe any patent, copyright or trademark,
        or misappropriates a trade secret of a third party, You shall immediately inform New Energy Group Pty Ltd of
        such infringement and provide all relevant information and reasonable cooperation requested by New Energy Group
        Pty Ltd to defend its rights and interests.
      </Text>
      <Text my={2}>
        <strong>6.5.</strong> New Energy Group Pty Ltd and other trademarks affixed or contained in the Mobile
        Application and/or its Documentation are registered trademarks of the New Energy Group Pty Ltd or its
        Affiliate(s). Except as otherwise expressly prescribed by mandatory provisions of applicable law, You may not
        remove or alter any trademark, trade names, product names, logo, copyright or other proprietary notices,
        legends, symbols or labels in the Mobile Application. These Terms of Use do not authorise You to use any names
        or trademarks, trade names, product names, logo, copyright or other proprietary notices, legends, symbols or
        labels of New Energy Group Pty Ltd, its Affiliates, its/their third-party licensors or authorised resellers.
      </Text>
      <Heading my={3} size="md">
        7. RESTRICTIONS ON USE
      </Heading>
      <Text my={2}>
        <strong>7.1.</strong> You may only download, install, access, use and display the Mobile Application on the type
        of mobile devices and operating systems on which the Mobile Application is compatible for use as referred to in
        these Terms of Use or the Documentation, strictly in accordance with these Terms of Use and the Documentation
        and only for the specific purposes stated in these Terms of Use and the Documentation.
      </Text>
      <Text my={2}>
        <strong>7.2.</strong> Furthermore, unless otherwise expressly required or authorized by statute, You may not,
        nor are You entitled to permit or cause others to do any of the following:
      </Text>
      <Text my={2}>
        (i) copy the Mobile Application except for backup purposes only in support of Your permitted use of the Mobile
        Application. Any such copy must include all copyright notices and any other proprietary legends present on the
        original Mobile Application. You may not sell, lease, license, rent or in other ways transfer any copy of the
        Mobile Application. If this Mobile Application contains Documentation that is provided only in electronic form
        or online, You may print one copy of such electronic Documentation for each license acquired to the Mobile
        Application. If this Mobile Application contains Documentation that is provided in printed form, You may make
        one copy of such printed Documentation for each license acquired for the Mobile Application;
      </Text>
      <Text my={2}>
        (ii) modify, adapt, translate, reverse engineer, decompile, disassemble or otherwise seek to reconstitute the
        source code of the Mobile Application, nor create derivative works from the Mobile Application. Further, in case
        of errors, bugs or defects of the Mobile Application, New Energy Group Pty Ltd expressly reserves the right to
        correct such errors, bugs or defects to the extent permitted by law but is not obligated to make such
        correction;
      </Text>
      <Text my={2}>
        (iii) sub-license, hire, lease, outsource or rent the Mobile Application, use the Mobile Application in any
        resale activity or permit a third-party to use the Mobile Application for its or anyone else&#39;s benefit;
      </Text>
      <Text my={2}>
        (iv) other than as expressly permitted in these Terms of Use, distribute in whole or part, modify, or create
        derivatives of, the Mobile Application or distribute applications created with the Mobile Application; or
      </Text>
      <Text my={2}>
        (v) directly or indirectly, export, re-export, download, or ship the Mobile Application in violation of the laws
        and regulations of Australia, the U.S.A. or the applicable jurisdiction in which You download and/or use the
        Mobile Application.
      </Text>
      <Text my={2}>
        <strong>7.3.</strong> Should You not fully comply with the above provisions, You shall bear any and all
        consequences, including any damages whatsoever (including but not limited to damages incurred by New Energy
        Group Pty Ltd and its Affiliates), resulting therefrom.
      </Text>
      <Text my={2}>
        <strong>7.4.</strong> The use of the Mobile Application is intended only for use with contents owned by You,
        public domain contents or properly licensed content. You may need a patent, copyright, or other license from a
        third-party to create, copy, download, record or save content files for use with the Mobile Application or to
        serve or distribute such files to be used with the Mobile Application; in such case, it is Your sole
        responsibility to obtain such license and you shall solely bear the cost and expense of obtaining such license.
      </Text>
      <Text my={2}>
        <strong>7.5.</strong> You may not use the Mobile Application in an attempt to, or in conjunction with any
        device, program or service designed to, circumvent technological measures employed to control access to, or the
        rights in, a content file or other work protected by the copyright or other laws of any jurisdiction.
      </Text>
      <Text my={2}>
        <strong>7.6.</strong> You agree that You shall only use the Mobile Application in a manner that complies with
        all applicable laws in the jurisdictions in which You download and/or use the Mobile Application, including, but
        not limited to, applicable restrictions concerning copyright and other Intellectual Property Rights.
      </Text>
      <Heading my={3} size="md">
        8. USER DATA
      </Heading>
      <Text my={2}>
        <strong>8.1.</strong> User Data may be provided by User as it determines under its own responsibility to
        facilitate its use of the Mobile Application. User shall have sole responsibility for the accuracy, quality,
        integrity, legality, reliability, appropriateness and ownership of all User Data it uploads, collects, stores
        and/or processes in the Mobile Application, and/or creates and/or modifies, through the User&#39;s use of the
        Mobile Application. User retains any and all rights it may possess to User Data. By accepting these Terms of
        Use, User grants New Energy Group Pty Ltd a non-exclusive, irrevocable, worldwide, royalty-free, paid-up,
        transferable and sub- licensable license right to collect, store, use, import, distribute, modify and distribute
        modifications of, perform, translate, copy and display User Data for the sole purpose of and in conjunction only
        with enabling the use of the Mobile Application by User in accordance with these Terms of Use. User represents
        that User has and will keep in effect all licenses and approvals necessary to grant the foregoing license
        rights, at no charge or expense, to New Energy Group Pty Ltd. User represents that User is not exceeding any
        specified entitlement or permitted use or violating applicable license agreements or applicable laws to grant
        the foregoing license rights. User agrees to indemnify and hold harmless New Energy Group Pty Ltd from any
        third-party claims and any costs, expenses and other amounts that New Energy Group Pty Ltd may incur or
        otherwise be subject to because of User&#39;s breach of this Section 8.1.
      </Text>
      <Text my={2}>
        <strong>8.2.</strong> User is solely responsible for determining the requirements regarding security of User
        Data. New Energy Group Pty Ltd accepts no liability for any corruption, loss or theft of data caused by security
        breaches resulting from internet connectivity and/or the environment or systems used by User to access and use
        the Mobile Application, including without limitation security breaches resulting from hackers, unlawful entry or
        unauthorized access. User is solely responsible to ensure at its own cost and expense any maintenance, testing,
        backup and support of User Data that User may determine to be appropriate.
      </Text>
      <Text my={2}>
        <strong>8.3.</strong> In case the Mobile Application enables the User to upload, collect, store, process, create
        and/or modify User Data within the Mobile Application, User Data shall be stored within the Mobile Application
        only as long as (i) its user account shall be active (when User has created an user account in the Mobile
        Application) or otherwise (ii) the time period of the license right granted to User to use the Mobile
        Application is not expired or terminated in accordance with these Terms of Use and New Energy Group Pty Ltd
        shall have no obligation or liability to return User Data to User at any time.
      </Text>
      <Text my={2}>
        <strong>8.4</strong>. New Energy Group Pty Ltd shall not disclose User Data to any third party – other than its
        suppliers involved in the provision of the Mobile Application- without User&#39;s prior express consent, unless
        disclosure of User Data is required in order for (i) New Energy Group Pty Ltd or its Affiliates to comply with
        any legal obligation applicable to New Energy Group Pty Ltd or its Affiliates, (ii) New Energy Group Pty Ltd or
        its Affiliates to comply with any applicable order by a court of competent jurisdiction or any applicable order
        of a governmental agency or (iii) New Energy Group Pty Ltd or its Affiliates to defend or prosecute their rights
        under these Terms of Use or in the frame of any litigation.
      </Text>
      <Heading my={3} size="md">
        9. PERSONAL INFORMATION PROTECTION
      </Heading>
      <Text my={2}>
        <strong>9.1</strong> User&#39;s Personal Information will be processed by New Energy Group Pty Ltd as described
        in privacy notices and policies, including New Energy Group Pty Ltd Privacy Policy at{' '}
        <a href={PRIVACY_POLICY_URL}>{PRIVACY_POLICY_URL}</a>, made available to User by New Energy Group Pty Ltd. The
        aforesaid policy and its address on New Energy Group Pty Ltd&#39;s corporate and/or local websites is subject to
        change by New Energy Group Pty Ltd for any lawful purpose including but not limited to in order to comply with
        changes to applicable data protection laws.
      </Text>
      <Text my={2}>
        <strong>9.2</strong> User is responsible for any third party Personal Information that User uploads, stores,
        creates, uses, shares, modifies, deletes or otherwise processes with the Mobile Application. User must comply
        with all applicable laws and regulations, obtain all required consents and make all required notifications in
        this respect.
      </Text>
      <Heading my={3} size="md">
        10. WARRANTIES AND DISCLAIMERS OF WARRANTIES
      </Heading>
      <Text my={2}>
        <strong>10.1</strong> NEW ENERGY GROUP PTY LTD WARRANTS THAT IT IS ENTITLED TO LICENSE AND OTHERWISE MAKE
        AVAILABLE THE MOBILE APPLICATION TO YOU IN ACCORDANCE WITH THE TERMS AND CONDITIONS SET OUT IN THESE TERMS OF
        USE.
      </Text>
      <Text my={2}>
        <strong>10.2</strong> IF THE MOBILE APPLICATION WAS DOWNLOADED BY YOU OR PROVIDED TO YOU FOR FREE WITH NO
        LICENSE OR OTHER FEE PAYABLE TO NEW ENERGY GROUP PTY LTD, THEN THE MOBILE APPLICATION IS PROVIDED TO YOU ON
        "AS-IS" BASIS AND "AS AVAILABLE" BASIS. WITHOUT PREJUDICE TO MANDATORY WARRANTIES THAT SHALL APPLY UNDER
        APPLICABLE LAW, NEW ENERGY GROUP PTY LTD EXPRESSLY DISCLAIMS ALL WARRANTIES, CONDITIONS AND REPRESENTATIONS,
        EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE MOBILE APPLICATION, ITS DOCUMENTATION, THEIR UPDATES AND
        UPGRADES.
      </Text>
      <Text my={2}>
        <strong>10.3</strong> IF THE MOBILE APPLICATION WAS DOWNLOADED BY YOU OR PROVIDED TO YOU FOR A LICENSE OR OTHER
        FEE PAYABLE TO NEW ENERGY GROUP PTY LTD THEN THE FOLLOWING WARRANTY APPLIES TO YOU: FOR A PERIOD OF NINETY (90)
        DAYS FROM THE DATE WHEN YOU FIRST DOWNLOAD, ACCESS OR USE THE MOBILE APPLICATION (WHICHEVER OCCURS FIRST), NEW
        ENERGY GROUP PTY LTD WARRANTS THAT THE MOBILE APPLICATION WILL PERFORM SUBSTANTIALLY IN ACCORDANCE WITH THE
        DOCUMENTATION.
      </Text>
      <Text my={2}>
        NEW ENERGY GROUP PTY LTD&#39;S SOLE OBLIGATION AND YOUR SOLE REMEDY WITH RESPECT TO THE FOREGOING LIMITED
        WARRANTY SHALL BE, AT NEW ENERGY GROUP PTY LTD&#39;S OPTION, TO FIX OR PROVIDE A WORK-AROUND TO, THE DEFECT OR
        NON-COMPLIANCE OF THE MOBILE APPLICATION WITHOUT CHARGE TO YOU, PROVIDED THAT (A) YOU GIVE NOTICE OF THE DEFECT
        TO NEW ENERGY GROUP PTY LTD WITHIN THE ABOVEMENTIONED WARRANTY PERIOD, AND (B) THE DEFECT DOES NOT FALL UNDER
        THE EXCLUSIONS SET FORTH IN SECTIONS 10.4 AND 10.5 BELOW.
      </Text>
      <Text my={2}>
        <strong>10.4</strong> NEW ENERGY GROUP PTY LTD&#39;S WARRANTY SHALL BE EXCLUDED AND NEW ENERGY GROUP PTY LTD
        SHALL HAVE NO WARRANTY OBLIGATIONS TO THE EXTENT THE MOBILE APPLICATION HAS BEEN ALTERED OR FAILS TO PERFORM IN
        ANY WAY AS THE RESULT OF YOUR NEGLIGENT OR UNAUTHORIZED USE, CONFIGURATION OR INSTALLATION OF THE MOBILE
        APPLICATION INCLUDING BUT NOT LIMITED TO THE USE OF THE MOBILE APPLICATION WITH ANY HARDWARE, SOFTWARE,
        FIRMWARE, OPERATING SYSTEM OR SOLUTION NOT SUPPLIED, NOT SPECIFIED OR NOT APPROVED BY NEW ENERGY GROUP PTY LTD
        TO BE USED WITH THE MOBILE APPLICATION, OR THE UNAUTHORIZED MAINTENANCE OF THE MOBILE APPLICATION.
      </Text>
      <Text my={2}>
        <strong>10.5</strong> NEW ENERGY GROUP PTY LTD&#39;S WARRANTY SHALL ALSO BE EXCLUDED AND NEW ENERGY GROUP PTY
        LTD SHALL HAVE NO WARRANTY OBLIGATIONS IN CASE OF DEFECT OR NON- COMPLIANCE OF THE MOBILE APPLICATION, TO THE
        EXTENT SUCH DEFECT OR NON- COMPLIANCE COULD HAVE BEEN PREVENTED BY MAKING USE OF AN UPDATE OR UPGRADE TO THE
        MOBILE APPLICATION THAT NEW ENERGY GROUP PTY LTD MADE AVAILABLE TO YOU IN ANY MANNER WHATSOEVER.
      </Text>
      <Text my={2}>
        <strong>10.6</strong> WITHOUT PREJUDICE TO ANY STATUTORY WARRANTIES THAT CANNOT BE EXCLUDED AND TO THE FULLEST
        EXTENT PERMITTED BY LAW (ON BOTH FEDERAL AND STATE LEVELS, WHEN APPLICABLE) IN ANY COUNTRY WHERE YOU WILL
        DOWNLOAD, INSTALL OR USE THE MOBILE APPLICATION, NEW ENERGY GROUP PTY LTD MAKES NO OTHER WARRANTY THAN THOSE
        CONTAINED IN THIS SECTION 10 AND EXPRESSLY DISCLAIMS ALL OTHER WARRANTIES, CONDITIONS AND REPRESENTATIONS,
        EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE MOBILE APPLICATION, ITS DOCUMENTATION, THEIR UPDATES AND
        UPGRADES, INCLUDING WITHOUT LIMITATION ANY WARRANTIES, CONDITIONS AND REPRESENTATIONS OF MERCHANTABILITY,
        NON-INFRINGEMENT OF THIRD-PARTY RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. FURTHER, WHILE NEW ENERGY GROUP PTY
        LTD HAS TAKEN REASONABLE STEPS TO ENSURE THE ACCURACY OF THE INFORMATION CONTAINED IN OR SHOWN BY THE MOBILE
        APPLICATION AND ITS DOCUMENTATION, NEW ENERGY GROUP PTY LTD MAKES NO WARRANTY OR REPRESENTATION OF ANY KIND,
        WHETHER EXPRESS OR IMPLIED, AS TO WHETHER THE MOBILE APPLICATION OR ANY INFORMATION CONTAINED IN OR SHOWN BY THE
        MOBILE APPLICATION AND ITS DOCUMENTATION WILL MEET YOUR REQUIREMENTS, EXPECTATIONS OR PURPOSES OR THAT THE
        OPERATION OF THE MOBILE APPLICATION WILL BE UNINTERRUPTED OR ERROR- FREE OR THAT THE MOBILE APPLICATION WILL BE
        PROTECTED AGAINST ALL POSSIBLE SECURITY THREATS, INTERNET THREATS OR OTHER THREATS OR INTERRUPTIONS.
      </Text>
      <Text my={2}>
        <strong>10.7</strong> NO ORAL OR WRITTEN INFORMATION, STATEMENT, OPINION OR ADVICE GIVEN BY NEW ENERGY GROUP PTY
        LTD, ITS EMPLOYEES OR ITS AUTHORIZED DISTRIBUTORS OR RESELLERS OR CERTIFIED PARTNERS, OR ANYONE ELSE ON ITS
        BEHALF, SHALL CREATE ANY LIABILITY OR IN ANY WAY EXTEND OR VARY THE SCOPE OF THE WARRANTIES EXPRESSED IN THESE
        TERMS OF USE.
      </Text>
      <Text my={2}>
        <strong>10.8</strong> EXCEPT FOR THE WARRANTIES STATED UNDER THIS SECTION 10, NEW ENERGY GROUP PTY LTD SHALL NOT
        HAVE ANY OBLIGATION TO PROVIDE YOU WITH ANY MAINTENANCE, SUPPORT OR CORRECTION SERVICES IN CONNECTION WITH THE
        MOBILE APPLICATION OTHER THAN THOSE YOU AND NEW ENERGY GROUP PTY LTD MAY HAVE AGREED UPON BY WAY OF A SEPARATE
        AGREEMENT.
      </Text>
      <Heading my={3} size="md">
        11. LIABILITIES
      </Heading>
      <Text my={2}>
        <strong>11.1</strong> IN NO EVENT SHALL NEW ENERGY GROUP PTY LTD (BY REASON OF ITS OWN ACTS OR OMISSIONS OR
        THOSE OF ANY THIRD-PARTY INVOLVED IN THE CREATION, PRODUCTION OR DELIVERY OF THE MOBILE APPLICATION, INCLUDING
        BUT NOT LIMITED TO NEW ENERGY GROUP PTY LTD&#39;S LICENSORS) BE LIABLE TO YOU OR ANY THIRD-PARTY FOR ANY LOSS OF
        REVENUE, LOSS OF BUSINESS, LOSS OF PROFITS OR CONTRACTS, LOSS OF SAVINGS, BUSINESS INTERRUPTION, LOSS OF DATA,
        LOSS OF REPUTATION OR WASTE OF MANAGEMENT OR OFFICE TIME OR OTHER INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
        PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, ARISING OUT OF OR IN CONNECTION WITH THE PERFORMANCE, USE,
        INABILITY TO USE OR MISUSE OF THE MOBILE APPLICATION, WHETHER BASED ON CONTRACT, TORT, WARRANTY OR OTHER LEGAL
        GROUND AND EVEN IF NEW ENERGY GROUP PTY LTD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </Text>
      <Text my={2}>
        <strong>11.2</strong> NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS OF USE, AND EXCEPT IN CASE OF (i)
        NEW ENERGY GROUP PTY LTD&#39;S FRAUD OR FRAUDULENT MISREPRESENTATION, (ii) NEW ENERGY GROUP PTY LTD&#39;S WILFUL
        MISCONDUCT OR GROSS NEGLIGENCE, OR (iii) DEATH OR INJURY TO AN INDIVIDUAL PERSON WHERE NO LIMITATION OF
        LIABILITY SHALL APPLY, NEW ENERGY GROUP PTY LTD&#39;S AGGREGATE AND ENTIRE LIABILITY UNDER ANY AND ALL
        PROVISIONS OF THESE TERMS OF USE OR OTHERWISE ARISING OUT OF OR IN CONNECTION WITH THE MOBILE APPLICATION OR ITS
        DOCUMENTATION, SHALL BE LIMITED TO AND SHALL IN NO EVENT EXCEED (I) THE TOTAL AMOUNT WITHOUT TAX YOU PAID TO
        ACQUIRE THE RIGHT TO USE THE MOBILE APPLICATION WITH RESPECT TO WHICH LIABILITY IS CLAIMED OR (II) FIFTY (50)
        AUD IN CASE THE MOBILE APPLICATION IS MADE AVAILABLE TO YOU FOR FREE WITH NO LICENSE OR OTHER FEE PAYABLE TO NEW
        ENERGY GROUP PTY LTD.
      </Text>
      <Text my={2}>
        <strong>11.3</strong> NEW ENERGY GROUP PTY LTD&#39;S LIABILITY ARISING OUT OF THESE TERMS OF USE IS REDUCED
        PROPORTIONALLY TO THE EXTENT TO WHICH THE ACT OR OMISSION OF YOU OR ANY OTHER THIRD PERSON CONTRIBUTED TO THE
        LOSS OR DAMAGE INCURRED.
      </Text>
      <Text my={2}>
        <strong>11.4</strong> NEW ENERGY GROUP PTY LTD SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR THE USE BY YOU
        OR ANY THIRD PARTY OF ANY USER DATA UPLOADED TO OR DOWNLOADED FROM THE MOBILE APPLICATION OR CREATED OR MODIFIED
        IN CONNECTION WITH THE USE OF THE MOBILE APPLICATION, NOR FOR THE CONSEQUENCES OF ANY DECISION, ACT OR OMISSION,
        SUCH AS BUT NOT LIMITED TO ANY ELECTRICAL ASSEMBLY, INSTALLATION OR MAINTENANCE, THAT YOU OR ANY THIRD-PARTY MAY
        MAKE BASED ON OR IN RELIANCE ON ANY USER DATA.
      </Text>
      <Text my={2}>
        <strong>11.5</strong> IN NO EVENT WILL NEW ENERGY GROUP PTY LTD BE LIABLE TO YOU FOR (I) ANY DAMAGES CAUSED BY
        YOUR FAILURE TO PERFORM YOUR OBLIGATIONS UNDER THESE TERMS OF USE OR TO FOLLOW THE DOCUMENTATION; OR (II) ANY
        CLAIMS OR DEMANDS OF THIRD PARTIES EXCEPT AS EXPRESSLY STATED IN THESE TERMS OF USE.
      </Text>
      <Text my={2}>
        <strong>11.6</strong> IN CASE THE RIGHT TO ACCESS AND USE THE MOBILE APPLICATION WAS FURNISHED TO YOU BY ANY
        AUTHORIZED NEW ENERGY GROUP PTY LTD DISTRIBUTOR OR RESELLER OR ANY NEW ENERGY GROUP PTY LTD CERTIFIED PARTNER,
        WITH OR WITHOUT A THIRD-PARTY PRODUCT, NEW ENERGY GROUP PTY LTD SHALL IN NO EVENT BE A PARTY TO ANY PURCHASE
        ORDER OR OTHER AGREEMENT BETWEEN YOU AND SUCH DISTRIBUTOR, RESELLER OR PARTNER AND SHALL NOT ASSUME OR OTHERWISE
        BEAR ANY LIABILITY THEREUNDER.
      </Text>
      <Text my={2}>
        <strong>11.7</strong> YOU ACKNOWLEDGE THAT THE USE OF THE MOBILE APPLICATION MAY BE SUBJECT TO LIMITATIONS,
        DELAYS AND POTENTIAL FAILURE DUE TO THE INHERENT RISKS OF TELECOMMUNICATIONS NETWORKS AND THE INTERNET. CLIPSAL
        SOLAR SHALL NOT BE RESPONSIBLE FOR ANY DELAY OR FAILURE RESULTING FROM THE AFORESAID LIMITATIONS, DELAYS AND
        POTENTIAL FAILURES.
      </Text>
      <Text my={2}>
        <strong>11.8</strong> THE EXCLUSIONS, LIMITATIONS AND DISCLAIMERS OF WARRANTY OR LIABILITY STATED IN THESE TERMS
        OF USE SHALL APPLY ONLY TO THE EXTENT PERMITTED BY THE LAW APPLICABLE TO THESE TERMS OF USE AND DO NOT AFFECT OR
        PREJUDICE STATUTORY RIGHTS WHICH, AS THE CASE MAY BE, BENEFIT YOU UNDER MANDATORY OR PUBLIC ORDER LAWS OR
        REGULATIONS APPLICABLE IN ANY COUNTRY WHERE YOU WILL DOWNLOAD, INSTALL OR USE THE MOBILE APPLICATION.
      </Text>
      <Heading my={3} size="md">
        12. INDEMNIFICATION
      </Heading>
      <Text my={2}>
        <strong>12.1</strong> You shall defend, indemnify, and hold harmless New Energy Group Pty Ltd, its Affiliates
        and each of their respective officers, directors, employees, licensors, contractors, successors and assigns from
        and against any and all claims, actions, suits, demands, proceedings, judgments, liabilities, losses, damages,
        costs and expenses (including without limitation reasonable attorneys&#39; fees and court costs) arising from or
        related to (i) any User Data; (ii) any breach by You of any of Your obligations under these Terms of Use such as
        but not limited to any unauthorized use of the Mobile Application; (iii) any misuse by You of the Mobile
        Application such as but not limited to Your use of the Mobile Application in a manner contrary to its
        Documentation, (iv) any failure by You to comply with any applicable law and/or regulation in connection with
        Your use of the Mobile Application, (v) any use of the Mobile Application with any hardware, software, firmware,
        operating system or solution not supplied, not specified or otherwise not approved by New Energy Group Pty Ltd
        to be used with the Mobile Application; and/or (vi) any modification, alteration or maintenance of the Mobile
        Application by anyone other than New Energy Group Pty Ltd without the written approval of New Energy Group Pty
        Ltd.
      </Text>
      <Text my={2}>
        <strong>12.2</strong> Subject to the limitations set forth in Section 11, New Energy Group Pty Ltd will defend
        and indemnify You against a third-party claim that the Mobile Application infringes any copyright enforceable in
        the jurisdiction where New Energy Group Pty Ltd has its registered office or principal place of business, or
        misappropriates any trade secret protected under the laws of such jurisdiction (the "Included Jurisdiction"),
        provide that: (i) You notify New Energy Group Pty Ltd of the claim in writing immediately upon the Your
        awareness of such claim; (ii) New Energy Group Pty Ltd has sole authority and control of the defence of the
        claim and all related settlement negotiations; and (iii) You provide New Energy Group Pty Ltd with the
        assistance, information and authority necessary in order for New Energy Group Pty Ltd to handle the defence and
        settlement of such claim and perform its obligations under this Section 12.2. If the Mobile Application is held
        or believed by New Energy Group Pty Ltd to constitute an infringement or misappropriation as per this Section
        12.2, then New Energy Group Pty Ltd will have the option, at its expense, to: (i) obtain for You a license to
        continue using the Mobile Application; (ii) replace or modify the Mobile Application to make it non-infringing;
        or (iii) if in New Energy Group Pty Ltd&#39;s sole discretion, it is not economically or commercially reasonable
        to perform either (i) or (ii) above, then New Energy Group Pty Ltd may terminate the license for the infringing
        Mobile Application and refund to You the license or other fee (if any) You paid to New Energy Group Pty Ltd for
        the infringing Mobile Application. This Section 12.2 states Your sole and exclusive remedy and New Energy Group
        Pty Ltd&#39;s sole liability for any claim under this Section 12.2.
      </Text>
      <Text my={2}>
        <strong>12.3</strong> To the extent permitted by law, New Energy Group Pty Ltd will have no liability to You
        under Section 12.2. of these Terms of Use for any claim that arises out of or relates to: (i) any User Data;
        (ii) any breach by You of any of Your obligations under these Terms of Use such as but not limited to any
        unauthorized use of the Mobile Application; (iii) any misuse by You of the Mobile Application such as but not
        limited to Your use of the Mobile Application in a manner contrary to its Documentation, (iv) any failure by You
        to comply with any applicable law and/or regulation in connection with Your use of the Mobile Application, (v)
        any use of the Mobile Application with any hardware, software, firmware, operating system or solution not
        supplied, not specified or otherwise not approved by New Energy Group Pty Ltd to be used with the Mobile
        Application; and/or (vi) any modification, alteration or maintenance of the Mobile Application by anyone other
        than New Energy Group Pty Ltd without the written approval of New Energy Group Pty Ltd; (vii) Your use of the
        Mobile Application after notice by New Energy Group Pty Ltd or any appropriate authority to You of the Mobile
        Application&#39;s alleged or actual infringement or misappropriation of any third-party&#39;s Intellectual
        Property Right; or (viii) Your failure to use corrections or enhancements made available by New Energy Group Pty
        Ltd.
      </Text>
      <Heading my={3} size="md">
        13. THIRD-PARTY WEBSITES AND CONTENTS
      </Heading>
      <Text my={2}>
        In case the Mobile Application enables the User to link to, transmit User Data to, indicate the web address of
        or otherwise access to, any third party&#39;s website or third-party&#39;s contents, products, services or
        information, the User shall bear all risks associated with the access and use of such third-parties&#39;
        websites and /or contents, products, services and information. New Energy Group Pty Ltd does not control and is
        not responsible for such third-parties&#39; websites or any such third-parties&#39; contents, products, services
        and information accessible from or provided through such third-parties&#39; websites. Any access to
        third-parties&#39; websites or any use of third-parties&#39; contents, products, services or information through
        Your access and/or Your use of the Mobile Application shall be subject to said third-parties&#39; own terms of
        use or other legal document which shall govern the relationship between You and said third-parties with respect
        thereto.
      </Text>
      <Heading my={3} size="md">
        14. EXPORT CONTROL
      </Heading>
      <Text my={2}>
        You agree that You will comply with all applicable local (on both federal and state levels, when applicable) and
        international laws and regulations, including but not limited to export control laws and regulations of
        Australia, the United States of America or other jurisdictions, that relate to Your use of the Mobile
        Application.
      </Text>
      <Text my={2}>
        The export of products, software, technology or information may be subject to control or restriction by
        applicable laws or regulations on the control of export, notably the United States Export Administration Act and
        the regulations thereunder, and the European Union Regulation applicable to dual use products and technologies.
        You are solely responsible for determining the existence and application of any such law or regulation to any
        proposed export by You or Your representatives of the Mobile Application or any service based or otherwise
        implementing Your right to access and use the Mobile Application and for performing any declaration or obtaining
        any required authorisation in relation therewith. You agree not to export the Mobile Application or any service
        based or otherwise implementing Your right to access and use the Mobile Application from any country in
        violation of any applicable legal or regulatory obligations or restrictions on that export.
      </Text>
      <Text my={2}>
        In the event the aforementioned legal or regulatory obligations or restrictions are violated by You or any of
        Your representatives in relation with the export of the Mobile Application or any service based or otherwise
        implementing Your right to access and use the Mobile Application, You shall indemnify and hold harmless Clipsal
        Solar, its Affiliates and each of their respective officers, directors, employees, licensors, contractors,
        successors and assigns harmless from any claims and compensate the same against any damages claimed by any
        third-party (including but not limited to governmental and/or international authorities and/or organizations) as
        a result of any such violation by You or Your representative(s).
      </Text>
      <Heading my={3} size="md">
        15. ASSIGNMENT
      </Heading>
      <Text my={2}>
        Your rights and obligations under these Terms of Use may not be sold, sub-licensed, rented, assigned, delegated,
        transferred or otherwise conveyed by You or Your representatives without New Energy Group Pty Ltd&#39;s prior
        express written consent. The license granted to You under these Terms of Use is granted in consideration of Your
        person ("<em>intuitu personae</em>"). New Energy Group Pty Ltd may assign its rights and obligations under these
        Terms of Use to any Affiliate or to any company New Energy Group Pty Ltd may acquire control of or merge with.
        Subject to the foregoing, these Terms of Use shall be binding upon and inure to the benefit of the parties
        hereto, their successors and permitted assigns.
      </Text>
      <Heading my={3} size="md">
        16. TERMINATION
      </Heading>
      <Text my={2}>
        <strong>16.1</strong> While not obligated to perform investigations, New Energy Group Pty Ltd may investigate
        violations of these Terms of Use or misuse of the Mobile Application and cooperate with appropriate law
        enforcement authorities with respect to security threats, fraud, or other illegal, malicious, or inappropriate
        activity by You or any third-party through Your access to and/or Your use of the Mobile Application.
      </Text>
      <Text my={2}>
        <strong>16.2</strong> New Energy Group Pty Ltd is entitled, at any time and without notice or liability to You,
        to terminate the license right granted to You to use the Mobile Application if You breach any of Your
        obligations under these Terms of Use or the other legal documents incorporated herein or You violate any
        applicable laws or regulations or otherwise infringe any Intellectual Property Rights in the access to or the
        use of the Mobile Application. Termination by New Energy Group Pty Ltd of the license right granted to You to
        use the Mobile Application shall not waive or affect any other right or remedies to which New Energy Group Pty
        Ltd may be entitled under these Terms of Use, at law or, if applicable, in equity. Without prejudice to the
        foregoing, termination by New Energy Group Pty Ltd of the license right granted to You to use the Mobile
        Application shall automatically and immediately cause the termination of the legal agreement formed between You
        and New Energy Group Pty Ltd by Your acceptance of these Terms of Use.
      </Text>
      <Text my={2}>
        <strong>16.3</strong> Upon expiration or termination of the license right granted to You hereunder, You
        undertake to immediately discontinue the use of the Mobile Application and You must delete or expunge the Mobile
        Application from all computers, hard disks, servers, tablets, smart phones or other mobile devices onto which
        You downloaded it. You shall upon New Energy Group Pty Ltd&#39;s request provide New Energy Group Pty Ltd with a
        written certification that You have carried out the required actions set out in this Section 16.3.
      </Text>
      <Text my={2}>
        <strong>16.4</strong> Termination by New Energy Group Pty Ltd of the license right granted to You hereunder and
        thereby the Agreement shall not waive or affect any other right or remedies to which New Energy Group Pty Ltd
        may be entitled under these Terms of Use, at law or, if applicable, in equity in connection to or arising out of
        Your breach, violation or infringement as stated above. Termination by New Energy Group Pty Ltd of the license
        right granted to You hereunder and thereby the Agreement does not affect any rights or remedies which, before
        said termination, may have accrued to You or New Energy Group Pty Ltd under these Terms of Use, at law or, if
        applicable, in equity, in connection to or arising out of such license.
      </Text>
      <Heading my={3} size="md">
        17. MISCELLANEOUS
      </Heading>
      <Text my={2}>
        <strong>17.1</strong> New Energy Group Pty Ltd is an independent contractor. Each of You and New Energy Group
        Pty Ltd agree that no partnership, joint venture, or agency relationship exists or is created by these Terms of
        Use between You and New Energy Group Pty Ltd. Neither You nor New Energy Group Pty Ltd is granted any right or
        authority to assume or create any obligation or responsibility, express or implied, on behalf of or in the name
        of one another.
      </Text>
      <Text my={2}>
        <strong>17.2</strong> No third-party beneficiary relationship is created by these Terms of Use. The Agreement is
        not intended to, and does not confer any rights, benefits or remedies upon any person other than You and Clipsal
        Solar.
      </Text>
      <Text my={2}>
        <strong>17.3</strong> These Terms of Use constitute the entire agreement between You and New Energy Group Pty
        Ltd in relation to Your right to use the Mobile Application and replaces any previous agreement or
        understanding, whether oral, electronic or written, in relation to the same subject matter. The Documentation
        forms an integral part of the license right granted under these Terms of Use. In case of a discrepancy between
        any provision of these Terms of Use and the provisions of the Documentation, the provisions of these Terms of
        Use shall prevail.
      </Text>
      <Text my={2}>
        <strong>17.4</strong> Should any provision of these Terms of Use be declared invalid or unenforceable by any
        competent jurisdiction, such provision of these Terms of Use shall be amended by New Energy Group Pty Ltd to
        achieve as nearly as possible its inner intent, and all other provisions of these Terms of Use shall remain
        valid and in full force and effect.
      </Text>
      <Text my={2}>
        <strong>17.5</strong> All notices sent in pursuance of these Terms of Use shall be in writing. Notices of a
        general purpose related to the Mobile Application shall be given by New Energy Group Pty Ltd by means of a
        general information posted on New Energy Group Pty Ltd&#39;s corporate website, the applications&#39; stores
        that make the Mobile Application available for download and/or through the Mobile Application itself. Notices
        specifically addressed to You shall be given by New Energy Group Pty Ltd, at its election, by electronic mail to
        the electronic address on record in the information You provided to download the Mobile Application or, when
        applicable, to create Your user account within the Mobile Application, or by written communication sent by first
        class mail or pre-paid post to the address of Your registered office or principal place of business. Any notice
        by the User to New Energy Group Pty Ltd shall be in writing sent by first class mail or pre-paid post to the
        address of New Energy Group Pty Ltd&#39;s registered office or principal place of business.
      </Text>
      <Text my={2}>
        <strong>17.6</strong> New Energy Group Pty Ltd&#39;s failure to exercise or enforce any right, power or remedy
        under the Agreement shall not operate or be construed as a waiver thereof.
      </Text>
      <Text my={2}>
        <strong>17.7</strong> Headings in these Terms of Use are just for ease of reference and will not affect its
        interpretation.
      </Text>
      <Text my={2}>
        <strong>17.8</strong> Words expressed in the singular include the plural and vice versa.
      </Text>
      <Text my={2}>
        <strong>17.9</strong> Section 6 "Title and trademarks", Section 8 "User Data", Section 10 "Warranties and
        disclaimers of warranties", Section 11 "Liabilities", Section 12 "Indemnification", Section 14 "Export Control",
        Section 16 "Termination", Section 17 "Miscellaneous" and Section 18 "Applicable Law and Disputes Resolution" of
        these Terms of Use shall survive termination or expiration of these Terms of Use or the license right granted to
        You hereunder. Furthermore, provisions that by their nature are intended to survive termination or expiration of
        these Terms of Use or the license right granted to You hereunder, shall survive such termination or expiration.
      </Text>
      <Text my={2}>
        <strong>17.10</strong> New Energy Group Pty Ltd reserves the right, at its sole discretion, to amend, add or
        remove any provision to or from these Terms of Use at any time. Any such amendment, addition or removal to these
        Terms of Use will be posted by New Energy Group Pty Ltd on its corporate website located at{' '}
        <a href="https://clipsalcortex.com">https://clipsalcortex.com</a>, the applications&#39; stores availing the
        Mobile Application for download and/or through the Mobile Application itself. New Energy Group Pty Ltd
        recommends that every User, including returning Users, carefully review the then-current Terms of Use posted on
        New Energy Group Pty Ltd&#39;s corporate website or the applications&#39; stores and/or through the Mobile
        Application. It is the User&#39;s responsibility to check the then- current Terms of Use prior to use of the
        Mobile Application or any updates or upgrades thereto. The User&#39;s continued use of the Mobile Application
        after any amendment, addition or removal to these Terms of Use is published by New Energy Group Pty Ltd, shall
        be deemed as the User&#39;s acceptance and agreement to the changed Terms of Use.
      </Text>
      <Text my={2}>
        <strong>17.11</strong> You agree to make all applicable records available for review by New Energy Group Pty Ltd
        during Your normal business hours so as to permit New Energy Group Pty Ltd (upon reasonable written notice to
        You) to verify Your compliance with these Terms of Use. Further, You agree that upon the request of New Energy
        Group Pty Ltd or New Energy Group Pty Ltd&#39;s authorized representative, You will promptly document and
        certify in writing to New Energy Group Pty Ltd that Your and Your employees&#39; use of the Mobile Application
        complies with these Terms of Use.
      </Text>
      <Text my={2}>
        New Energy Group Pty Ltd may (upon reasonable written notice) inspect Your use of the Mobile Application during
        Your normal business hours to ensure Your compliance with these Terms of Use. If the results of any such review
        or inspection indicate Your unlicensed or non-compliant use of the Mobile Application or the underpayment by You
        of applicable license or other fees (if any) contractually due and payable to New Energy Group Pty Ltd, You
        shall: (i) immediately pay sufficient fees to cover Your use of the Mobile Application, or such amounts of fees
        remaining due to New Energy Group Pty Ltd and (ii) reimburse New Energy Group Pty Ltd for the cost of such
        review or inspection.
      </Text>
      <Heading my={3} size="md">
        18. APPLICABLE LAW AND DISPUTES RESOLUTION
      </Heading>
      <Text my={2}>
        <strong>18.1</strong> These Terms of Use shall be exclusively governed by the laws of Australia, to the
        exclusion of said country&#39;s conflict of law rules. To the extent possible, New Energy Group Pty Ltd and You
        agree that the United Nations Convention on the International Sale of Goods shall not apply to these Terms of
        Use.
      </Text>
      <Text my={2}>
        <strong>18.2</strong> Any dispute between You and New Energy Group Pty Ltd arising out of or in connection with
        these Terms of Use and/or the Mobile Application, whether based on contract, warranty, tort (including
        negligence), strict liability, statute or otherwise, which cannot be amicably settled, shall in all cases be
        finally settled according to the law governing these Terms of Use as defined above, by the competent courts of
        New South Wales, Australia, to the exclusion of any other jurisdiction, including in case of plurality of
        defendants, injunction-like or emergency proceedings and appeal in warranty.
      </Text>
      <Text my={2}>
        <strong>18.3</strong> You acknowledge and accept that New Energy Group Pty Ltd will be irreparably damaged (and
        damages at law may be an inadequate remedy) if You breach any provision of these Terms of Use and such provision
        is not specifically enforced. Therefore, in the event of a breach or threatened breach by You of these Terms of
        Use, New Energy Group Pty Ltd shall be entitled, in addition to all other rights or remedies, to (i) an
        injunction or other injunctive relief restraining such breach, without being required to show any actual damage
        or to post an injunction or other bond; and/or (ii) a decree for specific performance of the applicable
        provision of these Terms of Use, to the extent permitted by applicable law in the country where New Energy Group
        Pty Ltd has its registered office or principal place of business and/or, as relevant in the context, where You
        will download, install or use the Mobile Application, on both federal and state levels when applicable.
      </Text>
      <Text fontSize="sm" my={2}>
        © 2021 - New Energy Group Pty Ltd. All rights reserved.
      </Text>
    </Box>
  );
}
