import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import MobileTopNav from '../../../common/components/MobileTopNav';
import DesktopTopNav from '../../../common/components/DesktopTopNav';

type SubRouteTopNavProps = {
  onGoBack: () => void;
  title: string;
  children?: JSX.Element;
};

export default function SubRouteTopNav(props: SubRouteTopNavProps) {
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  return isMobileViewport ? <MobileTopNav {...props} /> : <DesktopTopNav {...props} />;
}
