import {
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import emailSentImg from '../../../assets/images/email_sent.svg';
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import type { ForgotPasswordProps } from './ForgotPassword';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MIN_PASSWORD_LENGTH, RULES_TO_TEXT } from './password-validation-utils';
import { AWSError } from 'aws-sdk/lib/error';
import ViewTogglePasswordInput from 'clipsal-cortex-ui/src/components/ViewTogglePasswordInput';
import { LoginRoute } from './Login';
import CustomButton from '../../../common/components/CustomButton';

const validationSchema = yup.object({
  verificationCode: yup.string().required('Verification code is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .min(MIN_PASSWORD_LENGTH, RULES_TO_TEXT.minCharsMet)
    .matches(/[a-z]/g, RULES_TO_TEXT.oneLowerCase)
    .matches(/[A-Z]/g, RULES_TO_TEXT.oneUpperCase)
    .matches(/[^A-Za-z0-9]/g, RULES_TO_TEXT.oneSpecial)
    .matches(/\d/g, RULES_TO_TEXT.oneNumber),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

type FormData = yup.InferType<typeof validationSchema>;

const formOptions = {
  defaultValues: { verificationCode: '', newPassword: '', confirmNewPassword: '' },
  resolver: yupResolver(validationSchema),
  mode: 'onChange' as const,
};

type ConfirmForgotPasswordProps = Omit<ForgotPasswordProps, 'setEmail'> & {
  email: string;
};

export function ConfirmForgotPassword({ onChangeRoute, email = '' }: ConfirmForgotPasswordProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<FormData>(formOptions);
  const [isPasswordTextVisible, setPasswordTextVisible] = useState(false);
  const [isVerificationCodeInvalid, setVerificationCodeInvalid] = useState(false);
  const toast = useToast({ duration: 3000, isClosable: true });
  const verificationCodeInput = register('verificationCode');

  const handleSubmitForgotPassword = async (formData: FormData) => {
    try {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), formData.verificationCode, formData.newPassword);
      await Auth.signIn({
        username: email.toLowerCase(),
        password: formData.newPassword,
      });
      window.location.replace('/');
    } catch (e) {
      if ((e as AWSError).code === 'CodeMismatchException') {
        // Invalidate verification code field
        toast({
          title: `Invalid one-time password`,
          description: (e as Error).message,
          status: 'error',
        });
        setVerificationCodeInvalid(true);
      } else {
        toast({
          title: 'Something went wrong submitting your one-time password',
          description: 'Please try again',
          status: 'error',
        });
      }

      console.error(e);
    }
  };

  return (
    <Flex direction={'column'} justify={'center'} align={'center'}>
      <CloseButton
        data-testid="close-forgot-password-flow"
        alignSelf={'flex-end'}
        onClick={() => {
          onChangeRoute(LoginRoute.Login);
        }}
        rounded={50}
      />
      <Image mb={4} width={'40%'} src={emailSentImg} />
      <Heading size={'md'}>Email has been sent!</Heading>
      <Text textAlign={'center'}>Please enter the 6 digit verification code sent to your email address.</Text>
      <Flex
        data-testid="confirm-forgot-password-form"
        direction={'column'}
        justify={'center'}
        align={'center'}
        width={'100%'}
        as={'form'}
        mb={2}
        mt={4}
        onSubmit={handleSubmit(handleSubmitForgotPassword)}
      >
        <FormControl my={2} isInvalid={!!errors.verificationCode || isVerificationCodeInvalid}>
          <Input
            {...verificationCodeInput}
            onChange={async (e) => {
              setVerificationCodeInvalid(false);
              await verificationCodeInput.onChange(e);
            }}
            isInvalid={!!errors.verificationCode}
            type={'text'}
            placeholder={'Verification code'}
            data-testid="verification-code"
          />
          <FormErrorMessage data-testid="verification-code-error">
            {errors.verificationCode?.message || 'Invalid verification code'}
          </FormErrorMessage>
        </FormControl>
        <FormControl my={2} isInvalid={!!errors.newPassword}>
          <ViewTogglePasswordInput
            inputProps={{
              ...register('newPassword'),
              isInvalid: !!errors.newPassword,
              placeholder: 'New password',
              variant: 'outline',
            }}
            inputDataTestID="new-password"
            isVisible={isPasswordTextVisible}
            onToggleVisibility={(newValue) => setPasswordTextVisible(newValue)}
          />
          <FormErrorMessage data-testid="new-password-error">{errors.newPassword?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={2} isInvalid={!!errors.confirmNewPassword}>
          <ViewTogglePasswordInput
            inputProps={{
              ...register('confirmNewPassword'),
              isInvalid: !!errors.confirmNewPassword,
              placeholder: 'Confirm new password',
              variant: 'outline',
            }}
            inputDataTestID="confirm-new-password"
            buttonDataTestID="confirm-new-password-toggle-password-visibility"
            isVisible={isPasswordTextVisible}
            onToggleVisibility={(newValue) => setPasswordTextVisible(newValue)}
          />
          <FormErrorMessage data-testid="confirm-new-password-error">
            {errors.confirmNewPassword?.message}
          </FormErrorMessage>
        </FormControl>

        <Text fontSize={'sm'}>
          Minimum length 8 characters. Must contain a number, a special character, upper and lower case letters
        </Text>
        <CustomButton
          my={0}
          mt={4}
          mb={2}
          width={'80%'}
          alignSelf={'center'}
          rounded={3}
          colorScheme="dusk100"
          isDisabled={!isValid}
          isLoading={isSubmitting}
          type={'submit'}
          data-testid="submit-confirm-forgot-password-btn"
        >
          Reset password
        </CustomButton>
        <Text>
          Didn't receive the email?{' '}
          <Box
            data-testid="resend-forgot-password-otp"
            cursor={'pointer'}
            color={'customBlue.500'}
            as={'span'}
            onClick={async () => {
              await Auth.forgotPassword(email);

              toast({
                title: `Your email has been re-sent to ${email}`,
                description: 'Check your inbox (and spam folder) for your verification code.',
                status: 'success',
              });
            }}
          >
            Resend
          </Box>
        </Text>
        <Flex />
      </Flex>
    </Flex>
  );
}
