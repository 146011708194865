import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';
import { InvitationStatus, InvitedUserData, UserData } from '../../../../api/api-user';
import * as yup from 'yup';
import { getPhoneValidationSchema } from '../../../../utils/phone-validation';

export const PAGE_SIZES = [5, 10, 20, 50];

type TabConfig = {
  status: InvitationStatus;
  title: string;
};

export const MANAGE_USERS_TAB_CONFIG: TabConfig[] = [
  { status: 'CONFIRMED', title: 'Team Members' },
  { status: 'INVITED', title: 'Pending Invitations' },
];

export const FILTER_TYPES = ['Default', 'Date', 'Name', 'Email', 'Role'] as const;
export type FilterType = (typeof FILTER_TYPES)[number];

export const SORT_ORDERS = ['Ascending', 'Descending'] as const;
export type SortOrder = (typeof SORT_ORDERS)[number];

export type Users = PaginatedResponseV2<UserData>;

export const DEFAULT_STATE_VALUES = {
  pageIndex: 0,
  filterType: 'Default' as FilterType,
  filterRole: 'STAFF',
  sortOrder: 'Ascending' as SortOrder,
  searchTerm: '',
};

export const FILTERS_WITH_SORT_ORDERS = ['Date', 'Name', 'Email'] as FilterType[];

export const FILTER_TO_COLUMN_MAP = {
  Date: 'row_created',
  Name: 'user_first_name',
  Email: 'user_email',
  Default: 'user_email',
  Role: 'role',
} as { [key in FilterType]: string };

// to make it easier to invite user, we use only email to invite user
export const CONFIRMED_USERS_ONLY_FILTERS = ['Name'] as FilterType[];

export const userSchema = yup.object().shape({
  firstName: yup.string().typeError('First name is required').trim().required('First name is required'),
  lastName: yup.string().typeError('Last name is required').trim().required('Last name is required'),
  email: yup.string().trim().email('Email is not valid').required('Email is required'),
  phoneNumber: getPhoneValidationSchema(),
});

export const mapApiUserToForm = (user: UserData | InvitedUserData) => ({
  firstName: user.user_first_name,
  lastName: user.user_last_name,
  role: user.role,
  email: user.user_email,
  phoneNumber: user.user_phone,
  username: user.user_name,
  userId: 'user_id' in user && user?.user_id ? user.user_id : null,
});

export type UserFormData = ReturnType<typeof mapApiUserToForm>;

export type SearchUserQueryParams = {
  limit: number;
  offset: number;
  ordering?: string;
  search_term?: string;
  role__in?: string;
};
export type SearchUserQueryParamKey = keyof SearchUserQueryParams;
