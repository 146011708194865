import React from 'react';
import { Box, Center, Flex, Heading, SkeletonCircle, SkeletonText, Text, useTheme, VStack } from '@chakra-ui/react';
import { PieChart, Pie } from 'recharts';

interface Props {
  numActiveSites: number;
  numPendingSites: number;
  isNumSitesLoaded: boolean;
}
const SitesWidget = ({ numActiveSites, numPendingSites, isNumSitesLoaded }: Props) => {
  const theme = useTheme();
  const chartData = [
    { numSites: numActiveSites, fill: theme.colors.customTeal[500] },
    { numSites: numPendingSites, fill: theme.colors.day[500] },
  ];

  const Label = () => {
    const totalSites = numActiveSites + numPendingSites;
    return (
      <VStack position={'absolute'}>
        <Heading size={'md'}>{totalSites}</Heading>
        <Text size={'sm'}>All sites</Text>
      </VStack>
    );
  };

  return (
    <>
      <Heading size={'md'}>Total Number of Sites</Heading>

      <Center position={'relative'}>
        {isNumSitesLoaded ? (
          <>
            <Label />
            <PieChart data-testid={'total-sites-pie-chart'} width={200} height={200}>
              <Pie data={chartData} dataKey="numSites" outerRadius={85} innerRadius={70} />
            </PieChart>
          </>
        ) : (
          <SkeletonCircle my={4} size={'40'} />
        )}
      </Center>

      <Flex justify={'space-evenly'}>
        <Flex mr={2}>
          <Box w={'4px'} bg={'customTeal.500'} />
          <Box pl={4}>
            <Heading size={'sm'}>Active Sites</Heading>
            {isNumSitesLoaded ? (
              <Text data-testid={'num-active-sites'} mt={2}>
                {numActiveSites}
              </Text>
            ) : (
              <SkeletonText />
            )}
          </Box>
        </Flex>

        <Flex>
          <Box w={'4px'} bg={'day.500'} />
          <Box pl={4}>
            <Heading size={'sm'}>Pending Sites</Heading>
            {isNumSitesLoaded ? (
              <Text data-testid={'num-pending-sites'} mt={2}>
                {numPendingSites}
              </Text>
            ) : (
              <SkeletonText />
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default SitesWidget;
