import { FieldArrayWithId, useWatch } from 'react-hook-form';
import { DeviceType } from '../../../api/api-device-metadata';
import { useSelector } from 'react-redux';
import {
  fetchManufacturers,
  fetchModelsByManufacturerId,
  selectManufacturersByDeviceType,
  selectModelsByDeviceType,
} from './systemDetailsSlice';
import { useAppDispatch } from '../../../app/hooks';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  ListItem,
  OrderedList,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { CommonFieldListProps, SystemDetailsFormData } from './system-details-form-types';
import SearchableDropdownFormControl from '../../../common/components/SearchableDropdownFormControl';
import { setHasUnsavedChanges } from '../../wizard/wizardSlice';
import { AddIcon, InfoIcon, MinusIcon } from '@chakra-ui/icons';
import {
  GOODWE_MANUFACTURER_ID,
  LGES_MANUFACTURER_ID,
  SCHNEIDER_ELECTRIC_MANUFACTURER_ID,
  SUNGROW_MANUFACTURER_ID,
} from 'clipsal-cortex-utils/src/constants/common-constants';
import SchneiderEVChargerConfiguration from './custom-devices/SchneiderEVChargerConfiguration';

type FieldArrayFormKeys = 'inverters' | 'batteries' | 'evChargers';

// Pick properties for arrays off the main form type
type FieldArrayProperties = Pick<SystemDetailsFormData, FieldArrayFormKeys>;

type ModelManufacturerFieldPairProps = {
  index: number;
  field: FieldArrayWithId<SystemDetailsFormData, FieldArrayFormKeys>;
  deviceType: DeviceType;
  fieldKey: keyof FieldArrayProperties;
} & CommonFieldListProps;

export default function ModelManufacturerFieldPair({
  errors,
  index,
  field,
  getValues,
  setValue,
  control,
  deviceType,
  fieldKey,
}: ModelManufacturerFieldPairProps) {
  const [isModelsLoaded, setModelsLoaded] = useState(true);
  const manufacturers = useSelector(selectManufacturersByDeviceType(deviceType));
  const { [fieldKey]: items } = useWatch({ control });
  const modelsByManufacturerId = useSelector(selectModelsByDeviceType(deviceType));
  const dispatch = useAppDispatch();
  const selectedManufacturer = items?.[index].manufacturer;
  const visibleModels = selectedManufacturer && modelsByManufacturerId[selectedManufacturer];
  const isModelSelectDisabled = !(selectedManufacturer && modelsByManufacturerId[selectedManufacturer]?.length);
  const errorsAtIndex = errors?.[fieldKey]?.[index];
  // Used to ensure the model value is not set before the options are loaded.
  const [isFirstUpdateComplete, setFirstUpdateComplete] = useState(false);
  const isFirstUpdate = useRef(true);
  const valuesAtIndex = getValues()[fieldKey]?.[index];

  function getModelSelectText() {
    if (!isModelsLoaded) return 'Loading...';
    else if (!selectedManufacturer) return 'Select a manufacturer to view models.';
    else if (isModelSelectDisabled) return 'No models for this manufacturer.';

    return 'Select a model...';
  }

  useEffect(() => {
    async function fetchModels(manufacturerId: number) {
      setModelsLoaded(false);
      await dispatch(fetchManufacturers(deviceType));
      await dispatch(fetchModelsByManufacturerId({ manufacturerId, deviceType }));
      // Little hack to make sure the model value isn't set before the options are loaded, which won't set it.
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
        setFirstUpdateComplete(true);
      }
    }

    if (selectedManufacturer) {
      fetchModels(selectedManufacturer);
    } else {
      dispatch(fetchManufacturers(deviceType));
      isFirstUpdate.current = false;
      setFirstUpdateComplete(true);
    }
  }, [selectedManufacturer, deviceType, dispatch]);

  useEffect(() => {
    if (!isModelsLoaded && !!visibleModels) setModelsLoaded(true);
  }, [visibleModels, isModelsLoaded]);

  return (
    <>
      <FormControl isInvalid={!!errorsAtIndex?.manufacturer} mt={3} id={`${field.id}_manufacturer`}>
        <FormLabel>Manufacturer</FormLabel>
        <SearchableDropdownFormControl
          data-testid={`${fieldKey}-${index}-manufacturer`}
          defaultValue={valuesAtIndex.manufacturer && valuesAtIndex.manufacturer}
          control={control}
          options={manufacturers}
          valueLabelOptions={['manufacturer_id', 'manufacturer_name']}
          fieldName={`${fieldKey}.${index}.manufacturer`}
          placeholder={manufacturers ? 'Select a manufacturer...' : 'Loading...'}
          onDependentFieldAndStateUpdate={() => {
            setValue(`${fieldKey}.${index}.model` as any, '');
            dispatch(setHasUnsavedChanges(true));
          }}
          isInvalid={!!errorsAtIndex?.manufacturer}
        />

        <FormErrorMessage>{errorsAtIndex?.manufacturer?.message}</FormErrorMessage>
      </FormControl>
      {fieldKey === 'inverters' && valuesAtIndex.manufacturer === GOODWE_MANUFACTURER_ID && (
        <ManufacturerTutorialAccordion
          monitoringPlatformName={'Goodwe'}
          portalName={'SEMS'}
          externalLink={'https://semsportal.com'}
          visitorEmail={'fleet@clipsalcortex.com'}
          index={index}
        />
      )}
      {fieldKey === 'inverters' && valuesAtIndex.manufacturer === LGES_MANUFACTURER_ID && (
        <ManufacturerTutorialAccordion
          monitoringPlatformName={'LG RESU'}
          portalName={'LG RESU'}
          externalLink={'https://www.lgresuhomemonitor.com/home/login'}
          visitorEmail={'lges@clipsalsolar.com'}
          index={index}
        />
      )}
      {fieldKey === 'inverters' && valuesAtIndex.manufacturer === SUNGROW_MANUFACTURER_ID && (
        <ManufacturerTutorialAccordion
          monitoringPlatformName={'Sungrow iSolar Cloud'}
          portalName={'iSolar Cloud'}
          externalLink={'https://www.isolarcloud.com'}
          visitorEmail={'info@clipsalcortex.com'}
          visitorInstructions={'and set Plant restrictions to "Administrators"'}
          index={index}
        >
          <ListItem>Navigate to Plant Configuration {'->'} Plant in iSolar Cloud</ListItem>
          <ListItem>Scroll down to Channel / Partner and click Settings</ListItem>
        </ManufacturerTutorialAccordion>
      )}

      {fieldKey === 'evChargers' && valuesAtIndex.manufacturer === SCHNEIDER_ELECTRIC_MANUFACTURER_ID && (
        <SchneiderEVChargerConfiguration
          setChargerId={(chargerId: string) =>
            setValue(`evChargers.${index}.siteIdentifier`, chargerId, {
              shouldValidate: !!errors.evChargers?.[index],
            })
          }
          setSerialNumber={(serialNumber: string) => setValue(`evChargers.${index}.serialNumber`, serialNumber)}
          setEVChargerModel={(deviceMetadataId: number) => setValue(`evChargers.${index}.model`, deviceMetadataId)}
          isConfigured={!!valuesAtIndex.siteIdentifier && !!valuesAtIndex.serialNumber && !!valuesAtIndex.model}
        />
      )}

      {isFirstUpdateComplete && (
        <FormControl isInvalid={!!errorsAtIndex?.model} mt={3} id={`${field.id}_model`}>
          <FormLabel>Model</FormLabel>
          <SearchableDropdownFormControl
            data-testid={`${fieldKey}-${index}-model`}
            isDisabled={isModelSelectDisabled}
            defaultValue={valuesAtIndex.model && valuesAtIndex.model}
            control={control}
            options={selectedManufacturer ? modelsByManufacturerId[selectedManufacturer] : undefined}
            valueLabelOptions={['device_metadata_id', 'model']}
            fieldName={`${fieldKey}.${index}.model`}
            placeholder={getModelSelectText()}
            isInvalid={!!errorsAtIndex?.model}
            onDependentFieldAndStateUpdate={() => dispatch(setHasUnsavedChanges(true))}
          />
          <FormErrorMessage>{errorsAtIndex?.model?.message}</FormErrorMessage>
        </FormControl>
      )}
    </>
  );
}

interface TutorialAccordionProps {
  monitoringPlatformName: 'Goodwe' | 'LG RESU' | 'Sungrow iSolar Cloud';
  portalName: 'SEMS' | 'LG RESU' | 'iSolar Cloud';
  externalLink:
    | 'https://semsportal.com'
    | 'https://www.lgresuhomemonitor.com/home/login'
    | 'https://www.isolarcloud.com';
  visitorEmail:
    | 'info@clipsalsolar.com'
    | 'lges@clipsalsolar.com'
    | 'info@clipsalcortex.com'
    | 'fleet@clipsalcortex.com';
  visitorInstructions?: string;
  index: number;
}

export const ManufacturerTutorialAccordion = ({
  monitoringPlatformName,
  portalName,
  externalLink,
  visitorEmail,
  visitorInstructions = 'as a Visitor',
  index,
  children,
}: TutorialAccordionProps & PropsWithChildren) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const theme = useTheme();
  const borderColor = useColorModeValue(theme.colors.dusk100[900], theme.colors.dusk100[50]);

  return (
    <Accordion
      defaultIndex={[0]}
      allowToggle
      my={4}
      borderColor="gray.200"
      borderWidth={'1px'}
      rounded={10}
      overflow="hidden"
    >
      <AccordionItem
        border="none"
        borderLeft={`10px solid ${theme.colors.customLinkBlue[500]}`}
        data-testid={`inverters-${index}-${monitoringPlatformName.toLowerCase().replaceAll(' ', '')}-accordion`}
      >
        <Flex w="100%" justify="space-between" py={1}>
          <Flex align="center">
            <InfoIcon color="customLinkBlue.500" mx={2} h={5} w={5} />
            <Text fontWeight="600">{`Connect Cortex to ${monitoringPlatformName} Monitoring Platform:`}</Text>
          </Flex>

          <AccordionButton
            w="fit-content"
            mx={2}
            px={2}
            rounded={4}
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            {isAccordionOpen ? <MinusIcon h={3} /> : <AddIcon h={3} />}
          </AccordionButton>
        </Flex>
        <AccordionPanel pb={4} pt={0} pl={8}>
          <OrderedList>
            <ListItem>Connect inverter to home Wi-Fi</ListItem>
            <ListItem>
              Register site on{' '}
              <Link color="customLinkBlue.500" href={externalLink} isExternal mr={1}>
                {`${portalName} Portal`}
              </Link>
            </ListItem>
            {children}
            <ListItem>
              Add email
              <Popover placement="top" gutter={0} returnFocusOnClose={false} isOpen={isPopOverOpen}>
                <PopoverTrigger>
                  <Box
                    as="span"
                    borderBottom={`1px dashed ${borderColor}`}
                    cursor="pointer"
                    mx={1}
                    onClick={() => {
                      navigator.clipboard.writeText('info@clipsalsolar.com');
                      setIsPopOverOpen(true);
                      setTimeout(() => setIsPopOverOpen(false), 1000);
                    }}
                  >
                    {visitorEmail}
                  </Box>
                </PopoverTrigger>
                <PopoverContent w="fit-content">
                  <PopoverHeader rounded={4} background="dusk100.500" color="white" fontSize="sm">
                    Email copied!
                  </PopoverHeader>
                </PopoverContent>
              </Popover>
              {visitorInstructions}
            </ListItem>
          </OrderedList>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
