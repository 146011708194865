/**
 * This function capitalizes only the first letter of the string
 * @param word - word to be capitalized
 * @returns returns word with capitalized first letter
 */
export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

/**
 * This function removes whitespaces from search string and converts it to lowercase
 * @param word - search string to be formatted
 * @returns returns formatted search string
 */
export function formatSearchString(searchString: string) {
  return searchString.replace(/\s/g, '').toLowerCase();
}
