import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import { selectUser } from './userSlice';
import UserDetailsModal from './UserDetailsModal';

export const UserDetailsModalWrapper = () => {
  const user = useSelector(selectUser);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Invited users might not have user details, prompt them to fill
  useEffect(() => {
    if (user.hasFetchedUserDetails && !user.user_first_name && !user.user_last_name && !user.loginError) onOpen();
  }, [user.loginError, user.hasFetchedUserDetails, user.user_first_name, user.user_last_name]);

  return isOpen ? <UserDetailsModal {...{ isOpen: isOpen, onClose: onClose }} /> : null;
};
