import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import CustomWebSocket from '../../../utils/websocket-wrapper';

export const createWebSocket = createAsyncThunk<
  {
    webSocket: CustomWebSocket;
    subscribedSiteId: number;
  },
  WebSocketConfig,
  { state: RootState }
>('webSocket/create', async (config, { getState }) => {
  const { webSocket: wsState, site } = getState();

  // There might already be a connected websocket in global state -- just return that.
  if (wsState?.webSocket?.isConnected()) {
    return { webSocket: wsState.webSocket, subscribedSiteId: site.clipsal_solar_id };
  }

  // Otherwise, instantiate a new one.
  const { url, onMessage, onOpen } = config;
  const webSocket = new CustomWebSocket(url);
  webSocket.onmessage = onMessage.bind(webSocket);
  webSocket.onopen = onOpen.bind(webSocket);

  return {
    webSocket,
    subscribedSiteId: site.clipsal_solar_id,
  };
});

type WebSocketConfig = {
  url: string;
  onMessage: (this: CustomWebSocket, ev: MessageEvent) => any;
  onOpen: (this: CustomWebSocket, ev: Event) => any;
};

export type WizardReducers = {
  resetWebSocket: (state: WebSocketState) => void;
  updateSubscribedSite: (state: WebSocketState, action: PayloadAction<number>) => void;
};

// NOTE: This is a rare case where putting a non-serializable value into the store is acceptable, as it's essentially
// unavoidable since WebSocket is a class by ECMA standards, and we need global access to it. The alternative options
// seem far less suitable to our existing standard in this app, so it has been put into the Redux store.
type WebSocketState = {
  webSocket: CustomWebSocket | null;
  subscribedSiteId: number;
};

const initialState: WebSocketState = {
  webSocket: null,
  subscribedSiteId: 0,
};

export const webSocketSlice = createSlice<WebSocketState, WizardReducers>({
  name: 'webSocket',
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetWebSocket: (state) => (state = initialState),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateSubscribedSite: (state) => (state = initialState),
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createWebSocket.fulfilled, (state, action) => {
      state.webSocket = action.payload.webSocket;
      state.subscribedSiteId = action.payload.subscribedSiteId;
    });
  },
});

export const { resetWebSocket, updateSubscribedSite } = webSocketSlice.actions;

export const selectWebSocket = (state: RootState) => {
  return state.webSocket.webSocket;
};

export const selectWebSocketState = (state: RootState) => {
  return state.webSocket;
};

export default webSocketSlice.reducer;
