import {
  Box,
  Button,
  Heading,
  Input,
  Flex,
  Text,
  MenuList,
  Menu,
  MenuItem,
  MenuButton,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import CustomButton from '../../../../common/components/CustomButton';
import * as yup from 'yup';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { INVITATION_USER_ROLES, USER_ROLE_MAP } from '../account-helpers';
import { useInviteNewUserMutation } from './manageUsersApi';
import { useSelector } from 'react-redux';
import { selectUser } from '../../userSlice';

const emailSchema = yup.string().email('*Invalid email');

const InviteMemberForm = () => {
  const [{ email, inviteRole, isInvalid }, setState] = useState({
    email: '',
    inviteRole: 'STAFF',
    isInvalid: false,
  });
  const [inviteNewUser, { isLoading: isInviting }] = useInviteNewUserMutation();
  const user = useSelector(selectUser);
  const toast = useToast({ isClosable: true });
  const isSuperAdminUser = user.role === 'SUPER_ADMIN';

  return (
    <Box px={4} mb={4}>
      <Heading my={4} size={'md'} fontWeight={500}>
        Invite Team Members
      </Heading>
      <Flex align="center" direction={['column', 'column', 'row']} zIndex={99}>
        <Box w="100%" mr={2}>
          <Text fontSize={'sm'} color="gray" mb={1}>
            Email Address
          </Text>
          <Input
            w="100%"
            pl={2}
            data-testid="invite-email-input"
            isDisabled={isInviting}
            placeholder="Enter email address"
            value={email}
            isInvalid={isInvalid}
            onChange={(e) => {
              const email = e.target.value.toLowerCase();
              // Check if the email is valid
              const isValid = emailSchema.isValidSync(email);
              setState((prevState) => ({
                ...prevState,
                email,
                // only update if previously considered invalid
                isInvalid: prevState.isInvalid ? !isValid : false,
              }));
            }}
          />
        </Box>

        <Flex
          ml={'auto'}
          w={['100%', '100%', 'auto']}
          wrap={['wrap', 'nowrap']}
          justify={'space-between'}
          align={'flex-end'}
        >
          <Box mr={[0, 0, 2]} mt={[4, 4, 'auto']}>
            <Text fontSize={'sm'} color="gray" mb={1}>
              Role
            </Text>
            <Menu isLazy>
              <MenuButton
                as={Button}
                variant={'outline'}
                data-testid="invite-select-role"
                minW={isSuperAdminUser ? 160 : 140}
                rightIcon={<ChevronDownIcon />}
                textAlign={'left'}
                fontWeight={400}
              >
                {USER_ROLE_MAP[inviteRole]}
              </MenuButton>
              <MenuList minW={isSuperAdminUser ? 160 : 140}>
                {INVITATION_USER_ROLES.map((role, index) => {
                  if (!isSuperAdminUser && role === 'SUPER_ADMIN') return null;
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => setState((prevState) => ({ ...prevState, inviteRole: role }))}
                      data-testid={`invite-${role.toLowerCase()}-role`}
                      bg={inviteRole === role ? 'primaryBranding.100' : undefined}
                      _dark={{ bg: inviteRole === role ? 'primaryBranding.600' : undefined }}
                    >
                      {USER_ROLE_MAP[role]}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </Box>
          <CustomButton
            containerProps={{ w: 'auto' }}
            data-testid="invite-button"
            rounded={'md'}
            py={2}
            my={0}
            minW={110}
            mt={[4, 4, 'auto']}
            isLoading={isInviting}
            isDisabled={!email.length}
            onClick={async () => {
              // Check if the email is valid
              const isValidEmail = emailSchema.isValidSync(email);
              if (!isValidEmail) return setState((prevState) => ({ ...prevState, isInvalid: !isValidEmail }));

              const response = await inviteNewUser({
                user_email: email.toLowerCase(),
                role: inviteRole,
                tenant_id: user.tenant_id,
              });

              setState((prevState) => ({ ...prevState, email: '' }));
              const isError = 'error' in response;
              const status = isError ? 'error' : 'success';
              const description = isError ? 'Something went wrong inviting user!' : 'Successfully invited user!';
              toast({ status, description });
            }}
          >
            Invite
          </CustomButton>
        </Flex>
      </Flex>
      {isInvalid && (
        <Text color="red.500" data-testid="invalid-error">
          *Invalid email
        </Text>
      )}
    </Box>
  );
};

export default InviteMemberForm;
