import React from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  Switch,
} from '@chakra-ui/react';
import { useMeterFormContext } from './useMeterFormContext';
import { Controller, useFieldArray } from 'react-hook-form';
import { OriginalWattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

const SWITCH_CONTRACTOR_TYPES = ['NO', 'NC'] as const;
const SWITCH_STATE_LABELS = ['on', 'off'] as const;

const SwitchConfiguration = ({ meter }: { meter: OriginalWattwatchersMeter }) => {
  const { control, errors, register } = useMeterFormContext();
  const { fields } = useFieldArray({
    name: 'switches',
    control,
  });

  return (
    <Box>
      <Heading size="md" fontWeight={600} mb={4}>
        Switch Configuration
      </Heading>
      {fields.map((field, index) => {
        return (
          <Box key={field.id}>
            <Flex w="100%" justify={'space-between'} wrap={'wrap'} gap={2}>
              <FormControl isInvalid={!!errors?.switches?.[index]?.id} id="id" w={180}>
                <FormLabel>Switch Id</FormLabel>
                <Input
                  data-testid={`switches-${index}-id`}
                  {...register(`switches.${index}.id`)}
                  isDisabled
                  isReadOnly
                  cursor={'not-allowed'}
                  _disabled={{ opacity: 0.6, background: 'gray.100', _dark: { background: 'gray.900' } }}
                />
                <FormErrorMessage>{errors?.switches?.[index]?.id?.message ?? 'This is required'}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.switches?.[index]?.label} id="label" w={170}>
                <FormLabel>Label</FormLabel>
                <Input data-testid={`switches-${index}-label`} {...register(`switches.${index}.label`)} />
                <FormErrorMessage>{errors?.switches?.[index]?.label?.message ?? 'This is required'}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.switches?.[index]?.contactorType} id="contactorType" w={85}>
                <FormLabel>Type</FormLabel>
                <Select
                  data-testid={`switches-${index}-contactorType`}
                  {...register(`switches.${index}.contactorType`)}
                  isDisabled={!!meter.switches?.[index]?.pending?.contactorType}
                >
                  {SWITCH_CONTRACTOR_TYPES.map((contactorType) => (
                    <option key={contactorType} value={contactorType}>
                      {contactorType.toUpperCase()}
                    </option>
                  ))}
                </Select>
                {meter.switches?.[index]?.pending?.contactorType && (
                  <FormHelperText fontSize={'xs'} data-testid={`switches-${index}-contactorType-pending`}>
                    <Spinner size="xs" /> Updating...
                  </FormHelperText>
                )}
                <FormErrorMessage>
                  {errors?.switches?.[index]?.contactorType?.message ?? 'This is required'}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.switches?.[index]?.closedStateLabel} id="closedStateLabel" w={150}>
                <FormLabel>Closed State Label</FormLabel>
                <Select
                  data-testid={`switches-${index}-closedStateLabel`}
                  {...register(`switches.${index}.closedStateLabel`)}
                  isDisabled={!!meter.switches?.[index]?.pending?.closedStateLabel}
                >
                  {SWITCH_STATE_LABELS.map((stateLabel) => (
                    <option key={stateLabel} value={stateLabel}>
                      {stateLabel.toUpperCase()}
                    </option>
                  ))}
                </Select>
                {meter.switches?.[index]?.pending?.closedStateLabel && (
                  <FormHelperText fontSize={'xs'} data-testid={`switches-${index}-closedStateLabel-pending`}>
                    <Spinner size="xs" /> Updating...
                  </FormHelperText>
                )}
                <FormErrorMessage>
                  {errors?.switches?.[index]?.closedStateLabel?.message ?? 'This is required'}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.switches?.[index]?.openStateLabel} id="openStateLabel" w={150}>
                <FormLabel>Open State Label</FormLabel>
                <Select
                  data-testid={`switches-${index}-openStateLabel`}
                  {...register(`switches.${index}.openStateLabel`)}
                  isDisabled={!!meter.switches?.[index]?.pending?.openStateLabel}
                >
                  {SWITCH_STATE_LABELS.map((stateLabel) => (
                    <option key={stateLabel} value={stateLabel}>
                      {stateLabel.toUpperCase()}
                    </option>
                  ))}
                </Select>
                {meter.switches?.[index]?.pending?.openStateLabel && (
                  <FormHelperText fontSize={'xs'} data-testid={`switches-${index}-openStateLabel-pending`}>
                    <Spinner size="xs" /> Updating...
                  </FormHelperText>
                )}
                <FormErrorMessage>
                  {errors?.switches?.[index]?.openStateLabel?.message ?? 'This is required'}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.switches?.[index]?.state} id="state" w={85}>
                <FormLabel>State</FormLabel>
                <Controller
                  control={control}
                  name={`switches.${index}.state`}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Switch
                        size="lg"
                        my={1}
                        data-testid={`switches-${index}-state`}
                        isChecked={value === 'closed'}
                        onChange={(e) => onChange(e.target.checked ? 'open' : 'closed')}
                        colorScheme={'primaryBranding'}
                        isDisabled={!!meter.switches?.[index]?.pending?.state}
                      />
                    );
                  }}
                />
                {meter.switches?.[index]?.pending?.state && (
                  <FormHelperText fontSize={'xs'} data-testid={`switches-${index}-state-pending`}>
                    <Spinner size="xs" /> Updating...
                  </FormHelperText>
                )}

                <FormErrorMessage>{errors?.switches?.[index]?.state?.message ?? 'This is required'}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Divider my={4} />
          </Box>
        );
      })}
    </Box>
  );
};

export default SwitchConfiguration;
