import React, { useState } from 'react';
import useInterval from '../../../../common/hooks/use-interval';
import { Box, Flex, useTheme } from '@chakra-ui/react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

// @TODO: update styles to include gradient, needs a separate task as it's not as simple as expected
export default function CountdownTimer({ timestamp }: { timestamp: string }) {
  const [timeSinceFetch, setTimeSinceFetch] = useState(0);
  const theme = useTheme();

  useInterval(() => {
    setTimeSinceFetch(timeSinceFetch === 5000 ? 0 : timeSinceFetch + 1000);
  }, 1000);

  return (
    <Flex data-testid="countdown-timer" my={2}>
      <Box m={2} alignSelf={'center'} w={'30px'}>
        <CircularProgressbar
          value={(timeSinceFetch / 5000) * 100}
          strokeWidth={50}
          styles={buildStyles({
            backgroundColor: 'rgb(245, 246, 248)',
            pathColor: theme.colors.textGrey[500],
            strokeLinecap: 'butt',
            pathTransition: 'stroke-dashoffset 0.5s ease 0s',
          })}
        />
      </Box>
      <Box>
        <Box fontWeight={600}>{timestamp}</Box>
        <Box>Last updated</Box>
      </Box>
    </Flex>
  );
}
