import { FieldArrayWithId, useFieldArray, useWatch } from 'react-hook-form';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import NestedOrientationFieldArray from './NestedOrientationFieldArray';
import ModelManufacturerFieldPair from './ModelManufacturerFieldPair';
import { CommonFieldListProps, SystemDetailsFormData } from './system-details-form-types';
import { del } from '../../../api/api-helpers';
import { InstalledDevice } from '../../../api/api-device';
import { useSelector } from 'react-redux';
import { MissingModelDrawer } from './MissingModel';
import { selectSite } from '../siteSlice';
import { ScanBarcodeIcon } from '../../../styles/custom-icons';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { didUserGrantBarcodePermission } from 'clipsal-cortex-utils/src/common';
import { Capacitor } from '@capacitor/core';
import { WarningIcon } from '@chakra-ui/icons';
import {
  SOLAR_EDGE_MANUFACTURER_ID,
  SUNGROW_MANUFACTURER_ID,
} from 'clipsal-cortex-utils/src/constants/common-constants';
import Counter from '../../../common/components/Counter';
import { BatteryFieldArrayProps } from './BatteryForm';
import { removeInverter } from './systemDetailsSlice';
import { useAppDispatch } from '../../../app/hooks';
import {
  EMPTY_DEVICE_TEMPLATE,
  EMPTY_INVERTER_TEMPLATE,
  EMPTY_ORIENTATION_TEMPLATE,
  MANUFACTURERS_WITH_SERIAL,
} from './system-details-helpers';

export type NestedInverterFieldArrayProps = CommonFieldListProps & BatteryFieldArrayProps;

export default function NestedInverterFieldArray(props: NestedInverterFieldArrayProps) {
  const { register, control, errors, setValue, getValues } = props;
  const { fields, append, remove } = useFieldArray({
    name: 'inverters',
    control,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const site = useSelector(selectSite);
  const [currentlyEditingInverterIndex, setCurrentlyEditingInverterIndex] = useState<number>(-1);
  const { hasSolarSystem } = useWatch({ control });
  const toast = useToast();
  const counterValue = hasSolarSystem ? fields.length : 0;
  const isRemovingLastInverter = fields.length === 1;
  const dispatch = useAppDispatch();
  const dividerColor = useColorModeValue('#0000001A', 'dusk100.400');

  function checkIfManufacturerUsesSerial(inverterIndex: number): boolean {
    const { manufacturer } = getValues('inverters')[inverterIndex];
    return MANUFACTURERS_WITH_SERIAL.includes(manufacturer);
  }

  function checkIfManufacturerIsSolarEdge(inverterIndex: number): boolean {
    const { manufacturer } = getValues('inverters')[inverterIndex];
    return manufacturer === SOLAR_EDGE_MANUFACTURER_ID;
  }

  async function handleStartBarcodeScan(inverterIndex: number) {
    const hasPermission = await didUserGrantBarcodePermission();

    if (hasPermission) {
      await BarcodeScanner.hideBackground();
      document.body.classList.add('qrscanner');

      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        let serialNumber = result.content;
        const { manufacturer } = getValues('inverters')[inverterIndex];

        // sungrow serial numbers are prefixed with the inverter model
        const isSungrowInverter = manufacturer === SUNGROW_MANUFACTURER_ID;
        if (isSungrowInverter) serialNumber = serialNumber.split(' ')[1] || '';

        setValue(`inverters.${inverterIndex}.serialNumber`, serialNumber, { shouldDirty: true });

        toast({
          title: 'Barcode successfully scanned',
          status: 'success',
          isClosable: true,
        });
      }

      document.body.classList.remove('qrscanner');
    }
  }

  async function handleDeleteInverter(inverterId: number | null, inverterIndex: number) {
    if (window.confirm('Are you sure you want to delete this inverter?')) {
      if (inverterId) {
        await del<InstalledDevice>(`/fleet/sites/${site.clipsal_solar_id}/devices/${inverterId}`, {});
        dispatch(removeInverter(inverterId));
      }
      if (isRemovingLastInverter) setValue('hasSolarSystem', false);
      remove(inverterIndex);
    }
  }

  const handleAddInverter = () => {
    if (!hasSolarSystem) setValue('hasSolarSystem', true);

    append(EMPTY_INVERTER_TEMPLATE);
  };

  const handleRemoveInverter = async (inverterIndex: number) => {
    const { inverterId } = fields[inverterIndex];

    await handleDeleteInverter(inverterId, inverterIndex);
  };

  return (
    <Box mb={4}>
      <Heading size={'lg'} mb={2} px={[4, 6]}>
        Inverter
      </Heading>

      <Divider color={dividerColor} opacity={1} />
      <Flex align="center" justify="space-between" py={3}>
        <Input type="hidden" {...register('hasSolarSystem' as const)} />
        <Text pl={3} pr={[8, 4]}>
          How many inverters do you want to add in this site?
        </Text>
        <Counter
          dataTestId="add-remove-inverters-counter"
          value={counterValue}
          incrementAriaLabel="Add Inverter"
          decrementAriaLabel="Remove Inverter"
          onIncrement={handleAddInverter}
          onDecrement={() => handleRemoveInverter(fields.length - 1)}
          isDecrementDisabled={fields.length === 0 || !hasSolarSystem}
        />
      </Flex>

      <Collapse in={hasSolarSystem}>
        <Accordion defaultIndex={0} allowToggle>
          {fields.map((field, index) => (
            <AccordionItem data-testid="inverters-form" key={`${field.id}-${index}`}>
              <Box>
                <AccordionButton data-testid={`inverters-${index}`} role={'button'} as={Box}>
                  <Flex w={'100%'} justify={'space-between'} align={'center'}>
                    <Heading size={'md'}>Inverter {index + 1}</Heading>
                    <Flex align={'center'}>
                      {/* Display an error notification post-submission on this accordion button. */}
                      {!!errors?.inverters?.[index] && <WarningIcon mr={2} color={'red.500'} w={5} h={5} />}
                      <Button
                        data-testid={`inverters-${index}-removeBtn`}
                        variant={'ghost'}
                        onClick={() => handleRemoveInverter(index)}
                        size={'xs'}
                        ml={2}
                        colorScheme="red"
                        aria-label="Delete inverter"
                      >
                        Remove
                      </Button>
                      <AccordionIcon />
                    </Flex>
                  </Flex>
                </AccordionButton>

                <AccordionPanel pb={4} px={[4, 6]}>
                  <ModelManufacturerFieldPair
                    {...{ ...props, index, field, deviceType: 'INVERTER', fieldKey: 'inverters' }}
                  />

                  <Text
                    onClick={() => {
                      setCurrentlyEditingInverterIndex(index);
                      onOpen();
                    }}
                    cursor={'pointer'}
                    fontWeight={'bold'}
                    textDecoration={'underline'}
                    mt={2}
                    fontSize={'sm'}
                    color={'customLinkBlue.500'}
                    data-testid={`missing-inverters-model-button-${index}`}
                  >
                    Can't find your inverter?
                  </Text>

                  {checkIfManufacturerUsesSerial(index) && (
                    <FormControl
                      isInvalid={!!errors?.inverters?.[index]?.serialNumber}
                      mt={3}
                      id={`${field.id}_serialNumber`}
                    >
                      <FormLabel>Serial Number</FormLabel>
                      <InputGroup>
                        <Input
                          data-testid={`inverters-${index}-serialNumber`}
                          placeholder={'######'}
                          {...register(`inverters.${index}.serialNumber` as const)}
                          type="text"
                        />
                        {Capacitor.isNativePlatform() && (
                          <InputRightElement>
                            <IconButton
                              aria-label={'Scan barcode'}
                              size={'sm'}
                              colorScheme={'customBlue'}
                              variant={'ghost'}
                              icon={<ScanBarcodeIcon w={6} h={6} />}
                              onClick={() => handleStartBarcodeScan(index)}
                            />
                          </InputRightElement>
                        )}
                      </InputGroup>
                      <FormErrorMessage>{errors?.inverters?.[index]?.serialNumber?.message}</FormErrorMessage>
                    </FormControl>
                  )}

                  {checkIfManufacturerIsSolarEdge(index) && (
                    <>
                      <FormControl
                        isInvalid={!!errors?.inverters?.[index]?.siteIdentifier}
                        mt={3}
                        id={`${field.id}_siteIdentifier`}
                      >
                        <FormLabel>Site ID (optional)</FormLabel>
                        <Input
                          data-testid={`inverters-${index}-siteIdentifier`}
                          {...register(`inverters.${index}.siteIdentifier` as const)}
                          type="text"
                          placeholder={'######'}
                        />
                        <FormErrorMessage>{errors?.inverters?.[index]?.siteIdentifier?.message}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors?.inverters?.[index]?.apiKey} mt={3} id={`${field.id}_apiKey`}>
                        <FormLabel>API Key (optional)</FormLabel>
                        <Input
                          data-private
                          data-testid={`inverters-${index}-apiKey`}
                          defaultValue={(field as any).apiKey}
                          {...register(`inverters.${index}.apiKey` as const)}
                          type="text"
                          placeholder={'Enter API key'}
                        />
                        <FormErrorMessage>{errors?.inverters?.[index]?.apiKey?.message}</FormErrorMessage>
                      </FormControl>
                    </>
                  )}

                  <SolarPanelRelatedFields {...props} nestIndex={index} field={field} />
                </AccordionPanel>
              </Box>
            </AccordionItem>
          ))}
        </Accordion>

        <MissingModelDrawer
          index={currentlyEditingInverterIndex}
          setValue={setValue}
          isOpen={isOpen}
          onClose={onClose}
          modelType={'INVERTER'}
        />
      </Collapse>
    </Box>
  );
}

type SolarPanelRelatedFieldsProps = NestedInverterFieldArrayProps & {
  nestIndex: number;
  field: FieldArrayWithId<SystemDetailsFormData, 'inverters', 'id'>;
};

const SolarPanelRelatedFields = ({ nestIndex, field, ...props }: SolarPanelRelatedFieldsProps) => {
  const { errors, control, register, getValues, setValue, appendBattery } = props;
  const orientations = useWatch({ control, name: `inverters.${nestIndex}.orientations` });
  const totalPanelPower = useMemo(() => {
    return orientations.reduce((total, orientation) => {
      const totalOrientationPanelPower = orientation.numOfModules * orientation.panelPower;
      return total + (totalOrientationPanelPower || 0);
    }, 0);
  }, [orientations]);
  const hasSolarPanels = useWatch({ control, name: `inverters.${nestIndex}.hasSolarPanels` });
  const hasDCCoupledBattery = useWatch({ control, name: `inverters.${nestIndex}.hasDCCoupledBattery` });
  const isInitialLoadRef = useRef(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { textColor, disabledTextColor, inputBackground } = useColorModeValue(
    { textColor: 'dusk100.500', disabledTextColor: 'dusk100.300', inputBackground: 'dusk005.500' },
    { textColor: 'dusk100.50', disabledTextColor: 'gray.400', inputBackground: 'gray.600' }
  );
  const toast = useToast();

  useEffect(() => {
    if (!isInitialLoadRef?.current && hasDCCoupledBattery === true) {
      const totalRequiredDCCoupledBattery = getValues().inverters.filter(
        (inverter) => inverter.hasDCCoupledBattery
      ).length;
      const totalBatteries = getValues().batteries.length;

      // only add new battery when total batteries is less than total required dc coupled batteries
      if (totalBatteries < totalRequiredDCCoupledBattery) {
        if (!getValues().hasBattery) setValue('hasBattery', true);
        appendBattery(EMPTY_DEVICE_TEMPLATE);
      }
    }

    // ensures it does not add new battery on first load
    if (isInitialLoadRef?.current) isInitialLoadRef.current = false;
  }, [hasDCCoupledBattery, appendBattery, getValues, setValue]);

  return (
    <>
      <Flex align="center" justify="space-between" mt={4}>
        <Heading size="sm">Does the inverter have solar panels connected?</Heading>

        <Flex align="center">
          <Text>{hasSolarPanels ? 'Yes' : 'No'}</Text>
          <Switch
            data-testid={`inverters-${nestIndex}-has-solar-panels-switch`}
            ml={2}
            colorScheme="primaryBranding"
            size="lg"
            defaultChecked={field.hasSolarPanels}
            isChecked={hasSolarPanels}
            onChange={(e) => {
              const hasSolarPanels = e.target.checked;

              if (hasSolarPanels) {
                setValue(`inverters.${nestIndex}.orientations`, [EMPTY_ORIENTATION_TEMPLATE]);
                setValue(`inverters.${nestIndex}.hasSolarPanels`, true);
              } else {
                // open alert modal for user's decision
                onOpen();
              }
            }}
          />
        </Flex>
      </Flex>
      {hasSolarPanels && (
        <>
          <Flex mt={3} align="flex-start">
            <FormControl isInvalid={!!errors?.inverters?.[nestIndex]?.totalPanels} id={`${field.id}_totalPanels`}>
              <FormLabel>Total Panels</FormLabel>
              <Flex direction="column">
                <Input
                  data-testid={`inverters-${nestIndex}-totalPanels`}
                  defaultValue={field.totalPanels}
                  {...register(`inverters.${nestIndex}.totalPanels` as const)}
                  readOnly
                  minWidth={150}
                  color={disabledTextColor}
                  background={inputBackground}
                  cursor={'not-allowed'}
                />
                <Text color={textColor} fontSize="13px">
                  Total power: {(totalPanelPower / 1000).toFixed(2)} kW
                </Text>
              </Flex>
              <FormErrorMessage>{errors?.inverters?.[nestIndex]?.totalPanels?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <NestedOrientationFieldArray {...props} nestIndex={nestIndex} />
        </>
      )}
      <Flex align="center" justify="space-between" mt={4}>
        <Heading size="sm">Does the inverter have a DC coupled battery connected?</Heading>
        <Flex align="center" ml={2}>
          <Text>{hasDCCoupledBattery ? 'Yes' : 'No'}</Text>
          <Switch
            ml={2}
            colorScheme="primaryBranding"
            size="lg"
            defaultChecked={field.hasDCCoupledBattery}
            {...register(`inverters.${nestIndex}.hasDCCoupledBattery` as const)}
          />
        </Flex>
      </Flex>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent data-testid={`inverters-${nestIndex}-delete-orientations-dialog`}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Orientations
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete all orientations? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                data-testid={`inverters-${nestIndex}-cancel-delete-orientations-button`}
              >
                Cancel
              </Button>
              <Button
                data-testid={`inverters-${nestIndex}-delete-orientations-button`}
                isLoading={isDeleting}
                colorScheme="red"
                onClick={async () => {
                  setIsDeleting(true);
                  try {
                    const orientations = getValues().inverters[nestIndex].orientations;

                    const deleteOrientationPromises = orientations
                      .filter((orientation) => orientation.orientationId)
                      .map(({ orientationId }) => del(`/fleet/orientations/${orientationId}`, {}));

                    await Promise.all(deleteOrientationPromises);

                    setValue(`inverters.${nestIndex}.orientations`, []);
                    setValue(`inverters.${nestIndex}.hasSolarPanels`, false);
                    toast({
                      title: 'Successfully deleted all orientations!',
                      status: 'success',
                      isClosable: true,
                    });
                    onClose();
                  } catch (error) {
                    toast({
                      title: 'Something went wrong while deleting all orientations!',
                      status: 'error',
                      isClosable: true,
                    });
                  }
                  setIsDeleting(false);
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
